import { MutableRefObject, RefObject, ReactNode  } from "react";
import { View, ViewStyle } from "react-native";
import { ILayoutLimitation } from "../InView";

export enum DraggableGestureEventType {
  DRAG_BEFORE_START = "DRAG_BEFORE_START",
  DRAG_START = "DRAG_START",
  DRAG_BEFORE_END = "DRAG_BEFORE_END",
  DRAG_END = "DRAG_END",
  DRAG_UPDATE = "DRAG_UPDATE",
  RESIZE_START = "RESIZE_START",
  RESIZE_END = "RESIZE_END",
  RESIZE_UPDATE = "RESIZE_UPDATE",
  TAP = "TAP",
}

export enum DroppableGestureEventType {
  DROPPABLE_ENTER = "DROPPABLE_ENTER",
  DROPPABLE_LEAVE = "DROPPABLE_LEAVE",
  DROPPABLE_DROP_START = "DROPPABLE_DROP_START",
  DROPPABLE_DRAG_START = "DROPPABLE_DRAG_START",
}

export interface IDraggable {
  id: number;
  index: number;
  active?: boolean;
  style?: ViewStyle;
  longPress?: boolean;
  limitation?: ILimitation;
  minSizes?: { width: number; height: number };
  draggable?: boolean;
  viewRef:RefObject<View>;
  dragging?: boolean;
  dropped?:boolean;
  layout?: ILayout;
  children:ReactNode;
  position?: { x: number; y: number; onComplete?: () => void };
  fromPosition?: { x: number; y: number; onComplete?: () => void };
  dropToStartPosition?: boolean;
  ref: MutableRefObject<IDraggableRef | null>;
  droppable?: IDroppable | null;
  onUpdate?: (
    type: DraggableGestureEventType,
    position: IEventPosition,
    droppable: IDroppable | null
  ) => void;
}

export interface IDraggableRef {
  updatePosition: (
    x: number,
    y: number,
    duration?: number,
    delay?: number
  ) => Promise<void>;
  updateLayout:(layout:Partial<IEventPosition> | null) => void;
  updateDraggable:(
    type: DraggableGestureEventType,
    position: IEventPosition,
    droppable: IDroppable | null
  ) => void;
  getLayout:() => IEventPosition;
  startDrag:(_forceDroppableNull:boolean) => void;
  endDrag:() => void;
}

export interface IDroppable {
  index: number;
  id: number;
  filled?: IDraggable | null;
  layout?: ILayout;
  inView?: boolean;
  children:ReactNode;
  limitation?: ILayoutLimitation;
  droppableInnerRef?: RefObject<View>;
  onUpdate?: (type:DroppableGestureEventType, draggable: IDraggable | null) => void;
  ref?: MutableRefObject<IDroppableRef>;
}

export interface IDroppableRef {
  updateLayout: () => void;
  updateInView: () => void;
  getLayout:() => ILayout | undefined;
  updateDroppable:(
    type: DroppableGestureEventType,
    draggable: IDraggable | null
  ) => void;
}

export interface ILimitation {
  x: number;
  y: number;
  width: number;
  height: number;
}


export interface ILayout {
  x: number;
  y: number;
  width: number;
  height: number;
  left: number;
  top: number;
}

export interface IPoint {
  x: number;
  y: number;
  width?: number;
  height?: number;
}

export interface IPosition {
  absoluteX: number;
  absoluteY: number;
  x: number;
  y: number;
}

export interface IEventPosition {
  absoluteX: number;
  absoluteY: number;
  x: number;
  y: number;
  translationX:number;
  translationY:number;
  offsetX:number;
  offsetY:number;
}