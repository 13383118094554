import { StyleSheet } from "react-native";
import { isIos } from "../../utils";
import { colors } from "../../utils/colors";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightBlack,
    width: "100%",
    height: "100%",
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "transparent",
    position: "relative",
    alignItems: "center",
  },
  controls: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",
    top: isIos ? 40 : 16,
    right: 16,
  },
  controlSpacer: {
    marginHorizontal: 4,
  },
  containerCloseCameraButton: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: colors.lightBlue,
    alignItems: "center",
    justifyContent: "center",
  },
  takePicture: {
    position: "absolute",
    bottom: 56,
  },
});
