
export default {
  translation: {
    common: {
      button: {
        done: "Terminar",
      },
    },
    modals: {

    },
    components: {
      signature: {
        buttonReset: "Rehacer la firma",
      },
      takePicture: {
        takePicture: "Tomar la foto",
        retakePicture: "Tomar de nuevo la foto",
      },
      videoPlayer: {
        pause: "El vídeo está en pausa",
      },
      profilePicture: {

        retakePicture: "Tomar de nuevo la foto",
        retakeColor: "Cambiar el color",
      },
    },
    network: {
      noInternet: "No está conectado(a) a Internet",
      recovery: {
        title: "recuperación de la conexión",
        description: "¡ Se restablece la conexión !",
      },
    },
    notification: {
      camera: {
        error: {
          denied: {
            title: "Error",
            description: "Permiso no concedido",
          },
        },
      },
    },
    api:{
      error:{
        notFound:{
          title:"Alerte",
          description:"API non trouvé !"
        },
        unknownError:{
          title:"Alerte",
          description:"Un erreur inconnu est apparue"
        }
      },
      warning:{
        notActivated:{
          title:"Alerte",
          description:"Votre abonnement n'est pas activé"
        },
        emailNotFound:{
          title:"Alerte",
          description:"Votre email n'existe pas"
        },
        codeNotValid:{
          title:"Alerte",
          description:"Le code n'est pas valide"
        },
        cantActivate:{
          title:"Alerte",
          description:"Vous ne pouvez pas activer cet email"
        }
      },
      success:{
        codeGenerated:{
          title:"Succès",
          description:"Un code à été envoyé par email"
        },
        activated:{
          title:"Succès",
          description:"Votre abonnement est activé"
        }
      }
    },
    module:{
      userOnBoarding: {
        profileInfos: {
          title: "Introduzca su información",
          inputs: {
            pseudoTitle: "Pseudo",
            firstNameTitle: "Nombre",
            lastNameTitle: "Apellido",
            emailTitle: "Email",
          },
          notification: {},
        },
        profilePicture: {
          title: "Elige un avatar o hazte una foto",
          retakePicture: "Tomar de nuevo la foto",
          retakeColor: "Cambiar el color",
        },
        roomSelecter: {
          title: "Introduzca el código indicado en la aplicación Animator",
        },
        errors: {
          voidInput: {
            title: "¡ Atención !",
            description: "Todos los campos son obligatorios",
          },
          noValideEmail: {
            title: "¡ Atención !",
            description: "El formato de su email no es válido",
          },
          wrongRoom: {
            title: "Error..",
            description: "El código de espacio no es válido",
          },
          wrongEmail: {
            title: "Error..",
            description: "Su email no es válido",
          },
          socketsError: {
            title: "Se produce un error",
            description: "Se ha producido un error, inténtelo de nuevo",
          },
        },
      },
    },
  }
};
