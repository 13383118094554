export const UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS";

export interface IDimensionsState {
  height: number;
  width: number;
  orientation: string;
  screenWidth: number;
  screenHeight: number;
  screenOrientation: string;
}

interface UpdateDimensionsAction {
  type: typeof UPDATE_DIMENSIONS;
  payload: IDimensionsState;
}

export type DimensionsActionTypes = UpdateDimensionsAction;
