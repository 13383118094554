import React, {
  FunctionComponent,
  lazy,
  Suspense,
  useEffect,
  createRef,
  useCallback,
} from "react";
import {
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
  ParamListBase,
  PartialRoute,
  Route,
} from "@react-navigation/native";

import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { getDefaultLinking, withNoHeaderWithTitle } from "./options";
import ScreenLoader from "../components/ScreenLoader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useUserInformations } from "../hooks/user";
import {
  updateCurrentNavigation,
  updateNavigationRef,
  updateReadyNavigation,
  updateScreenNames,
} from "../store/navigation/actions";
import { TransitionSpecs } from "@react-navigation/stack";
import { useUpdateNavigation } from "../store/navigation/selecters";
import { ScreenName } from "../store/navigation/types";
import { AppDispatch, RootState, store } from "../store";

const Stack = createStackNavigator<ParamListBase>();
const Tab = createBottomTabNavigator<ParamListBase>();


export const navigationRef = createRef<NavigationContainerRef<ParamListBase>>();

interface INavigation {
  initScreens: ScreenName[];
  defaultScreen: string;
  getLinking?: (
    screens: ScreenName[],
    dispatch?: AppDispatch,
    state?: RootState
  ) => LinkingOptions<ParamListBase>;
  onRouteChange?: (
    route: PartialRoute<Route<string, object | undefined>>
  ) => void;
  onReady?: () => void;
  customHook?: () => void;
}

const config = {
  animation: "spring",
};

const Navigation: FunctionComponent<INavigation> = ({
  initScreens,
  defaultScreen,
  getLinking,
  onRouteChange,
  onReady,
  customHook,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { screens, ready, ref } = useSelector(useUpdateNavigation);

  useUserInformations();
  customHook && customHook();

  useEffect(() => {
    if (ready && ref) {
      ref?.addListener("state", async (e) => {
        const { state } = e.data;
        const route = state?.routes[state.routes.length - 1];
        //dispatch(updateCurrentNavigation(route?.name || ""));
        onRouteChange && onRouteChange(route);
      });
      //dispatch(updateCurrentNavigation(ref.getCurrentRoute()?.name || ""));
    }
  }, [ready, ref]);

  useEffect(() => {
    dispatch(updateScreenNames(initScreens));
  }, [initScreens]);

  const renderScreens = useCallback(() => {
    return screens.map((screen, index) => {
      const { name, component } = screen;
      const titleKey =`screensTitles.${name}`;
      const hasTitle =  i18n.exists(titleKey)
      const title = hasTitle ? withNoHeaderWithTitle(t(titleKey)) : {};
      return (
        <Stack.Screen
          key={index}
          name={name}
          component={component}
          options={title}
        />
      );
    });
  }, [screens]);

  const getCurrentLinking = () => {
    const linking =
      getLinking != null
        ? getLinking(screens, dispatch, store.getState())
        : getDefaultLinking(screens, dispatch, store.getState());
    return linking;
  };

  useEffect(() => {
    if (navigationRef.current) {
      dispatch(updateNavigationRef(navigationRef.current));
    }
  }, [navigationRef.current]);

  useEffect(() => {
    return () => {
      dispatch(updateReadyNavigation(false));
    };
  }, []);

  return (
    <Suspense fallback={<ScreenLoader />}>
      {screens.length > 0 && (
        <NavigationContainer
          ref={navigationRef}
          linking={getCurrentLinking()}
          onReady={() => {
            onReady && onReady();
            dispatch(updateReadyNavigation(true));
          }}
        >
          <Stack.Navigator
            defaultScreenOptions={{
              animationEnabled:true
            }}
            screenOptions={({ route, navigation }) => ({
              headerShown: false,
              gestureEnabled: true,
            })}
            initialRouteName={defaultScreen}
          >
            {renderScreens()}
          </Stack.Navigator>
        </NavigationContainer>
      )}
    </Suspense>
  );
};

export default Navigation;
