import { ILangData } from "../../languages";

export const UPDATE_LANGUAGES = "UPDATE_LANGUAGES";

export interface ILanguagesState {
  languages:ILangData[]
}

interface UpdateLanguagesAction {
  type: typeof UPDATE_LANGUAGES;
  payload: ILangData[];
}

export type LanguagesActionTypes = UpdateLanguagesAction;
