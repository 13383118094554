import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import { GLView } from "expo-gl";
import {
  PixelRatio,
  Platform,
  LayoutChangeEvent,
  ViewStyle,
  StyleProp,
} from "react-native";
import { View } from "react-native-animatable";
//@ts-ignore
import PIXI from "expo-pixi/lib/Pixi";
import { useAnimatedGestureHandler } from "react-native-reanimated";
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  TapGestureHandler,
  State,
} from "react-native-gesture-handler";
import { colors } from "@core/utils/colors";

type Size = {
  width?: number;
  height?: number;
};
type Point = {
  x: number;
  y: number;
  color?: string | number;
  width?: number;
  alpha?: number;
};

type Line = {
  points: Point[];
  color?: string | number;
  width?: number;
  alpha?: number;
};

type AnimatedGHContext = {
  offsetX: number;
  offsetY: number;
  offsetWidth: number;
  offsetHeight: number;
};

export interface IDrawer {
  id: string;
  style?: StyleProp<ViewStyle>;
  strokeColor: number | string;
  strokeWidth: number;
  strokeAlpha: number;
  onChange?: (renderer: PIXI.Renderer) => PIXI.Renderer;
  onReady?: (context: WebGLRenderingContext) => WebGLRenderingContext;
  onEmpty?: () => void;
  onUndo?: () => void;
  initialLines?: Array<Line>;
  enableDrawing?: boolean;
}

export interface IDrawerRef {
  undo: () => void;
  clear: () => void;
  isEmpty: () => void;
  addImage: (uri: string, point: [number, number, number, number]) => void;
  toDataURL: () => string | null;
}

const scale = PixelRatio.get();

function scaled({ x, y }: Point): Point {
  return { x: x * scale, y: y * scale };
}
/*function scaled(nativeEvent) {
     const out = vec2.fromValues(nativeEvent.locationX, nativeEvent.locationY);
     vec2.scale(out, out, scale);
     return out;
}*/

const Drawer = forwardRef<IDrawerRef, IDrawer>(
  (
    {
      id,
      style,
      strokeColor,
      strokeWidth,
      strokeAlpha,
      onChange,
      onReady,
      onEmpty,
      onUndo,
      initialLines = [],
      enableDrawing = true,
    },
    ref
  ) => {
    const ease: number = 0.3;
    const delay: number = 5;
    const renderer = useRef<PIXI.Renderer>(null);
    const stage = useRef<PIXI.Container>(null);
    const lastPoint = useRef<Point>();
    const lastTime = useRef<number>(0);
    const graphics = useRef<PIXI.Graphics>(null);
    const gplume = useRef<PIXI.Graphics>(null);
    const lines = useRef<Array<Line>>([]);
    const points = useRef<Array<Point>>([]);
    const gl = useRef<GLView>(null);
    const [sizes, setSizes] = useState<Size>({ width: 0, height: 0 });
    const layers = useRef<PIXI.Container>();

    useImperativeHandle(ref, () => ({
      undo: () => {
        undo();
      },
      clear: () => {
        clear();
      },
      isEmpty: () => {
        return isEmptyDraw();
      },
      addImage: (uri: string, point: [number, number, number, number]) => {
        drawImage(uri, point);
      },
      toDataURL: () => {
        var texture = renderer.current.generateTexture(
          stage.current,
          undefined,
          undefined,
          renderer.current.screen
        );
        var exportCanvas = renderer.current.extract.canvas(texture);
        return exportCanvas.toDataURL();
      },
    }));

    const isEmptyDraw = () => {
      const { children } = layers.current;
      return children.length == 0;
    };

    const drawHandler = useAnimatedGestureHandler<
      PanGestureHandlerGestureEvent,
      AnimatedGHContext
    >({
      onStart: (event, ctx) => {
        if (enableDrawing) {
          const { x, y } = scaled({
            x: event.x,
            y: event.y,
          });

          drawLine(
            {
              x,
              y,
              color: strokeColor,
              width: strokeWidth,
              alpha: strokeAlpha,
            },
            false
          );
        }
      },
      onActive: (event, ctx) => {
        if (enableDrawing && lastPoint.current) {
          const point = scaled({
            x: event.x,
            y: event.y,
          });

          const time = Date.now();
          const delta = time - lastTime.current;
          //if (delta < delay) return;
          lastTime.current = time;

          const x: number =
            lastPoint.current.x + ease * (point.x - lastPoint.current.x);
          const y: number =
            lastPoint.current.y + ease * (point.y - lastPoint.current.y);
          drawLine(
            {
              x,
              y,
              color: strokeColor,
              width: strokeWidth,
              alpha: strokeAlpha,
            },
            false
          );
        }
      },
      onEnd: (event, ctx) => {
        if (enableDrawing) {
          setTimeout(() => onChange && onChange(renderer.current), 1);
        }
      },
    });

    const maybeCreateLayersContainer = () => {
      if (!layers.current) {
        layers.current = new PIXI.Container();
        stage.current.addChild(layers.current);

        const g2 = new PIXI.Graphics();
        g2.beginFill(0x000000, 0);
        g2.drawRect(0, 0, sizes.width, sizes.height);
        g2.endFill();
        stage.current.addChild(g2);

        renderer.current._update();
      }
    };

    const drawLine = (point: Point, newLine: boolean) => {
      if (
        !renderer.current ||
        (!newLine && !graphics.current) ||
        !enableDrawing
      ) {
        return;
      }

      maybeCreateLayersContainer();

      if (newLine) {
        gplume.current = new PIXI.Graphics();
        graphics.current = new PIXI.Graphics();

        const layer = new PIXI.Container();
        layer.addChild(gplume.current);
        layer.addChild(graphics.current);

        layers.current.addChild(layer);
        persistStroke();

        lastPoint.current = point;
        points.current = [point];
        return;
      }
      lastPoint.current = point;
      points.current.push(point);

      const g = graphics?.current;
      g.clear();
      gplume.current.clear();
      for (let i = 0; i < points.current.length; i++) {
        const { x, y, color, width, alpha } = points.current[i];
        if (i === 0) {
          g.lineStyle(
            width || strokeWidth || 10,
            color || strokeColor || 0x000000,
            alpha || strokeAlpha || 1
          );
          g.moveTo(x, y);
        } else {
          g.lineTo(x, y);
        }

        gplume.current.beginFill(color);
        gplume.current.drawCircle(x, y, width ? width / 2 : 0);
      }
      g.currentPath.shape.closed = false;
      g.endFill(); /// TODO: this may be wrong: need stroke
      gplume.current.endFill();
      renderer.current._update();
    };

    const drawImage = async (
      uri: string,
      point: [number, number, number, number]
    ) => {
      return new Promise((resolve, reject) => {
        maybeCreateLayersContainer();
        const scale = PixelRatio.get();
        var dataTexture = PIXI.Texture.fromImage(uri);
        var icon = PIXI.Sprite.from(dataTexture);
        icon.x = point[0] * scale;
        icon.y = point[1] * scale;
        icon.width = point[2] * scale;
        icon.height = point[3] * scale;
        layers.current.addChild(icon);
        setTimeout(() => {
          renderer.current._update();
          resolve("done");
        }, 50);
      });
    };

    const persistStroke = () => {
      if (graphics.current) {
        graphics.current.points = points;
        lines.current.push(graphics.current);
      }
      lastTime.current = 0;
      points.current = [];
    };

    const undo = (): any => {
      if (!renderer.current || !layers.current) {
        return null;
      }

      const { children } = layers.current;
      if (children.length > 0) {
        const child = children[children.length - 1];
        layers.current.removeChild(child);

        renderer.current._update();
        lines.current.pop();

        // TODO: This doesn't really work :/
        if (isEmptyDraw()) {
          setTimeout(() => onEmpty && onEmpty(), 2);
        } else {
          setTimeout(() => onUndo && onUndo(renderer.current), 2);
        }
        return child;
      } else if (points.current.length > 0) {
        persistStroke();
        return undo();
      }
    };

    const clear = (): any => {
      if (!renderer.current) {
        return null;
      }

      if (layers?.current?.children.length) {
        layers.current.removeChildren();
        renderer.current._update();
        lines.current = [];
        points.current = [];
      }

      return null;
    };

    const onContextCreate = async (context) => {
      stage.current = new PIXI.Container();
      //const getAttributes = context.getContextAttributes || (() => ({}));
      const getAttributes =
        Platform.OS === "web"
          ? () => ({})
          : context.getContextAttributes || (() => ({}));

      context.getContextAttributes = () => {
        const contextAttributes = getAttributes();
        return {
          ...contextAttributes,
          stencil: true,
        };
      };

      renderer.current = PIXI.autoDetectRenderer({
        width: context.drawingBufferWidth,
        height: context.drawingBufferHeight,
        preserveDrawingBuffer: true,
        context,
        antialias: true,
        backgroundColor: "transparent",
        transparent: true,
        autoStart: false,
        screen: {},
      });

      renderer.current._update = () => {
        renderer.current.render(stage.current);
        context.endFrameEXP();
      };

      //const scale = PixelRatio.get();

      setSizes({
        width: context.drawingBufferWidth,
        height: context.drawingBufferHeight,
      });

      onReady && onReady(context);

      if (initialLines) {
        for (let line of initialLines) {
          buildLine(line);
        }
        lines.current = initialLines;
      }
    };

    const buildLine = ({ points, color, alpha, width }: Line) => {
      for (let i = 0; i < points.length; i++) {
        drawLine({ color, alpha, width, ...points[i] }, i === 0);
      }
    };

    const onLayout = ({
      nativeEvent: {
        layout: { width, height },
      },
    }: LayoutChangeEvent) => {
      const scale = PixelRatio.get();
      setSizes({ width: width * scale, height: height * scale });
    };

    useEffect(() => {
      if (sizes && renderer.current) {
        renderer.current.resize(sizes.width, sizes.height);
        renderer.current._update();
      }
    }, [sizes]);

    return (
      <>
        {Platform.OS == "web" && (
          <PanGestureHandler onGestureEvent={drawHandler}>
            <TapGestureHandler
              onHandlerStateChange={({ nativeEvent }) => {
                if (nativeEvent.state == State.BEGAN) {
                  if (enableDrawing) {
                    const { x, y } = scaled({
                      x: nativeEvent.x,
                      y: nativeEvent.y,
                    });

                    drawLine(
                      {
                        x,
                        y,
                        color: strokeColor,
                        width: strokeWidth,
                        alpha: strokeAlpha,
                      },
                      true
                    );
                    drawLine(
                      {
                        x,
                        y,
                        color: strokeColor,
                        width: strokeWidth,
                        alpha: strokeAlpha,
                      },
                      false
                    );
                    if (enableDrawing) {
                      setTimeout(
                        () => onChange && onChange(renderer.current),
                        1
                      );
                    }
                  }
                }
              }}
            >
              <View
                onLayout={onLayout}
                style={[
                  { flex: 1, alignSelf: "stretch", overflow: "hidden" },
                  style
                ]}
              >
                <GLView
                  key={"Expo.Sketch-" + id}
                  style={{ flex: 1 }}
                  onContextCreate={onContextCreate}
                />
              </View>
            </TapGestureHandler>
          </PanGestureHandler>
        )}

        {Platform.OS != "web" && (
          <PanGestureHandler onGestureEvent={drawHandler}>
            <GLView
              onLayout={onLayout}
              key={"Expo.Sketch-" + id}
              style={style}
              onContextCreate={onContextCreate}
            />
          </PanGestureHandler>
        )}
      </>
    );
  }
);

export default Drawer;
