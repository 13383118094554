import Constants from "expo-constants";
import moment from "moment";
moment.locale("fr");

export const IS_DEV: boolean = process.env.NODE_ENV == "development";
export const SITE_URL: string = Constants.manifest?.extra?.siteUrl;
export const APP_URL: string = Constants.manifest?.extra?.appUrl;
export const API_URL: string = Constants.manifest?.extra?.apiUrl;
export const PROJECT_ROOT: string = Constants.manifest?.extra?.projectRoot;
export const APP_PATH: string = Constants.manifest?.extra?.appPath;
//export const WORKSPACE_ROOT: string = Constants.manifest?._internal.
// Google Analitycs + GTM
export const GOOGLE_ANALITYCS_ID: string =
  Constants.manifest?.extra?.googleAnalitycsID;
export const GOOGLE_TAG_MANAGER_ID: string =
  Constants.manifest?.extra?.googleTagManagerID;
export const SOCKET_URL: string =
  Constants.manifest?.extra?.socketURL || "wss://ha-ratchet.opendo.fr";
export const API_AUTH: string = Constants.manifest?.extra?.apiAuthUrl || "https://bo.opendo.fr";
export const CORE_PATH:string = `${PROJECT_ROOT}/${APP_PATH}`;