import {
  IUserState,
  UPDATE_DEFAULT_PICTURE_INDEX,
  UPDATE_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_STATUS,
  UPDATE_USER_ID,
  UPDATE_USER_LANG,
  UPDATE_USER_LASTNAME,
  UPDATE_USER_COLOR,
  UPDATE_USER_NAME,
  UPDATE_USER_PICTURE,
  UPDATE_USER_CODE,
  UPDATE_USER_CODE_VERIFICATION_SENDED,
  UPDATE_USER_ACTIVATED,
  UPDATE_USER_ACCESS_TOKEN,
  UPDATE_USER_DEVICE_ID,
  UserActionTypes,
} from "./types";

export function updateUser(newUser: IUserState): UserActionTypes {
  return {
    type: UPDATE_USER,
    payload: newUser,
  };
}

export function updateUserName(userName: string): UserActionTypes {
  return {
    type: UPDATE_USER_NAME,
    payload: userName,
  };
}

export function updateUserLang(lang: string): UserActionTypes {
  return {
    type: UPDATE_USER_LANG,
    payload: lang,
  };
}

export function updateUserLastname(lastName: string): UserActionTypes {
  return {
    type: UPDATE_USER_LASTNAME,
    payload: lastName,
  };
}

export function updateUserEmail(email: string): UserActionTypes {
  return {
    type: UPDATE_USER_EMAIL,
    payload: email,
  };
}

export function updateUserPicture(
  userPicture: string,
  isDefaultPicture: boolean
): UserActionTypes {
  return {
    type: UPDATE_USER_PICTURE,
    payload: { userPicture, isDefaultPicture },
  };
}

export function updateUserEmailStatus(hasEmailError: boolean): UserActionTypes {
  return {
    type: UPDATE_USER_EMAIL_STATUS,
    payload: hasEmailError,
  };
}

export function updateUserPictureIndex(
  userPictureIndex: number
): UserActionTypes {
  return {
    type: UPDATE_DEFAULT_PICTURE_INDEX,
    payload: userPictureIndex,
  };
}

export function updateUserId(userId: string): UserActionTypes {
  return {
    type: UPDATE_USER_ID,
    payload: userId,
  };
}

export function updateUserColor(userColor: string): UserActionTypes {
  return {
    type: UPDATE_USER_COLOR,
    payload: userColor,
  };
}


export function updateUserCode(code: string | null): UserActionTypes {
  return {
    type: UPDATE_USER_CODE,
    payload: code,
  };
}

export function updateUserCodeVerificationSended(sended: boolean): UserActionTypes {
  return {
    type: UPDATE_USER_CODE_VERIFICATION_SENDED,
    payload: sended,
  };
}


export function updateUserActivated(activated: boolean): UserActionTypes {
  return {
    type: UPDATE_USER_ACTIVATED,
    payload: activated,
  };
}

export function updateUserAccessToken(token:string): UserActionTypes {
  return {
    type: UPDATE_USER_ACCESS_TOKEN,
    payload: token,
  };
}


export function updateUserDeviceId(deviceId: string): UserActionTypes {
  return {
    type: UPDATE_USER_DEVICE_ID,
    payload: deviceId,
  };
}