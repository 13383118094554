import React, { forwardRef } from "react";
import {
  View,
  TextInput as RNTextInput,
  Text,
  ViewStyle,
  StyleProp,
  Animated,
  KeyboardType,
  TextStyle,
} from "react-native";
import { inputStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { InputType } from "./utils";
import { useDimensions } from "../../store/dimensions/selecters";
import { FontSizeType } from "../../utils/mq";
import { deburr } from "lodash";
import { checkEmail } from "../../utils/keyboard";
import { colors } from "@core/utils/colors";

export interface ITextInput {
  text: string;
  onChangeText?: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  title?: string;
  keyboardType?: KeyboardType;
  placeholder?: string;
  lowerCase?: boolean;
  maxLength?: number;
  error?: boolean;
  isMail?: boolean;
  type?: InputType;
  style?: StyleProp<ViewStyle>;
  containerInputStyle?:ViewStyle;
  inputStyle?: StyleProp<ViewStyle | TextStyle>;
  size?: FontSizeType | number;
  titleColor?: string;
  textAlign?: string;
  hasBorder?: boolean;
  editable?: boolean;
}

export interface ITextInputRef {
  focus?: () => void;
}
const TextInput = forwardRef<ITextInputRef, ITextInput>(
  (
    {
      text,
      placeholder,
      onChangeText,
      onBlur,
      onFocus,
      lowerCase,
      maxLength,
      title,
      keyboardType = "default",
      type = InputType.NORMAL,
      error = false,
      isMail = false,
      style,
      inputStyle,
      containerInputStyle,
      titleColor = colors.white,
      textAlign = "center",
      hasBorder = true,
      editable = true,
      size = FontSizeType.MEDIUM,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const dimensions = useSelector(useDimensions);

    const filteredText = (text: string) => {
      if (checkEmail(text)) {
        text = deburr(text);
      }

      onChangeText && onChangeText(text);
    };

    const styles = inputStyles(type, size, titleColor, textAlign, hasBorder);
    return (
      <Animated.View style={[styles.containerWrapper, style]}>
        <View style={[styles.containerInner]}>
          {title && <Text style={styles.text}>{title}</Text>}
          <View style={[error && styles.container_error, containerInputStyle]}>
            <RNTextInput
              disableFullscreenUI={true}
              placeholder={placeholder}
              style={[styles.input, inputStyle, { outline: "none" }]}
              value={text}
              onChangeText={filteredText}
              onBlur={onBlur}
              onFocus={onFocus}
              autoCapitalize={lowerCase ? "none" : "words"}
              maxLength={maxLength}
              editable={Boolean(editable)}
              keyboardType={keyboardType}
            />
          </View>
        </View>
      </Animated.View>
    );
  }
);

export default TextInput;
