import { StyleSheet } from "react-native";
import { colors } from "../../utils/colors";
import { Fonts } from "../../utils/fonts";
import { appPaddingHorizontal } from "../../utils/generalStyle";
import { FontSizeType, MQSize, responsiveFontSize, responsivePadding } from "../../utils/mq";

export const swiperStyles = () => {
  return StyleSheet.create({
    container: {
      flex: 1,
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
    },
    slideWrapperStyle: {
      flex:1,
      alignContent: "center",
      justifyContent: "center",
      flexBasis: "auto",
    },
    innerContainerStyle: {
      flex:1,
      flexBasis: "auto",
    },
    paginationStyle: {
      justifyContent: "center",
      alignItems: "center",
    },
    paginationDotsStyle: {
      flexDirection:"row",
      justifyContent: "center",
      alignItems: "center",
    },
    paginationText: {
      color: colors.white,
      fontFamily: Fonts.ROBOTO_BOLD,
      fontSize: responsiveFontSize(FontSizeType.MEDIUM),
    },
    controls:{
      //backgroundColor:colors.successGreen,
      flexShrink:1,
      flexDirection:"row",
      alignItems:"center",
      justifyContent:"space-between",
      paddingVertical:responsivePadding(MQSize.MEDIUM),
      paddingHorizontal:appPaddingHorizontal()
    }
  });
};


export const createPaginationDotStyles = (active:boolean = false) => {
  return StyleSheet.create({
    dot:{
      height: 6,
      width: 6,
      borderRadius: 6,
      margin: 2,
      backgroundColor: colors.white,
      opacity:active ? 1 : 0.5
    }
  })
}