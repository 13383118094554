import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useState,
} from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { IconArrowTurnRight } from "../../Svg/IconArrowTurnRight";
import { IconCamera } from "../../Svg/IconCamera";
import { createStyles } from "./styles";
import { useSelector } from "react-redux";
import { useUser } from "../../../store/user/selecters";
import { useTranslation } from "react-i18next";
import {
  getChooseItemWidth,
  PROFILE_PICTURE,
  SCALE_VALUE_DISABLE,
  SCALE_VALUE_ENABLE,
} from "./utils";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { Easing } from "../../../utils/reanimated";
import CustomButton from "../../CustomButton";
import { colors } from "../../../utils/colors";
import { FontSizeType, MQSize } from "../../../utils/mq";
import TextInput from "../../TextInput";
import { useDimensions } from "../../../store/dimensions/selecters";

interface IProfilePictureChoice {
  choiceProfile: PROFILE_PICTURE;
  updateColorProfile: () => void;
  updateCameraState: (state: boolean) => void;
  profileDefaultImage: string;
  profilePictureCamera: string;
  onSwitchProfileType?: (type: PROFILE_PICTURE) => void;
}

export const ProfilePictureChoice: FunctionComponent<IProfilePictureChoice> = ({
  updateCameraState,
  choiceProfile,
  updateColorProfile,
  profileDefaultImage,
  profilePictureCamera,
  onSwitchProfileType,
}) => {
  const user = useSelector(useUser);
  const dimensions = useSelector(useDimensions);
  const { t } = useTranslation();

  const [currentChoiceProfil, setCurrentChoiceProfil] =
    useState<PROFILE_PICTURE>(choiceProfile);

  const scalePictureColor = useSharedValue(SCALE_VALUE_DISABLE);
  const scalePictureCamera = useSharedValue(SCALE_VALUE_ENABLE);
  const translateXPictureColor = useSharedValue(0);
  const translateXPictureCamera = useSharedValue(0);
  
  const animatedScalePictureColor = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scalePictureColor.value }],
    };
  });

  const animatedScalePictureCamera = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scalePictureCamera.value }],
    };
  });

  const animatedTranslateXPictureColor = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translateXPictureColor.value }],
    };
  });

  const animatedTranslateXPictureCamera = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translateXPictureCamera.value }],
    };
  });

  const styles = createStyles(currentChoiceProfil, profilePictureCamera);

  const switchChoicePictureType = () => {
    setCurrentChoiceProfil(
      currentChoiceProfil === PROFILE_PICTURE.DEFAULT
        ? PROFILE_PICTURE.CAMERA
        : PROFILE_PICTURE.DEFAULT
    );
  };

  useEffect(() => {
    if (currentChoiceProfil == PROFILE_PICTURE.DEFAULT) {
      scalePictureColor.value = withTiming(SCALE_VALUE_ENABLE, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
      scalePictureCamera.value = withTiming(SCALE_VALUE_DISABLE, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
      translateXPictureColor.value = withTiming(0, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
      translateXPictureCamera.value = withTiming(getChooseItemWidth() * 1.2, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
    }
    if (currentChoiceProfil == PROFILE_PICTURE.CAMERA) {
      scalePictureColor.value = withTiming(SCALE_VALUE_DISABLE, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
      scalePictureCamera.value = withTiming(SCALE_VALUE_ENABLE, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
      translateXPictureColor.value = withTiming(-getChooseItemWidth() * 1.2, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
      translateXPictureCamera.value = withTiming(0, {
        duration: 500,
        easing: Easing.easeOutBack,
      });
    }
    onSwitchProfileType && onSwitchProfileType(currentChoiceProfil);
  }, [currentChoiceProfil, dimensions]);

  return (
    <View style={styles.container}>
      <View style={styles.containerInner}>
        <Animated.View
          style={[
            styles.containerButton,
            styles.pictureButton,
            animatedScalePictureColor,
          ]}
        >
          <Animated.View style={[{ flex: 1 }, animatedTranslateXPictureColor]}>
            <View style={[styles.innerButton, styles.pictureInnerButton]}>
              <TouchableOpacity
                style={[styles.innerButton]}
                onPress={() => {
                  if (currentChoiceProfil != PROFILE_PICTURE.DEFAULT) {
                    switchChoicePictureType();
                  } else {
                    updateColorProfile();
                  }
                }}
              >
                <Image
                  source={{ uri: profileDefaultImage }}
                  style={[{ width: "100%", height: "100%" }]}
                />
              </TouchableOpacity>
            </View>
            {currentChoiceProfil === PROFILE_PICTURE.DEFAULT && (
              <CustomButton
                title={t("components.profilePicture.retakeColor")}
                style={styles.innerSelectButton}
                icon={{
                  name: "ArrowTurnRight",
                  background: true,
                  backgroundColor: colors.lightBlue,
                  size: MQSize.XSMALL,
                }}
                size={FontSizeType.XSMALL}
                background={false}
                onPress={updateColorProfile}
              />
            )}
          </Animated.View>
        </Animated.View>

        <Animated.View
          style={[
            styles.containerButton,
            styles.cameraButton,
            animatedScalePictureCamera,
          ]}
        >
          <Animated.View style={[{ flex: 1 }, animatedTranslateXPictureCamera]}>
            <View style={[styles.innerButton, styles.cameraInnerButton]}>
              <TouchableOpacity
                style={styles.innerButton}
                onPress={() => {
                  if (currentChoiceProfil != PROFILE_PICTURE.CAMERA) {
                    switchChoicePictureType();
                  } else {
                    updateCameraState(true);
                  }
                }}
              >
                {!profilePictureCamera ? (
                  <IconCamera
                    size={
                      currentChoiceProfil === PROFILE_PICTURE.CAMERA ? 120 : 60
                    }
                  />
                ) : null}
                {profilePictureCamera ? (
                  <Image
                    source={{ uri: profilePictureCamera }}
                    style={styles.cameraInnerPicture}
                  />
                ) : null}
              </TouchableOpacity>
            </View>

            {currentChoiceProfil === PROFILE_PICTURE.CAMERA && (
              <CustomButton
                title={t("components.profilePicture.retakePicture")}
                style={styles.innerSelectButton}
                icon={{
                  name: "ArrowTurnRight",
                  background: true,
                  backgroundColor: colors.lightBlue,
                  size: MQSize.XSMALL,
                }}
                size={FontSizeType.XSMALL}
                background={false}
                onPress={() => updateCameraState(true)}
              />
            )}
          </Animated.View>
        </Animated.View>
      </View>
    </View>
  );
};
