import React, {
  FunctionComponent,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  forwardRef
} from "react";
import { View, StyleSheet, Animated, ViewStyle } from "react-native";
//@ts-ignore
import { Emitter } from "react-native-particles";
import { SVGIcon } from "../../Svg";
import { colors } from "../../../utils/colors";
export interface IOpendoExplosion {
  style?: ViewStyle;
  getPositions?: () => { top: number; left: number };
  autoplay?: boolean;
}

export interface IOpendoExplosionRef {
  start:() => void;
}

const OpendoExplosion = forwardRef<IOpendoExplosionRef, IOpendoExplosion>(
  ({ style, getPositions, autoplay = false }, ref) => {
    const emitterRef = useRef<Emitter>();
    const [positions, setPositions] = useState(
      getPositions ? getPositions() : null
    );

    const start = () => {
      if (getPositions) {
        setPositions(getPositions());
      }
      emitterRef.current.start();
    };

    useImperativeHandle(ref, () => ({
      start,
    }));

    useEffect(() => {
      if (autoplay) {
        start();
      }
    }, [autoplay]);

    return (
      <>
        <View style={[style ? style : {}, positions]} pointerEvents="none">
          <Emitter
            ref={emitterRef}
            numberOfParticles={50}
            emissionRate={5}
            interval={10}
            speed={80}
            particleLife={1500}
            direction={-100}
            spread={360}
            gravity={18}
            fromPosition={{ x: 0, y: 0 }}
            autoStart={false}
          >
            <EmitterParticle />
          </Emitter>
        </View>
      </>
    );
  }
);

interface IEmitterParticle {}

const EmitterParticle: FunctionComponent<IEmitterParticle> = ({}) => {
  const sizes = [20, 30, 40, 70, 80];
  const opacities = [0.3, 0.5, 0.8, 1, 1, 1];
  const customColors = [
    colors.lightBlue,
    colors.lightOrange,
    colors.lightGreen2,
  ];
  const randSize = Math.floor(Math.random() * sizes.length);
  const randColor = Math.floor(Math.random() * customColors.length);
  const randOpacity = Math.floor(Math.random() * opacities.length);
  const size = sizes[randSize];
  const color = customColors[randColor];
  const opacity = opacities[randOpacity];

  const fadeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: opacity,
      duration: 500,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    fadeIn();
  }, []);

  return (
    <>
      <Animated.View
        style={[
          {
            opacity: fadeAnim, // Bind opacity to animated value
          },
        ]}
      >
        <SVGIcon
          size={size}
          color={color}
          style={{ opacity: opacity }}
          name={"OpendoLogo"}
          backgroundColor={colors.transparent}
        />
      </Animated.View>
    </>
  );
};

export const createStyles = () => {
  return StyleSheet.create({
    confetti: {},
  });
};

export default OpendoExplosion;
