
import {
  IAppState,
  AppActionTypes,
  APP_PROCESSING,
  APP_UPDATE_ASSETS,
  APP_BUSYING,
  EBusyType,
  APP_UPDATE_ICONS,
} from "./types";

const initialState: IAppState = {
  processing: false,
  assets:[],
  busying:{
    show:false,
    type:EBusyType.PROCESSING
  },
  icons:{}
};

export function appReducer(
  state = initialState,
  action: AppActionTypes
): IAppState {
  switch (action.type) {
    case APP_PROCESSING: {
      return {
        ...state,
        processing: action.payload,
      };
      break;
    }
    case APP_BUSYING: {
      return {
        ...state,
        busying: action.payload,
      };
      break;
    }
    case APP_UPDATE_ASSETS: {
      return {
        ...state,
        assets: action.payload,
      };
      break;
    }
    case APP_UPDATE_ICONS: {
      return {
        ...state,
        icons: action.payload,
      };
      break;
    }
    default:
      return state;
  }
}
