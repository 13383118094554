export default {
  translation: {
    common: {
      button: {
        done: "Terminer",
      },
    },
    modals: {
    },
    components: {
      signature: {
        buttonReset: "Refaire la signature",
      },
      takePicture: {
        takePicture: "Prendre la photo",
        retakePicture: "Reprendre la photo",
      },
      videoPlayer: {
        pause: "La vidéo est en pause",
      },
      profilePicture: {
        retakePicture: "Reprendre la photo",
        retakeColor: "Changer la couleur",
      },
    },
    network: {
      noInternet: "Vous n’êtes pas connecté(e) à internet",
      recovery: {
        title: "Récupération de la connexion",
        description: "La connexion vient d'être rétablie !",
      },
    },
    notification: {
      camera: {
        error: {
          denied: {
            title: "Error",
            description: "Permission not granted",
          },
        },
      },
    },
    api:{
      error:{
        notFound:{
          title:"Alerte",
          description:"API non trouvé !"
        },
        unknownError:{
          title:"Alerte",
          description:"Un erreur inconnu est apparue"
        }
      },
      warning:{
        notActivated:{
          title:"Alerte",
          description:"Votre abonnement n'est pas activé"
        },
        emailNotFound:{
          title:"Alerte",
          description:"Votre email n'existe pas"
        },
        codeNotValid:{
          title:"Alerte",
          description:"Le code n'est pas valide"
        },
        cantActivate:{
          title:"Alerte",
          description:"Vous ne pouvez pas activer cet email"
        }
      },
      success:{
        codeGenerated:{
          title:"Succès",
          description:"Un code à été envoyé par email"
        },
        activated:{
          title:"Succès",
          description:"Votre abonnement est activé"
        }
      }
    },
    module:{
      userOnBoarding:{
        profileInfos: {
          title: "Saisissez vos infos",
          inputs: {
            pseudoTitle: "Pseudo",
            firstNameTitle: "Prénom",
            lastNameTitle: "Nom",
            emailTitle: "Email",
          },
          notification: {},
        },
        profilePicture: {
          title: "Choisissez un avatar ou prenez-vous en photo",
        },
        roomSelecter: {
          title: "Saisissez le code indiqué dans l'app de l’Animateur",
        },
        code: {
          title: "Saisissez le code à 6 chiffres reçu par email",
          inputs: {
            codeTitle: "Code",
          },
          resendButton: "Renvoyer un nouveau code",
        },
        errors: {
          voidInput: {
            title: "Attention !",
            description: "Tous les champs sont obligatoires",
          },
          noValideEmail: {
            title: "Attention !",
            description: "Le format de votre email n'est pas valide",
          },
          wrongEmail: {
            title: "Erreur..",
            description: "Votre email n'est pas valide",
          },
          socketsError: {
            title: "Une erreur est survenu",
            description: "Une erreur s'est produite, veuillez réessayer",
          },
          alreadyUsedRoom: {
            title: "Attention !",
            description: "Cette room est déja utiliser par un autre animateur",
          },
        },
      }
    },
  },
};
