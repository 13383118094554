import { StyleSheet } from "react-native";
import { BUTTON_WIDTH } from "./utils";

export const createStyles = () => {
  return StyleSheet.create({
    box: {},
    boxInner: {
      ...StyleSheet.absoluteFillObject,
    },
    boxInnerH: {
      width: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    button: {
      ...StyleSheet.absoluteFillObject,
      position: "absolute",
      top: 0,
      left: 0,
    },
  });
};
