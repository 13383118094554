import React, { FunctionComponent } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ViewStyle,
} from "react-native";
import { useSelector } from "react-redux";
import { ISvgIcon } from "./../../components/Svg/types";
import { SVGIcon } from "./../../components/Svg";
import {
  responsiveFontSize,
  MQSize,
  MQType,
  FontSizeType,
  responsiveValue,
} from "./../../utils/mq";
import { colors } from "./../../utils/colors";
import { Fonts } from "./../../utils/fonts";
import { useDimensions } from "./../../store/dimensions/selecters";
import { boxShadow } from "./../../utils/generalStyle";
import ActivityIndicator from "../../components/Loaders/ActivityIndicator";



interface ICustomButton {
  title?: string;
  style?: ViewStyle;
  disabled?: boolean;
  visible?: boolean;
  onPress?: () => void;
  icon?: ISvgIcon | null;
  color?: string;
  size?: MQSize | FontSizeType | number;
  font?:Fonts;
  direction?: string;
  background?: boolean;
  active?: boolean;
  backgroundColor?: string;
  processing?: boolean;
  centered?: boolean;
  busy?: boolean;
  busyCount?: number;
  shadow?: boolean;
  touchable?: boolean;
}

const CustomButton: FunctionComponent<ICustomButton> = ({
  title,
  style,
  icon = null,
  onPress,
  color = colors.white,
  size = MQSize.MEDIUM,
  direction = "row",
  disabled = false,
  touchable = true,
  font = Fonts.ROBOTO_BOLD,
  visible = true,
  background = false,
  active = false,
  backgroundColor = colors.lightBlue,
  processing = false,
  centered = false,
  busy = false,
  busyCount = -1,
  shadow = false,
}) => {
  const dimensions = useSelector(useDimensions);
  const fontSize: number = isNaN(size as number) ? responsiveFontSize(size as FontSizeType) : size as number;
  const styles = createStyles(
    fontSize,
    font,
    direction,
    color,
    active,
    icon,
    backgroundColor,
    processing,
    centered,
    shadow
    
  );

  const opacity: number = visible ? (disabled ? 0.5 : 1) : 0;
  const baseStyles = [styles.button, style, { opacity }];

  if (background) {
    baseStyles.push(styles.buttonBackground);
  }

  return (
    <TouchableOpacity
      style={baseStyles}
      onPress={onPress}
      disabled={!touchable || (touchable && disabled)}
    >
      {processing && (
        <ActivityIndicator
          style={styles.loader}
          animating={true}
          size={icon?.size || 0}
          color={colors.white}
        />
      )}
      {icon && (
        <View style={[styles.iconWrapper]}>
          <SVGIcon {...icon} />
        </View>
      )}

      {busy && busyCount == -1 && <View style={styles.busyBadge} />}
      {busy && busyCount != -1 && (
        <View style={styles.busyBadge}>
          <Text style={styles.busyBadgeText}>{busyCount}</Text>
        </View>
      )}

      {title && <Text style={styles.text}>{title}</Text>}
    </TouchableOpacity>
  );
};

const createStyles = (
  size: number,
  font:Fonts = Fonts.ROBOTO_BOLD,
  direction: any = "row",
  color: string,
  active: boolean = false,
  icon: ISvgIcon | null = null,
  backgroundColor: string = "",
  processing: boolean = false,
  centered: boolean = false,
  shadow: boolean = true,
  hasBorder :boolean = true
) => {
  const busyBadgeSize = responsiveValue(
    [
      {
        mq:[MQType.MD],
        value:size * .8,
      },
      {
        mq:[MQType.SM],
        value:size * 1.1,
      }
    ]
  )
  const styles = StyleSheet.create({
    button: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: direction,
    },
    buttonBackground: {
      padding: 10,
      paddingRight: 20,
      paddingLeft: 20,
      borderRadius: 30,
      backgroundColor: backgroundColor,
    },
    iconWrapper: {
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      opacity: processing ? 0.8 : 1,
    },
    text: {
      marginLeft: direction == "row" && icon != null ? 16 : 0,
      color: color,
      fontFamily: font,
      fontSize: size,
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10,
    },
    busyBadge: {
      position: "absolute",
      top: 0,
      right: 0,
      borderRadius: 50,
      width: busyBadgeSize,
      height: busyBadgeSize,
      backgroundColor: colors.darkPink,
      zIndex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    busyBadgeText: {
      color: colors.white,
      fontSize: 15,
      fontFamily:Fonts.ROBOTO_BOLD
    },
  });

  if (centered) {
    styles.button = {
      ...styles.button,
      ...{
        transform: [{ translateX: -size / 2 }, { translateY: -size / 2 }],
      },
    };
  }
  if (icon != null && active) {
    styles.button = {
      ...styles.button,
      ...{
        borderRadius: 50,
        ...boxShadow(),
      },
    };
  }

  if (icon == null && shadow) {
    styles.button = {
      ...styles.button,
      ...{
        shadowColor: "black",
        shadowOffset: {
          width: 0,
          height: 20,
        },
        shadowOpacity: 0.2,
        shadowRadius: 20.3,
        elevation: 13,
      },
    };
  }

  return styles;
};

export default CustomButton;
