import {
  IModalsState,
  ModalsActionTypes,
  SHOW_MODAL,
  HIDE_MODAL,
  RESET_MODALS,
  IModalState,
  REMOVE_MODAL,
} from "./types";

const initialState: IModalsState = {
  activeModals: [],
  currentLevel: 1,
};

export function modalsReducer(
  state = initialState,
  action: ModalsActionTypes
): IModalsState {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        activeModals: [
          ...state.activeModals.filter((modal: IModalState) => {
            return modal.modalName !== action.payload.modalName;
          }),
          {
            ...action.payload,
            level: state.currentLevel + 2,
            display: true,
          },
        ],
        currentLevel: state.currentLevel + 2,
      };

    case HIDE_MODAL:
      const activeModals = state.activeModals.map((modal) => {
        return modal.modalName == action.payload.modalName
          ? { ...modal, ...{ display: false } }
          : modal;
      });

      return {
        ...state,
        activeModals,
        currentLevel:
          state.activeModals.filter((modal: IModalState) => {
            return modal.modalName !== action.payload.modalName;
          }).length > 0
            ? state.currentLevel
            : 1,
      };

    case REMOVE_MODAL:
      return {
        ...state,
        activeModals: state.activeModals.filter((modal: IModalState) => {
          return modal.modalName !== action.payload.modalName;
        }),
        currentLevel:
          state.activeModals.filter((modal: IModalState) => {
            return modal.modalName !== action.payload.modalName;
          }).length > 0
            ? state.currentLevel
            : 1,
      };

    case RESET_MODALS:
      return initialState;

    default:
      return state;
  }
}
