import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { updateAppProcessing } from "../../store/app/action";
import { useApp as useAppSelector } from "../../store/app/selecters";

export const useApp = () => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector(useAppSelector)
  const showProcessing = () => {
    dispatch(updateAppProcessing(true));
  };

  const hideProcessing = () => {
    dispatch(updateAppProcessing(false));
  };

  return {
    state,
    showProcessing,
    hideProcessing,
  };
};
