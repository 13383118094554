import {
  APP_PROCESSING,
  APP_BUSYING,
  APP_UPDATE_ASSETS,
  AppActionTypes,
  IAsset,
  IBusying,
  APP_UPDATE_ICONS,
} from "./types";

export const updateAppUpdateAssets = (assets: IAsset[]): AppActionTypes => {
  return {
    type: APP_UPDATE_ASSETS,
    payload: assets,
  };
};

export const updateAppProcessing = (processing: boolean): AppActionTypes => {
  return {
    type: APP_PROCESSING,
    payload: processing,
  };
};

export const updateAppBusying = (busying: IBusying): AppActionTypes => {
  return {
    type: APP_BUSYING,
    payload: busying,
  };
};

export const updateAppIcons = (icons:any | null = null): AppActionTypes => {
  return {
    type: APP_UPDATE_ICONS,
    payload: icons,
  };
};

