export const APP_PROCESSING = 'APP_PROCESSING';
export const APP_BUSYING = 'APP_BUSYING';
export const APP_UPDATE_ASSETS = 'APP_UPDATE_ASSETS';
export const APP_UPDATE_ICONS = 'APP_UPDATE_ICONS';

export enum EBusyType {
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export interface IBusying {
  show: boolean;
  type: EBusyType;
}

export interface IAsset {
  name: string;
  path: string;
}

export interface IAppState {
  processing: boolean;
  assets: IAsset[];
  busying: IBusying;
  icons:any;
}

interface AppBusyingAction {
  type: typeof APP_BUSYING;
  payload: IBusying;
}

interface AppProcessingAction {
  type: typeof APP_PROCESSING;
  payload: boolean;
}

interface AppUpdateAssetsAction {
  type: typeof APP_UPDATE_ASSETS;
  payload: IAsset[];
}

interface AppUpdateIconsAction {
  type: typeof APP_UPDATE_ICONS;
  payload: any | null;
}

export type AppActionTypes =
  | AppProcessingAction
  | AppBusyingAction
  | AppUpdateAssetsAction
  | AppUpdateIconsAction;
