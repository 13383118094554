export interface ISwiperSlide {
  index: number;
  canNext?: boolean;
  data?: any;
  onUpdateNextPermission?: (canNext: boolean) => void;
  processing?:boolean;
}

export enum SwiperCommonControlsType{
  DEFAULT = "default",
  VERBOSE = "verbose"
}
