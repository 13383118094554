import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { View } from "react-native";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { getHeight, getWidth } from "../../utils";
import { createPDFStyles } from "./styles";
import { IPDFView, PDFViewRef } from "./utils";
import { useSelector } from "react-redux";
import { useDimensions } from "../../store/dimensions/selecters";
import ActivityIndicator from "../Loaders/ActivityIndicator";
import { colors } from "../../utils/colors";

const PDFView = forwardRef<PDFViewRef, IPDFView>(
  ({ base64, onInit, onChange }, ref) => {
    const dimensions = useSelector(useDimensions);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const PDFStyles = createPDFStyles();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
      setNumPages(numPages);
    }

    const nextPage = () => {
      if (numPages) {
        setPageNumber(pageNumber >= numPages ? 1 : pageNumber + 1);
      }
    };

    const prevPage = () => {
      if (numPages) {
        setPageNumber(pageNumber <= 1 ? 1 : pageNumber - 1);
      }
    };

    useImperativeHandle(ref, () => ({
      nextPage,
      prevPage,
      numPages,
      pageNumber,
    }));

    useEffect(() => {
      if (numPages) {
        onInit(numPages);
      }
    }, [numPages]);

    useEffect(() => {
      if (pageNumber) {
        onChange(pageNumber);
      }
    }, [pageNumber]);

    return (
      <View style={PDFStyles.pageContainer}>
        {!numPages && (
          <View style={PDFStyles.spinnerWrapper}>
            <ActivityIndicator color={colors.lightBlue} />
          </View>
        )}
        <Document
          file={`data:application/pdf;base64,${base64}`}
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode="canvas"
          loading={" "}
        >
          {numPages && (
            <Page
              pageNumber={pageNumber}
              height={getHeight()}
              width={getWidth()}
              loading={" "}
            />
          )}
        </Document>
      </View>
    );
  }
);

export default PDFView;
