
import { colors } from "@core/utils/colors";
import {
  IThemeState,
  ThemeActionTypes,
  UPDATE_THEME,
  EThemeType,
} from "./types";

const initialState: IThemeState = {
  name:EThemeType.Light,
  themes:[
    {
      name:EThemeType.Light,
      backgroundColor:colors.bgLightGrey,
      pageTitleColor:colors.white,
      textColor:colors.darkGrey,
      buttonColor:colors.lightBlue,
      buttonDangerColor:colors.bgRed,
      spinnerColor:colors.lightBlue
    },
    {
      name:EThemeType.Dark,
      backgroundColor:colors.lightGrey2,
      pageTitleColor:colors.white,
      textColor:colors.white,
      buttonColor:colors.lightBlue,
      buttonDangerColor:colors.bgRed,
      spinnerColor:colors.lightBlue
    }
  ]
};

export function themeReducer(
  state = initialState,
  action: ThemeActionTypes
): IThemeState {
  switch (action.type) {
    case UPDATE_THEME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    default:
      return state;
  }
}
