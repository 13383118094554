import { lang } from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserEmail,
  updateUserLastname,
  updateUserName,
} from "../../store/user/actions";
import { useUser } from "../../store/user/selecters";
import { isWeb } from "../../utils";
import { readCookie, setCookie } from "../../utils/cookie";
import Constants from "expo-constants";

export const OPENDO_USER_COOKIE_NAME: string =
  Constants.manifest?.extra?.opendoUserCookieName || "OpendoUserCookie";
//one week expiration cookie
export const OPENDO_USER_COOKIE_EXPIRE: number = 604800;

export const useUserInformations = () => {
  const { checkUserCookie } = useUserCookie();
  useEffect(() => {
    checkUserCookie();
  }, []);
};

export const useUserCookie = () => {
  const user = useSelector(useUser);
  const dispatch = useDispatch();

  const updateUserCookie = () => {
    if (isWeb) {
      const { userName, userLastName, userEmail } = user;
      if (userName != "" && userLastName != "" && userEmail != "") {
        setCookie(
          OPENDO_USER_COOKIE_NAME,
          JSON.stringify({
            userName,
            userLastName,
            userEmail,
          }),
          OPENDO_USER_COOKIE_EXPIRE
        );
      }
    }
  };

  const checkUserCookie = () => {
    if (isWeb) {
      const opendoUserCookie = readCookie(OPENDO_USER_COOKIE_NAME);
      const { userEmail } = user;
      if (opendoUserCookie && !userEmail) {
        const userInfos = JSON.parse(opendoUserCookie);
        dispatch(updateUserName(userInfos.userName));
        dispatch(updateUserLastname(userInfos.userLastName));
        dispatch(updateUserEmail(userInfos.userEmail));
      }
    }
  };

  return {
    updateUserCookie,
    checkUserCookie,
  };
};
