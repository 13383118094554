import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import { RouteProp } from "@react-navigation/native";

export enum RoutePath {
  HOME_PATH = "home",
  USER_ON_BOARDING_PATH = "onboarding",
  SPEEDY_ON_BOARDING_PATH = "speedyjoin",
  ACTIVATED_PATH = "activated",
  LOGGED_IN_PATH = "logged-in",
}

export enum ScreenNames {
  Home = "Opendo",
  UserOnboarding = "Connexion",
  SpeedyOnboarding = "SpeedyOnboarding",
  Activated = "Activated",
  LoggedIn = "Connected"
}

export type RootStackParamList = {
  [ScreenNames.Home]: undefined;
  [ScreenNames.UserOnboarding]: undefined | { id: string };
  [ScreenNames.Activated]: undefined;
  [ScreenNames.LoggedIn]: undefined;
}

export type RootStackComponent<RouteName extends keyof RootStackParamList> = {
  navigation: StackNavigationProp<RootStackParamList, RouteName>;
  route: RouteProp<RootStackParamList, RouteName>;
};
