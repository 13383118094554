import {
  getStateFromPath as RNGetStateFromPath,
  getPathFromState as RNGetPathFromState,
  LinkingOptions,
  Route,
} from "@react-navigation/native";
import Constants from "expo-constants";
import { AppDispatch, RootState } from "../store";
import { ScreenName } from "../store/navigation/types";
import { mapValues, keyBy } from "lodash";
import { APP_URL } from "../config";
import { RootStackParamList, ScreenNames, RoutePath } from "./types";

export const withNoHeader = () => {
  return { header: () => null };
};

export const withNoHeaderWithTitle = (title: string) => {
  return { header: () => null, title: getPageTitle(title) };
};

export const getPageTitle = (title: string) => {
  return `${Constants.manifest?.name} - ${title}`;
};

export const getStateFromPath = (
  path: string,
  opts: any,
  dispatch?: AppDispatch
) => {
  return RNGetStateFromPath(path, opts);
};

export const getPathFromState = (
  state: any,
  opts: any,
  dispatch?: AppDispatch
) => {
  return RNGetPathFromState(state, opts);
};

export const getDefaultLinking = (
  screens: ScreenName[],
  dispatch?: AppDispatch,
  state?: RootState
): LinkingOptions<RootStackParamList> => {
  const newScreens = mapValues<ScreenName>(
    keyBy(screens, "name"),
    (screen: ScreenName) => {
      return { path: screen.path };
    }
  );

  const linking = {
    prefixes: [`${APP_URL}/`],
    config: {
      screens: {
        ...newScreens,
        NotFound: "*",
      },
    },
    getPathFromState: (state: any, opts: any) => {
      return getPathFromState(state, opts, dispatch);
    },
    getStateFromPath: (path: string, opts: any) => {
      return getStateFromPath(path, opts, dispatch);
    },
  };
  return linking as LinkingOptions<RootStackParamList>;
};

export const getLinkingActivationCode = (
  screens: ScreenName[],
  dispatch: AppDispatch,
  state: RootState
) => {
  const linking = getDefaultLinking(screens, dispatch, state);
  const { USER_ON_BOARDING_PATH, HOME_PATH, LOGGED_IN_PATH } = RoutePath;

  return {
    ...linking,
    getPathFromState: (routesState: any, opts: any, dispatch: AppDispatch) => {
      let route = routesState.routes[routesState.routes.length - 1];
      const path = opts?.screens[route.name]?.path;
      const ACTION = path.replace("/", "").replaceAll("/", " ").split(" ");
      if (state?.user) {
        const { userActivated, userCodeVerificationSended } = state?.user;
        if (route.name != ScreenNames.UserOnboarding) {
          if (!userActivated && userCodeVerificationSended) {
            route = {
              ...route,
              params: { codeVerificationSended: true },
            };
            routesState.routes[routesState.routes.length - 1] = route;
          }
        }

        if (!userActivated && userCodeVerificationSended) {
          route = {
            ...route,
            path: `${USER_ON_BOARDING_PATH}?userCodeVerificationSended=true`,
          };
          routesState.routes[routesState.routes.length - 1] = route;
        }

        if (
          !userActivated &&
          ACTION[0] != HOME_PATH &&
          ACTION[0] != USER_ON_BOARDING_PATH
        ) {
          route = {
            ...route,
            path: HOME_PATH,
          };
          routesState.routes[routesState.routes.length - 1] = route;
        }

        if (userActivated && ACTION[0] == USER_ON_BOARDING_PATH) {
         /* routesState.routes[routesState.routes.length - 1] = {
            ...route,
            path: RoutePath.LOGGED_IN_PATH,
            name: ScreenNames.LoggedIn,
          };*/
        
        }
      }

      return getPathFromState(routesState, opts, dispatch);
    },

    getStateFromPath: (path: string, opts: any) => {
      const ACTION = path.replace("/", "").replaceAll("/", " ").split(" ");
      

      const { userActivated, userCodeVerificationSended } = state?.user;
      const _getStateFromPath = linking.getStateFromPath
        ? linking.getStateFromPath
        : getStateFromPath;

      if (!userActivated && userCodeVerificationSended) {
        path = `${USER_ON_BOARDING_PATH}?userCodeVerificationSended=true`;
      }

      if (
        !userActivated &&
        ACTION[0] != HOME_PATH &&
        ACTION[0] != USER_ON_BOARDING_PATH
      ) {
        path = HOME_PATH;
      }

      if (userActivated && ACTION[0] == USER_ON_BOARDING_PATH) {
        path = LOGGED_IN_PATH;
      }
      return _getStateFromPath(path, opts);
    },
  };
};
