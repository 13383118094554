import { Easing as RNEasing } from 'react-native';

const Easing =  {
  step0: RNEasing.step0,
  step1: RNEasing.step1,
  linear: RNEasing.linear,
  ease: RNEasing.ease,
  quad: RNEasing.quad,
  cubic: RNEasing.cubic,
  poly: RNEasing.poly,
  sin: RNEasing.sin,
  circle: RNEasing.circle,
  exp: RNEasing.exp,
  elastic: RNEasing.elastic,
  back: RNEasing.back,
  bounce: RNEasing.bounce,
  bezier: RNEasing.bezier,
  in: RNEasing.in,
  out: RNEasing.out,
  inOut: RNEasing.inOut,

  easeIn: RNEasing.bezier(0.42, 0, 1, 1),
  easeOut: RNEasing.bezier(0, 0, 0.58, 1),
  easeInOut: RNEasing.bezier(0.42, 0, 0.58, 1),

  easeInCubic: RNEasing.bezier(0.55, 0.055, 0.675, 0.19),
  easeOutCubic: RNEasing.bezier(0.215, 0.61, 0.355, 1.0),
  easeInOutCubic: RNEasing.bezier(0.645, 0.045, 0.355, 1.0),

  easeInCirc: RNEasing.bezier(0.6, 0.04, 0.98, 0.335),
  easeOutCirc: RNEasing.bezier(0.075, 0.82, 0.165, 1.0),
  easeInOutCirc: RNEasing.bezier(0.785, 0.135, 0.15, 0.86),

  easeInExpo: RNEasing.bezier(0.95, 0.05, 0.795, 0.035),
  easeOutExpo: RNEasing.bezier(0.19, 1.0, 0.22, 1.0),
  easeInOutExpo: RNEasing.bezier(1.0, 0.0, 0.0, 1.0),

  easeInQuad: RNEasing.bezier(0.55, 0.085, 0.68, 0.53),
  easeOutQuad: RNEasing.bezier(0.25, 0.46, 0.45, 0.94),
  easeInOutQuad: RNEasing.bezier(0.455, 0.03, 0.515, 0.955),

  easeInQuart: RNEasing.bezier(0.895, 0.03, 0.685, 0.22),
  easeOutQuart: RNEasing.bezier(0.165, 0.84, 0.44, 1.0),
  easeInOutQuart: RNEasing.bezier(0.77, 0.0, 0.175, 1.0),

  easeInQuint: RNEasing.bezier(0.755, 0.05, 0.855, 0.06),
  easeOutQuint: RNEasing.bezier(0.23, 1.0, 0.32, 1.0),
  easeInOutQuint: RNEasing.bezier(0.86, 0.0, 0.07, 1.0),

  easeInSine: RNEasing.bezier(0.47, 0.0, 0.745, 0.715),
  easeOutSine: RNEasing.bezier(0.39, 0.575, 0.565, 1.0),
  easeInOutSine: RNEasing.bezier(0.445, 0.05, 0.55, 0.95),

  easeInBack: RNEasing.bezier(0.6, -0.28, 0.735, 0.045),
  easeOutBack: RNEasing.bezier(0.175, 0.885, 0.32, 1.275),
  easeInOutBack: RNEasing.bezier(0.68, -0.55, 0.265, 1.55),

  easeInElastic: RNEasing.out(RNEasing.elastic(2)),
  easeInElasticCustom: (bounciness = 2) => RNEasing.out(RNEasing.elastic(bounciness)),
  easeOutElastic: RNEasing.in(RNEasing.elastic(2)),
  easeOutElasticCustom: (bounciness = 2) => RNEasing.in(RNEasing.elastic(bounciness)),
  easeInOutElastic: RNEasing.inOut(RNEasing.out(RNEasing.elastic(2))),
  easeInOutElasticCustom: (bounciness = 2) => RNEasing.inOut(RNEasing.out(RNEasing.elastic(bounciness))),

  easeInBounce: RNEasing.out(RNEasing.bounce),
  easeOutBounce: RNEasing.in(RNEasing.bounce),
  easeInOutBounce: RNEasing.inOut(RNEasing.out(RNEasing.bounce)),
};

export { Easing }