import { AppDispatch } from "apps/salesguru/front/src/store";
import { ScreenName } from "@core/store/navigation/types";
import { getDefaultLinking, getStateFromPath } from "@core/navigation/options";

export const getLinking = (screens: ScreenName[], dispatch?: AppDispatch) => {
  const linking = getDefaultLinking(screens, dispatch);
  return {
    ...linking,
    getStateFromPath: (path: string, opts: any) => {
      const ACTION = path.replace("/", "").replaceAll("/", " ").split(" ");
      return getStateFromPath(path, opts, dispatch);
    },
  };
};
