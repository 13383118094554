import { createContext, MutableRefObject } from "react";
import { PanGestureHandlerEventPayload } from "react-native-gesture-handler";
import { IDraggable, IDroppable, IEventPosition, ILayout, IPoint, IPosition } from "./types";

export interface DNDContextType {
  currentDraggable: MutableRefObject<IDraggable | null> | null;
  currentDroppable: MutableRefObject<IDroppable | null> | null;
  draggables: Partial<IDraggable>[];
  droppables: Partial<IDroppable>[];
  droppablesOffset: IPoint;
  droppablesOffsetDiff: IPoint;
  draggablesOffset: IPoint;
  setDraggables:(draggables:IDraggable[]) => void;
  getDroppableInArea:(position:IPosition) =>  IDroppable | null;
  updateDroppablesLayout: () => void;
  updateAreaLayout: (layout: IPoint) => void;
  updateDroppablesOffset: (offset: IPoint) => void;
  updateDraggablesOffset: (offset: IPoint) => void;
  registerDraggable: (id: number, data: Partial<IDraggable>) => void;
  updateDraggable: (id: number, data: Partial<IDraggable>) => void;
  unregisterDraggable: (id: number) => void;
  registerDroppable: (id: number, data: Partial<IDroppable>) => void;
  updateDroppable: (id: number, data: Partial<IDroppable>) => void;
  unregisterDroppable: (id: number) => void;
  handleTap:(id:number, position:IPosition) => void;
  handleBeforeDragStart: (
    id: number,
    position:IEventPosition,
  ) => void;
  handleDragStart: (
    id: number,
    position:IEventPosition,
  ) => void;
  handleBeforeDragEnd: (
    id: number,
    position:IEventPosition,
  ) => void;
  handleDragEnd: (
    id: number,
    position:IEventPosition,
  ) => void;
  handleDragMove: (
    id: number,
    position:IEventPosition,
  ) => void;
  getDraggable: (id: number) => IDraggable | null;
  getDroppable: (id: number) => IDroppable | null;
}

export default createContext<DNDContextType>({
  currentDraggable: null,
  currentDroppable: null,
  draggables: [],
  droppables: [],
  droppablesOffset: { x: 0, y: 0 },
  droppablesOffsetDiff: { x: 0, y: 0 },
  draggablesOffset: { x: 0, y: 0 },
  setDraggables:(draggables:IDraggable[]) => [],
  getDroppableInArea:(position:IPosition) => null,
  updateDroppablesLayout: () => {},
  updateAreaLayout: (layout: IPoint) => {},
  updateDroppablesOffset: (offset: IPoint) => {},
  updateDraggablesOffset: (offset: IPoint) => {},
  registerDraggable: (id: number, data: Partial<IDraggable>) => {},
  updateDraggable: (id: number, data: Partial<IDraggable>) => {},
  unregisterDraggable: (id: number) => {},
  registerDroppable: (id: number, data: Partial<IDroppable>) => {},
  updateDroppable: (id: number, data: Partial<IDroppable>) => {},
  unregisterDroppable: (id: number) => {},
  handleTap:(id:number, position: { x: number; y: number }) => {},
  handleBeforeDragStart: (
    id: number,
    position:IEventPosition,
  ) => {},
  handleDragStart: (
    id: number,
    position:IEventPosition,
  ) => {},
  handleBeforeDragEnd: (
    id: number,
    position:IEventPosition
  ) => {},
  handleDragEnd: (
    id: number,
    position:IEventPosition,
  ) => {},
  handleDragMove: (
    id: number,
    position:IEventPosition,
  ) => {},
  getDraggable: (id: number) => null,
  getDroppable: (id: number) => null,
});
