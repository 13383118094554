import { StyleSheet } from "react-native";
import { colors } from "../../../utils/colors";
import { getChooseItemWidth, PROFILE_PICTURE } from "./utils";
import { MQSize, responsiveMargin } from "../../../utils/mq";

export const createStyles = (choiceProfile:PROFILE_PICTURE,  profilePictureCamera:string) => {
  return StyleSheet.create({
    container: {
      alignItems:"center",
      justifyContent:"center"
    },
    containerInner:{
      alignItems:"center",
      justifyContent:"center",
      height:getChooseItemWidth(),
    },
    containerButton:{
      position:"absolute",
      top:0,
      left:-getChooseItemWidth() /2
    },
    pictureButton:{
      zIndex:choiceProfile == PROFILE_PICTURE.DEFAULT ? 1 : 0
    },
    cameraButton:{
      zIndex:choiceProfile == PROFILE_PICTURE.CAMERA ? 1 : 0
    },
    innerButton:{
      width:getChooseItemWidth(),
      height:getChooseItemWidth(),
      borderRadius:10,
      overflow:"hidden",
      alignItems:"center",
      justifyContent:"center"
    },
    pictureInnerButton:{
      backgroundColor:colors.bgGreen
    },
    cameraInnerButton:{
      backgroundColor:!profilePictureCamera ? colors.lightBlue : "transparent"
    },
    innerSelectButton:{
      marginTop:responsiveMargin(MQSize.XSMALL)
    },
    cameraInnerPicture:{
      width:getChooseItemWidth(), 
      height:getChooseItemWidth()
    }
  });
}
