import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {
  DEFAULT_LANGUAGE,
  ELanguages,
  getLanguagesFiles,
  ILanguageData,
  getLanguages,
  ILangFile,
} from "./types";

const initLanguages = (
  languages: ILangFile | null = null
) => {
  if (!i18n.isInitialized) {
    const resources = getLanguagesFiles(languages);
    i18n.use(initReactI18next).init({
      resources,
      lng: DEFAULT_LANGUAGE,
      interpolation: {
        escapeValue: false,
      },
      initImmediate:false
    });
  }
};

export { initLanguages, DEFAULT_LANGUAGE, ELanguages, getLanguages };

export type ILangData = ILanguageData;
