import { ReactNode } from "react";

export const getBoundingClientRect = (node: HTMLElement) => {
  if (node != null) {
    const isElement = node.nodeType === 1;
    if (isElement && typeof node.getBoundingClientRect === "function") {
      return node.getBoundingClientRect();
    }
  }
};

export const getLayout = (node: ReactNode = null) => {
  if (!node) {
    return;
  }
  const bound = getBoundingClientRect(node as HTMLElement);
  if (!bound) {
    return;
  }
  const { x, y, width, height, left, top } = bound;
  return {
    x,
    y,
    width,
    height,
    left,
    top,
  };
};

export interface IPoint {
  x: number;
  y: number;
}

export interface ISize {
  width: number;
  height: number;
}
