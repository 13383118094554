import React, { FunctionComponent, lazy } from "react";
import AppNavigation from "@core/navigation";
import {
  ScreenNames,
  RoutePath
} from "apps/salesguru/front/src/navigation/types";
import { getLinking } from "./options";
import { useSelector } from "react-redux";
import { useDimensions } from "@core/store/dimensions/selecters";


const Home = lazy(() => import("./../screens/home"));
const Error = lazy(() => import("./../screens/error"));
const WheelOfFortune = lazy(() => import("./../screens/games/WheelOfFortune"));
const OnBoarding = lazy(() => import("./../screens/onBoarding"));


export const screens = [
  {
    name: ScreenNames.Home,
    component: Error,
    path: RoutePath.HOME_PATH,
  },
  {
    name: ScreenNames.HomeJoiner,
    component: Home,
    path: RoutePath.HOME_JOINER_PATH,
  },
  {
    name: ScreenNames.WheelOfFortune,
    component: WheelOfFortune,
    path: RoutePath.WHEEL_OF_FORTUNE_PATH,
  },
  {
    name: ScreenNames.OnBoarding,
    component: OnBoarding,
    path: RoutePath.ON_BOARDING_PATH,
  },
  {
    name: ScreenNames.Error,
    component: Error,
    path: RoutePath.ERROR_PATH,
  },
];

interface INavigation {}

const Navigation: FunctionComponent<INavigation> = () => {
  const dimensions = useSelector(useDimensions);
  return (
      <AppNavigation
        getLinking={getLinking}
        defaultScreen={ScreenNames.Home}
        initScreens={screens}
      />
  );
};

export default Navigation;
