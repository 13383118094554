import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Camera as ExpoCamera } from "expo-camera";
import { useTranslation } from "react-i18next";
import { isWeb } from "../../utils";
import { Camera } from "../Camera";
import { useUser } from "../../store/user/selecters";
import { randomPicture } from "../../store/user/reducers";
import { Portal } from "react-native-paper";
import { ProfilePictureChoice } from "./ProfilePictureChoice";
import {
  defaultProfilesPictures,
  PROFILE_PICTURE,
} from "./ProfilePictureChoice/utils";
import { useDimensions } from "../../store/dimensions/selecters";

export interface IProfilePicture {
  onChangeCameraState?: (state: boolean) => void;
  onUpdatePicture?: (picture: string, defaultPicture: boolean) => void;
  onUpdatePictureIndex?: (index: number) => void;
  onSwitchProfileType?:(type:PROFILE_PICTURE) => void;
  defaultProfilePicture?: string | null;
  defaultUserpicture?: string | null;
}

export const ProfilePicture: FunctionComponent<IProfilePicture> = ({
  onChangeCameraState,
  onUpdatePicture,
  onUpdatePictureIndex,
  onSwitchProfileType,
  defaultUserpicture,
  defaultProfilePicture,
}) => {
  const dimensions = useSelector(useDimensions);
  const [cameraVisible, setCameraVisible] = useState<boolean>(false);
  const [choiceProfil, setChoiceProfil] = useState(
    defaultUserpicture ? PROFILE_PICTURE.CAMERA : PROFILE_PICTURE.DEFAULT
  );
  const [profilePictureCamera, setProfilePictureCamera] = useState<string>(
    defaultUserpicture || ""
  );

  const dispatch = useDispatch();
  const user = useSelector(useUser);

  const [selectedDefaultProfileIndex, setSelectedDefaultProfileIndex] =
    useState<number>(user?.defaultPictureIndex || randomPicture);

  const [profileDefaultPicture, setProfileDefaultPicture] = useState<string>(
    defaultProfilePicture ||
      defaultProfilesPictures[selectedDefaultProfileIndex]
  );

  const cam = useRef<ExpoCamera>(null);

  const { t } = useTranslation();


  useEffect(() => {
    if (!user?.userPicture) {
      onUpdatePicture &&
        onUpdatePicture(defaultProfilesPictures[randomPicture], true);
      onUpdatePictureIndex && onUpdatePictureIndex(randomPicture);
    }
  }, [user]);

  const updateCameraState = async (state: boolean) => {
    if (choiceProfil === PROFILE_PICTURE.DEFAULT && user.userPicture) {
      onUpdatePicture && onUpdatePicture(profilePictureCamera, false);
      setChoiceProfil(PROFILE_PICTURE.CAMERA);
      setCameraVisible(state);
    } else {
      if (state) {
        if (isWeb) {
          onChangeCameraState && onChangeCameraState(state);
          setCameraVisible(state);
        } else {
          const { status } = await ExpoCamera.requestCameraPermissionsAsync();
          if (status === "granted") {
            onChangeCameraState && onChangeCameraState(state);
            setCameraVisible(state);
          }
        }
        setChoiceProfil(PROFILE_PICTURE.CAMERA);
      } else {
        onChangeCameraState && onChangeCameraState(state);
        setCameraVisible(state);
      }
    }
  };

  const updateColorProfile = () => {
    if (choiceProfil === PROFILE_PICTURE.CAMERA) {
      const profilIndex = user.defaultPictureIndex || 0;
      onUpdatePicture &&
        onUpdatePicture(
          defaultProfilesPictures[selectedDefaultProfileIndex],
          true
        );
        setSelectedDefaultProfileIndex(profilIndex)
        setProfileDefaultPicture(defaultProfilesPictures[profilIndex]);
        setChoiceProfil(PROFILE_PICTURE.DEFAULT);
    } else {
      if (selectedDefaultProfileIndex >= defaultProfilesPictures.length - 1) {
        onUpdatePicture && onUpdatePicture(defaultProfilesPictures[0], true);
        onUpdatePictureIndex && onUpdatePictureIndex(0);
        setProfileDefaultPicture(defaultProfilesPictures[0])
        setSelectedDefaultProfileIndex(0);
      } else {
        onUpdatePicture &&
          onUpdatePicture(
            defaultProfilesPictures[selectedDefaultProfileIndex + 1],
            true
          );
        onUpdatePictureIndex &&
          onUpdatePictureIndex(selectedDefaultProfileIndex + 1);
        setProfileDefaultPicture(defaultProfilesPictures[selectedDefaultProfileIndex + 1]);
        setSelectedDefaultProfileIndex(selectedDefaultProfileIndex + 1);
      }
    }
  };

  const onTakePicture = async () => {
    if (cam.current) {
      let picture = await cam.current.takePictureAsync({ base64: true });
      if (picture?.base64) {
        setProfilePictureCamera(picture.base64);
        onUpdatePicture && onUpdatePicture(picture.base64, false);
      }
      await updateCameraState(false);
    }
  };

  return (
    <>
      {cameraVisible && (
        <Portal>
          <Camera
            ref={cam}
            visible={cameraVisible}
            onTakeProfilePicture={onTakePicture}
            onCloseCamera={() => updateCameraState(false)}
            userDeniedPermission={() => setCameraVisible(false)}
          />
        </Portal>
      )}
      <ProfilePictureChoice
        choiceProfile={choiceProfil}
        updateCameraState={updateCameraState}
        updateColorProfile={updateColorProfile}
        profileDefaultImage={profileDefaultPicture}
        profilePictureCamera={profilePictureCamera}
        onSwitchProfileType={onSwitchProfileType}
      />
    </>
  );
};
