import { StyleSheet } from "react-native";
import { getHeight, getWidth } from "../../utils";
import { colors } from "../../utils/colors";

export const VideoStyles = (defaultBackground: string) =>
  StyleSheet.create({
    pauseWrapper: {
      position: "absolute",
      top: 24,
      width: "100%",
      alignItems: "center",
    },
    pauseContainer: {
      height: 32,
      padding: 8,
      borderRadius: 16,
      backgroundColor: colors.lightBlack,
      alignItems: "center",
      justifyContent: "center",
    },
    pauseText: {
      color: colors.white,
    },
    wrapper: {
      flex: 1,
      backgroundColor: defaultBackground,
      justifyContent: "center",
    },
    container: {
      height: getHeight() / 2,
      width: getWidth(),
    },
  });
