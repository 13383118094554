import React, { FunctionComponent, useState, useEffect } from "react";
import {Animated, TouchableOpacity, ViewStyle} from "react-native";
import { useSelector } from "react-redux";
import { useDimensions } from "@core/store/dimensions/selecters";
import { MQType, responsiveValue } from "@core/utils/mq";
import { Buzzer as BuzzerSVG } from "@core/components/Svg/Buzzer";
import {styles} from "@core/components/Buzzer/styles";

interface IBuzzer {
  disabled: boolean;
  style?: ViewStyle;
  onPress?: () => void;
}

const getBuzzerDimensions = responsiveValue([
  {
    mq: [MQType.SM],
    value: 200,
  },
  {
    mq: [MQType.MD],
    value: 260,
  },
  {
    mq: [MQType.LG],
    value: 300,
  },
]);

const Buzzer: FunctionComponent<IBuzzer> = ({
  disabled = true,
  style,
  onPress,
}) => {
  const [buzzerAnim, setBuzzerAnim] = useState(
      new Animated.Value(getBuzzerDimensions)
  );

  const onUserPressBuzzer = () => {
    Animated.sequence([
      Animated.timing(buzzerAnim, {
        toValue: getBuzzerDimensions / 1.5,
        duration: 125,
        useNativeDriver: true,
      }),
      Animated.timing(buzzerAnim, {
        toValue: getBuzzerDimensions,
        duration: 125,
        useNativeDriver: true,
      }),
    ]).start();

    onPress?.();
  };

  const dimensions = useSelector(useDimensions);

  return (
    <>
      <TouchableOpacity
        disabled={disabled}
        style={[style, { opacity: disabled ? 0.5 : 1 }]}
        onPress={onUserPressBuzzer}
      >
        <Animated.View
            style={[{ height: buzzerAnim, width: buzzerAnim }, styles.shadow]}
        >
          <BuzzerSVG size={buzzerAnim} />
        </Animated.View>
      </TouchableOpacity>
    </>
  );
};

export default Buzzer;
