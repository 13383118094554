import React, { useCallback, useEffect, useState } from "react";
import { Camera as ExpoCamera } from "expo-camera";
import { View } from "react-native";
import { styles } from "./styles";
import { CameraType, CameraMountError } from "expo-camera/build/Camera.types";
import { useDispatch } from "react-redux";
import { errorNotification } from "../../store/inAppNotification/actions";
import CustomButton from "../CustomButton";
import { MQSize } from "../../utils/mq";
import { updateAppProcessing } from "../../store/app/action";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

const PERMISSION_STATUS_UNKNOW: string = "PERMISSION_STATUS_UNKNOW";

interface ICamera {
  visible: boolean;
  onTakeProfilePicture: () => void;
  onCloseCamera?: () => void;
  orientation?: CameraType;
  hasCloseButton?: boolean;
  hasOrientationButton?: boolean;
  hasPressButtonInCamera?: boolean;
  userDeniedPermission?: () => void;
}

export const Camera = React.forwardRef<ExpoCamera, ICamera>(
  (
    {
      visible = false,
      onCloseCamera,
      onTakeProfilePicture,
      orientation = ExpoCamera.Constants.Type.front,
      hasCloseButton = true,
      hasOrientationButton = false,
      hasPressButtonInCamera = true,
      userDeniedPermission,
    },
    ref
  ) => {
    const [cameraReady, setCameraReady] = useState<boolean>(false);
    const [cameraOrientation, setCameraOrientation] =
      useState<CameraType>(orientation);
    const [hasPermission, setHasPermission] = useState<boolean>(false);
    const [permissionStatus, setPermissionStatus] = useState<string>(
      PERMISSION_STATUS_UNKNOW
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onCameraReady = useCallback(() => {
      setCameraReady(true);
    }, []);

    const flipCamera = useCallback(() => {
      setCameraOrientation(
        cameraOrientation == CameraType.front
          ? CameraType.back
          : CameraType.front
      );
    }, []);

    useEffect(() => {
      (async () => {
        const { status } = await ExpoCamera.requestCameraPermissionsAsync();
        setPermissionStatus(status);
        setHasPermission(status === "granted");

        if (status === "denied") {
          dispatch(
            errorNotification({
              title: t("notification.camera.error.denied.title"),
              description: t("notification.camera.error.denied.description"),
              duration: 5000,
            })
          );
          userDeniedPermission && userDeniedPermission();
        }
        dispatch(updateAppProcessing(false));
      })();

      dispatch(updateAppProcessing(true));
    }, []);

    if (!visible || !hasPermission) {
      return null;
    }

    return (
      <>
        <ExpoCamera
          ref={ref}
          style={styles.container}
          type={cameraOrientation}
          onMountError={(event: CameraMountError) => {
            dispatch(
              errorNotification({
                title: "error",
                description: event.message,
                duration: 5000,
              })
            );
            userDeniedPermission && userDeniedPermission();
          }}
          onCameraReady={onCameraReady}
        >
          <View style={styles.mainContainer}>
            <View style={styles.controls}>
              {hasOrientationButton && !isDesktop && (
                <CustomButton
                  style={styles.controlSpacer}
                  background={false}
                  icon={{
                    name: "CameraFlip",
                    size: MQSize.MEDIUM,
                  }}
                  onPress={flipCamera}
                />
              )}
              {hasCloseButton && (
                <CustomButton
                  style={styles.controlSpacer}
                  background={false}
                  icon={{
                    name: "Close",
                    size: MQSize.MEDIUM,
                  }}
                  onPress={onCloseCamera}
                />
              )}
            </View>

            {hasPressButtonInCamera && cameraReady && (
              <CustomButton
                style={styles.takePicture}
                background={false}
                icon={{
                  name: "Cam",
                  size: MQSize.LARGE,
                }}
                onPress={onTakeProfilePicture}
              />
            )}
          </View>
        </ExpoCamera>
      </>
    );
  }
);
