/* eslint-disable */
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { getHeight, getWidth, isWeb } from "../../../utils";
import { convertHexToRGBA, convertHexToUint } from "../../../utils/colors";
import { ExpoWebGLRenderingContext, GLView } from "expo-gl";
//@ts-ignore
import PIXI from "expo-pixi/lib/Pixi";

import { getRandomArbitrary } from "../../../utils/maths";
import { range, shuffle } from "lodash";
import {
  LayoutChangeEvent,
  PixelRatio,
  StyleProp,
  View,
  ViewStyle,
} from "react-native";

export const MIN_SCALE = 0.2;
export const MAX_SCALE = 1;
export const PARTICLE_SIZE = 50;
export const OFFSET_TOP = 100;
export const SPEED = 1;
export const NUM_PARTICLES = 200;
export interface IConfettiOptions {
  width: number;
  height: number;
  colors: string[];
  numParticles: number;
  playing: boolean;
  onCompleteEmpty: () => void;
  style: StyleProp<ViewStyle>;
}

interface IConfettiRef {
  start: () => void;
  pause: () => void;
}

type Size = {
  width: number;
  height: number;
};

const Confetti = forwardRef<IConfettiRef, Partial<IConfettiOptions>>(
  (
    {
      width = getWidth(),
      height = getHeight(),
      numParticles = NUM_PARTICLES,
      colors = ["#BADF44", "#38D7E7", "#FFA52E", "#EE316B"],
      playing = false,
      onCompleteEmpty,
      style,
    },
    ref
  ) => {
    const stage = useRef<PIXI.Container>();
    const renderer = useRef<PIXI.Renderer>();
    const graphics = useRef<PIXI.Graphics[]>([]);
    const scale = useRef<number[]>([]);
    const xAdd = useRef<number[]>([]);
    const xInit = useRef<number[]>([]);
    const yOut = useRef<boolean[]>([]);
    const timer = useRef<number>(0);
    const frame = useRef<number>(0);
    const isAnimating = useRef<boolean>(false);
    const [isReady, setReady] = useState<boolean>(false);
    const isPlaying = useRef<boolean>(playing);
    const [sizes, setSizes] = useState<Size>({
      width: width * PixelRatio.get(),
      height: height * PixelRatio.get(),
    });

    useEffect(() => {
      isPlaying.current = playing;
    }, [playing]);

    useEffect(() => {
      return () => {
        if (isAnimating) {
          cancelAnimationFrame(frame.current);
        }
      };
    }, []);

    const onContextCreate = (context: ExpoWebGLRenderingContext) => {
      stage.current = new PIXI.Container();
      //const getAttributes = context.getContextAttributes || (() => ({}));
      const getAttributes = isWeb
        ? () => ({})
        : context.getContextAttributes || (() => ({}));

      context.getContextAttributes = () => {
        const contextAttributes = getAttributes();
        return {
          ...contextAttributes,
          stencil: true,
        };
      };

      renderer.current = PIXI.autoDetectRenderer({
        width: context.drawingBufferWidth,
        height: context.drawingBufferHeight,
        preserveDrawingBuffer: true,
        context,
        antialias: true,
        backgroundColor: "transparent",
        transparent: true,
        clientHeight: context.drawingBufferHeight,
        autoStart: false,
        screen: {},
      });

      renderer.current._update = () => {
        frame.current = requestAnimationFrame(animate);
        renderer.current.render(stage.current);
        context.endFrameEXP();
        //PIXI.tweenManager.update();
        timer.current++;
      };

      /*setSizes({
        width: context.drawingBufferWidth,
        height: context.drawingBufferHeight,
      });*/

      initParticles();
      setReady(true);
    };

    const generateGraphics = (index: number) => {
      const g = new PIXI.Graphics();
      const randColor = Math.round(Math.random() * (colors.length - 1));
      const color = colors[randColor];
      const rand = Math.round(Math.random() * 1);
      if (rand == 0) {
        g.beginFill(convertHexToUint(color), 1)
          .drawRect(
            -PARTICLE_SIZE / 2,
            -PARTICLE_SIZE / 2,
            PARTICLE_SIZE,
            PARTICLE_SIZE - Math.random() * PARTICLE_SIZE
          )
          .endFill();
      }
      if (rand == 1) {
        g.beginFill(convertHexToUint(color), 1)
          .drawCircle(0, 0, PARTICLE_SIZE / 2)
          .endFill();
      }
      return g;
    };

    const initParticles = () => {
      const width = sizes.width;
      const height = sizes.height;
      for (var i = 0; i < numParticles; i++) {
        
        if(scale.current.filter(s => s > 0.6).length > numParticles / 4){
          scale.current.push(getRandomArbitrary(MIN_SCALE, MAX_SCALE/2));
        }else{
          scale.current.push(getRandomArbitrary(MIN_SCALE, MAX_SCALE));
        }
        const x = Math.floor(Math.random() * width + PARTICLE_SIZE / 2) - PARTICLE_SIZE;
        const y =-height - PARTICLE_SIZE + Math.floor(Math.random() * height);
        const g = generateGraphics(i);
        xAdd.current.push(i % 2 === 0 ? 1 : -1);
        xInit.current.push(x);
        yOut.current.push(true);
        graphics.current.push(g);
        graphics.current[i].position.x = x;
        graphics.current[i].position.y = y;
        graphics.current[i].scale.x = scale.current[i];
        graphics.current[i].scale.y = scale.current[i];
        graphics.current[i].skew.y = (i % 10) * 0.1;
        graphics.current[i].rotation = 0.1 + (i % 5) * 0.1;
        // graphics.current[i].pivot.set(0.5, 0.5)
        stage.current.addChild(graphics.current[i]);
      }
      //renderer.current._update();
    };

    const isEmptyScreen = () => {
      const allOut = yOut.current.filter((out) => {
        return out == true;
      });

      return !isPlaying.current && allOut.length >= graphics.current.length;
    };

    const animate = () => {
      var skews = shuffle(range(0.01, 0.15, 0.02));
      var xPlus = shuffle(range(0.3, 2.2, 0.3));
      var xPlusMax = shuffle(range(40, 400, 40));
      var yPlus = shuffle(range(0.01, 40, 5));
      var rotations = shuffle(range(0.01, 0.15, 0.01));
      const width = sizes.width;
      const height = sizes.height;

      if (isAnimating.current) {
        for (var i = 0; i < numParticles; i++) {
          var y = 0,
            x = 0;
          const rotation = rotations[i % (rotations.length - 1)];
          const skewY = skews[i % (skews.length - 1)];
          const xP = xPlus[i % (xPlus.length - 1)];
          const yP = yPlus[i % (yPlus.length - 1)];
          const xPM = xPlusMax[i % (xPlusMax.length - 1)];
          const startY = -height - PARTICLE_SIZE + Math.floor(Math.random() * height);

          const cantMove = !isPlaying.current && graphics.current[i].y < -PARTICLE_SIZE;
          const velocity = (50+(graphics.current[i].y > 0 ? graphics.current[i].y : 0)) * 0.002;
          //const velocity = 1;
          if (!cantMove) {
            //console.log(i, graphics.current[i].y, height)
            if (graphics.current[i].y > height) {
              
              y = startY;
              x = Math.floor(Math.random() * width + PARTICLE_SIZE / 2) - PARTICLE_SIZE;
              xInit.current[i] = x;
              graphics.current[i].x = x;
            } else {
              
              y =
                graphics.current[i].y +
                Math.abs(velocity * yP * (graphics.current[i].scale.x*1.5) * 0.8 + 5);
             

              if (graphics.current[i].y > height) {
                y = startY;
                x = Math.floor(Math.random() * width + PARTICLE_SIZE / 2) - PARTICLE_SIZE;
                xInit.current[i] = x;
                graphics.current[i].x = x;

              }
            }
            if (graphics.current[i].x > xInit.current[i] + xPM) {
              xAdd.current[i] = -1;
            } else if (graphics.current[i].x < xInit.current[i] - xPM) {
              xAdd.current[i] = 1;
            }
   
            if (xAdd.current[i] === -1) {
              x = graphics.current[i].x - xP;
            } else {
              x = graphics.current[i].x + xP;
            }

            graphics.current[i].x = x;
 
            graphics.current[i].scale.x = scale.current[i];
            graphics.current[i].scale.y = scale.current[i];
            graphics.current[i].position.x = x;
            graphics.current[i].position.y = y;
            graphics.current[i].skew.y += i % 2 === 0 ? skewY : skewY * -1;
            graphics.current[i].skew.x += i % 2 === 0 ? skewY : skewY * -1;
            graphics.current[i].rotation += i % 3 === 0 ? rotation : -rotation;
          }

          yOut.current[i] = (
            y > height + PARTICLE_SIZE * 2
          );
        }

        if (isEmptyScreen() && onCompleteEmpty && !isPlaying.current) {
          onCompleteEmpty();
          isAnimating.current = false;
          cancelAnimationFrame(frame.current);
          timer.current = 0;
          return;
        }
      }
      renderer.current._update();
      isAnimating.current = true;
    };

    useEffect(() => {
      if (playing && isReady && !isAnimating.current) {
        animate();
      }
    }, [playing, isReady, isAnimating.current]);

    const onLayout = ({
      nativeEvent: {
        layout: { width, height },
      },
    }: LayoutChangeEvent) => {
      const scale = PixelRatio.get();
      setSizes({ width: width * scale, height: height * scale });
    };

    useEffect(() => {
      console.log("sizes", sizes);
    }, [sizes]);

    return (
      <View
        onLayout={onLayout}
        style={[{ flex: 1, width, height }, style]}
        pointerEvents={"none"}
      >
        <GLView
          key={"Expo.Sketch-Confetti"}
          style={{ width, height }}
          onContextCreate={onContextCreate}
        />
      </View>
    );
  }
);

export default Confetti;
