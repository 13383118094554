import { ViewStyle } from "react-native";

interface IPoint {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

enum IDragEventType {
  DRAG_START = "drag_start",
  DRAG_END = "drag_end",
  DRAG_UPDATE = "drag_update",
  RESIZE_START = "resize_start",
  RESIZE_END = "resize_end",
  RESIZE_UPDATE = "resize_update",
}

interface IBox {
  x: number;
  y: number;
  width: number;
  height: number;
  limitationHeight: number;
  limitationWidth: number;
  children?: any;
  minWidth?: number;
  minHeight?: number;
  resizable?: boolean;
  draggable?: boolean;
  style?: ViewStyle;
  active?: boolean;
  dilation?: boolean;
  onUpdatePoint?: (type: IDragEventType, point: IPoint) => void;
}

export { IPoint, IDragEventType, IBox };
