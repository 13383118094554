export const UPDATE_THEME = "UPDATE_THEME";

export enum EThemeType {
  Light = "light",
  Dark = "dark"
}

export interface IThemeData {
  name:EThemeType;
  backgroundColor:string;
  pageTitleColor:string;
  textColor:string;
  buttonColor:string;
  buttonDangerColor:string;
  spinnerColor:string;

}

export interface IThemeState {
  name:EThemeType;
  themes:IThemeData[];
}

interface UpdateThemeAction {
  type: typeof UPDATE_THEME;
  payload: EThemeType;
}

export type ThemeActionTypes = UpdateThemeAction
