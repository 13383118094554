import {
  NavigationContainerRef,
} from "@react-navigation/native";
import { ComponentType } from "react";
export const UPDATE_CURRENT_SCREEN = "UPDATE_CURRENT_SCREEN";
export const UPDATE_READY = "UPDATE_READY";
export const UPDATE_SCREEN_NAMES = "UPDATE_SCREEN_NAMES";
export const UPDATE_NAVIGATION_REF = "UPDATE_NAVIGATION_REF";

export enum ENavigationType {
  Stack = "stack",
  Tab = "Tab"
}

export interface INavigationScreen {
  type:ENavigationType;
  parent?:string;
}

export interface ScreenName {
  name: string;
  path:string;
  component: ComponentType<any>;
  navigation:INavigationScreen
  
}

export interface INavigationState {
  screen: string | null;
  screens: ScreenName[];
  ready: boolean;
  ref: NavigationContainerRef<any> | null;
}

interface UpdateScreensNamesAction {
  type: typeof UPDATE_SCREEN_NAMES;
  payload: ScreenName[];
}

interface UpdateCurrentScreenAction {
  type: typeof UPDATE_CURRENT_SCREEN;
  payload: string;
}

interface UpdateReadyAction {
  type: typeof UPDATE_READY;
  payload: boolean;
}

interface UpdateNavigationRefAction {
  type: typeof UPDATE_NAVIGATION_REF;
  payload: NavigationContainerRef<any> | null;
}

export type NavigationActionTypes =
  | UpdateScreensNamesAction
  | UpdateCurrentScreenAction
  | UpdateReadyAction
  | UpdateNavigationRefAction;
