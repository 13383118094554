export const UPDATE_SOCKET_EVENTS = "UPDATE_SOCKET_EVENTS";
export const UPDATE_SOCKET = "UPDATE_SOCKET";
export const UPDATE_SOCKET_INTERNET_STATUS = "UPDATE_SOCKET_INTERNET_STATUS";

export interface ISocketState {
  socket: WebSocket;
  isOnline: boolean;
  events: WebSocketMessageEvent | MessageEvent | null;
}

interface UpdateSocketEventsAction {
  type: typeof UPDATE_SOCKET_EVENTS;
  payload: WebSocketMessageEvent | MessageEvent;
}

interface UpdateSocketAction {
  type: typeof UPDATE_SOCKET;
  payload: WebSocket;
}

interface UpdateSocketStatusAction {
  type: typeof UPDATE_SOCKET_INTERNET_STATUS;
  payload: boolean;
}

export type SocketActionTypes =
  | UpdateSocketEventsAction
  | UpdateSocketAction
  | UpdateSocketStatusAction;
