import React, { useState, useEffect, forwardRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { useSelector } from "react-redux";
import {
  MQType,
  responsiveValue,
  responsiveFontSize,
  FontSizeType,
} from "../../utils/mq";
import { Fonts } from "../../utils/fonts";
import { colors, convertHexToRGBA } from "../../utils/colors";

import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  cancelAnimation
} from "react-native-reanimated";
import { useDimensions } from "../../store/dimensions/selecters";
import { Easing } from "@core/utils/reanimated";


const config = () => {
  const SWITCH_BORDERS: number = responsiveValue([
    {
      mq: [MQType.LG],
      value: 5,
    },
    {
      mq: [MQType.MD],
      value: 3,
    }
  ])
  const SWITCH_WIDTH: number = responsiveValue([
    {
      mq: [MQType.LG],
      value: 80,
    },
    {
      mq: [MQType.MD],
      value: 60,
    }
  ]);
  const BUTTON_WIDTH: number = responsiveValue([
    {
      mq: [MQType.LG],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 20,
    }
  ]);
  return {
    SWITCH_BORDERS,
    SWITCH_WIDTH,
    BUTTON_WIDTH
  }
}


const animeConfig = {
  duration:200,
  easing:Easing.easeOutBack
}

const createStyles = (
  enabled: boolean,
  textColor: string,
  textSize: FontSizeType | number,
  font:Fonts = Fonts.ROBOTO_BOLD,
  backgroundColor: string,
  backgroundColorEnabled: string,
  buttonColor: string,
  direction:ESwitchDirection = ESwitchDirection.LTR
) => {
  const { SWITCH_WIDTH, SWITCH_BORDERS, BUTTON_WIDTH } = config();
  return StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: direction == ESwitchDirection.LTR ? "row" : "row-reverse",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      fontFamily: font,
      fontSize:
        typeof textSize == "string" ? responsiveFontSize(textSize) : textSize,
      color: colors.white,
    },
    switch: {
      marginLeft: direction == ESwitchDirection.LTR ? 15 : 0,
      marginRight: direction == ESwitchDirection.LTR ? 0 : 15,
      width: SWITCH_WIDTH,
      height: SWITCH_BORDERS * 2 + BUTTON_WIDTH,
      borderRadius: 50,
      backgroundColor: !enabled ? backgroundColor : backgroundColorEnabled,
    },
    switchButton: {
      width: BUTTON_WIDTH,
      height: BUTTON_WIDTH,
      borderRadius: 50,
      backgroundColor: buttonColor,
      marginHorizontal: SWITCH_BORDERS,
      marginVertical: SWITCH_BORDERS,
      borderWidth: 1,
      borderColor: convertHexToRGBA(colors.white, 0.6),
    },
  });
};

export enum ESwitchDirection {
  RTL = "RTL",
  LTR = "LTR"
}

export interface ISwitch {
  label?: string;
  enabled?: boolean;
  textColor?: string;
  textSize?: FontSizeType;
  font?:Fonts;
  backgroundColor?: string;
  backgroundColorEnabled?: string;
  buttonColor?: string;
  direction?:ESwitchDirection
  onToggle?: (enabled: boolean) => void;
  size?:number;
}

export interface ISwitchRef {}

const Switch = forwardRef<ISwitchRef, ISwitch>(
  (
    {
      label,
      enabled = false,
      textColor = colors.white,
      textSize = FontSizeType.SMALL,
      font = Fonts.ROBOTO_BOLD,
      backgroundColor = colors.lightBlack,
      backgroundColorEnabled = colors.successGreen,
      buttonColor = colors.lightBlue,
      direction = ESwitchDirection.LTR,
      onToggle,
      size = 20
    },
    ref
  ) => {
    const dimensions = useSelector(useDimensions);
    const [isSwitchOn, setIsSwitchOn] = useState(enabled);
    const [isInited, setIsInited] = useState(false);
    const { SWITCH_WIDTH, SWITCH_BORDERS, BUTTON_WIDTH } = config();
    const styles = createStyles(
      isSwitchOn,
      textColor,
      textSize,
      font,
      backgroundColor,
      backgroundColorEnabled,
      buttonColor,
      direction,
      size
    );

    const toggleButtonTranslateX = () => {
      return !isSwitchOn ? 0 : SWITCH_WIDTH - BUTTON_WIDTH - SWITCH_BORDERS * 2
    }

    const translateX = useSharedValue(toggleButtonTranslateX());
    
    const buttonAnimatedStyles = useAnimatedStyle(() => {
      return {
        transform: [{ translateX: translateX.value }],
      };
    });

    useEffect(() => {
      cancelAnimation(translateX)
      translateX.value = withTiming(toggleButtonTranslateX(), animeConfig, (finished) => {
        
      })
    }, [isSwitchOn]);

    useEffect(() => {
      setIsSwitchOn(enabled);
    }, [enabled])

    
    return (
      <View style={[styles.container]}>
        <Text style={[styles.text]}>{label}</Text>
        <TouchableOpacity onPress={() => {
          setIsSwitchOn(!isSwitchOn)
          onToggle && onToggle(!isSwitchOn);
        }}>
          <View style={styles.switch}>
            <Animated.View style={[styles.switchButton, buttonAnimatedStyles]}></Animated.View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
);

export default Switch;
