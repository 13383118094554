import React, { FunctionComponent } from "react";
import { View, StyleProp, ViewStyle } from "react-native";
import { colors } from "../../../utils/colors";
import { createStyles } from "./styles";
import { ActivityIndicator as RNPActivityIndicator } from "react-native-paper";
import { MQSize, responsiveIconSize } from "../../../utils/mq";

interface IActivityIndicator {
  style?: StyleProp<ViewStyle>;
  color?: string;
  animating?: boolean;
  size?: MQSize;
}

const ActivityIndicator: FunctionComponent<IActivityIndicator> = ({
  style,
  color = colors.dangerRed,
  animating = true,
  size = MQSize.MEDIUM,
}) => {
  const styles = createStyles();
  return (
    <View style={[styles.container, style]}>
      
      <RNPActivityIndicator
        animating={animating}
        size={responsiveIconSize(size)}
        color={color}
      />
    </View>
  );
};

export default ActivityIndicator;
