import { StyleSheet } from "react-native";
import { getWidth } from "../../utils";
import { colors } from "../../utils/colors";
import { Fonts } from "../../utils/fonts";
import {
  FontSizeType,
  MQType,
  responsiveFontSize,
  responsiveValue,
} from "../../utils/mq";

export const footerHeight = () => {
  return responsiveValue([
    {
      mq: [MQType.MD],
      value: 80,
    },
    {
      mq: [MQType.SM],
      value: 40,
    },
  ]);
};

export const nextButtonSize = () => {
  return responsiveValue([
    {
      mq: [MQType.MD],
      value: 60,
    },
    {
      mq: [MQType.SM],
      value: 30,
    },
  ]);
};

export const createPDFStyles = () => {
  return StyleSheet.create({
    container: {
      // height: footerHeight(),
      width: "100%",
      /*position: "absolute",
      bottom: 0,*/
      alignItems: "stretch",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    prevButton: {
      marginLeft: 16,
    },
    footerContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    footerContainerText: {
      fontSize: responsiveFontSize(FontSizeType.SMALL),
      fontFamily: Fonts.ROBOTO_REGULAR,
    },
    pageContainer: {
      //paddingBottom:footerHeight()
      backgroundColor: colors.white,
      flex: 1,
      alignItems: "stretch",
      justifyContent: "space-between",
    },
    nextButton: {
      flex: 1,
      alignItems: "flex-end",
      justifyContent: "center",
      marginRight: 16,
    },
    spinnerWrapper: {
      flex: 1,
      position: "absolute",
      width: "100%",
      height: "100%",
    },
  });
};
