import {
  IRoomState,
  RoomActionTypes,
  UPDATE_CAN_SEND,
  UPDATE_ROOM,
  UPDATE_ROOM_DISPLAY,
} from "./types";

const initialState: IRoomState = {
  id: null,
  display: null,
  formerId: null,
  date: null,
  canSend: false,
};

export function roomReducer(
  state = initialState,
  action: RoomActionTypes
): IRoomState {
  switch (action.type) {
    case UPDATE_ROOM: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_CAN_SEND: {
      return {
        ...state,
        canSend: action.payload,
      };
    }
    case UPDATE_ROOM_DISPLAY: {
      return {
        ...state,
        display: action.payload,
      };
    }
    default:
      return state;
  }
}
