import {
  NavigationContainerRef,
  ParamListBase,
} from "@react-navigation/native";
import { RootStackParamList } from "@app/nativation/types";

import {
  ScreenName,
  NavigationActionTypes,
  UPDATE_CURRENT_SCREEN,
  UPDATE_NAVIGATION_REF,
  UPDATE_READY,
  UPDATE_SCREEN_NAMES,
} from "./types";

export function updateScreenNames(
  screens: ScreenName[]
): NavigationActionTypes {
  return {
    type: UPDATE_SCREEN_NAMES,
    payload: screens,
  };
}

export function updateNavigationRef(
  ref: NavigationContainerRef<RootStackParamList> | null
): NavigationActionTypes {
  return {
    type: UPDATE_NAVIGATION_REF,
    payload: ref,
  };
}

export function updateCurrentNavigation(screen: string): NavigationActionTypes {
  return {
    type: UPDATE_CURRENT_SCREEN,
    payload: screen,
  };
}

export function updateReadyNavigation(ready: boolean): NavigationActionTypes {
  return {
    type: UPDATE_READY,
    payload: ready,
  };
}
