import { isWeb } from ".";

export const setExpiration = (cookieLife:number = 3600) => {
  var today = new Date();
  var expr = new Date(today.getTime() + cookieLife * 1000);
  return  expr.toUTCString();
}

export const setCookie = (name:string, value:string, expires:number = 3600) => {
  if(isWeb){
    let cookie = name + "=" + value + "; ";
    cookie += "expires=" + setExpiration(expires) + "; ";
    cookie += "domain=" + document.domain + "; ";
    document.cookie = cookie;
  }
}

export const readCookie = (name: string) => {
  if (!isWeb) {
    return null;
  }
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
