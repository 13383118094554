import { Dimensions, Platform } from "react-native";
import Constants from "expo-constants";

export const SOCKET_USER_RECONNECT_TIME = 1; // in days
export const SOCKET_USER_RECONNECT_UNIT = "day"; // can update here if you want seconds, minutes, hours, ...
export const ONE_SECONDE_IN_MILLISEC: number = 1000;
export const ANIMATION_DURATION:number = 250;

export const getWidth = (): number => Dimensions.get("window").width;
export const getHeight = (): number => Dimensions.get("window").height;
export const getScreenWidth = (): number => Dimensions.get("screen").width;
export const getScreenHeight = (): number => Dimensions.get("screen").height;
export const getPlatform = (): string => Platform.OS;
export const isDev: boolean = process.env.NODE_ENV == "development";
export const isWeb: boolean = Platform.OS === "web";
export const isIos: boolean = Platform.OS === "ios";
export const isAndroid: boolean = Platform.OS === "android";

export const isSmallScreen: boolean = getWidth() <= 320;
export const isMediumScreen: boolean = getWidth() <= 400;
export const isBigScreen: boolean = getWidth() > 400;

export const MAIL_DESIGNATION = "@opendo.fr";
export const APP_URL =
  Constants.manifest?.extra?.appUrl || "https://app.opendo.fr";

export enum THEME {
  LIGHT = "light",
  NORMAL = "normal",
  DARK = "dark",
}

