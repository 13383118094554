import { StyleSheet } from "react-native";
import { colors } from "@core/utils/colors";
import { Fonts } from "@core/utils/fonts";
import { FontSizeType, responsiveFontSize } from "@core/utils/mq";
export const createStyles = () => {
  return StyleSheet.create({
    timerContainer: {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      backgroundColor: colors.darkPink,
      justifyContent: "center",
      alignItems: "center",
    },
    timerText: {
      fontFamily: Fonts.ROBOTO_BOLD,
      fontSize: responsiveFontSize(FontSizeType.LARGE),
      color: colors.white,
      textAlign: "center",
    },
  });
};
