import Constants from "expo-constants";
import { Resource } from "i18next";
import { merge } from "lodash";

const path = require("path");
export enum ELanguages {
  fr = "fr",
  en = "en",
  es = "es",
}

export interface ILangFile {
  [key: string]: Object;
}

export interface ILanguageData {
  code: string;
  file: Object; 
  flag: string;
}

export const DEFAULT_LANGUAGE =
  Constants.manifest?.extra?.languages.defaultLang || ELanguages.fr;

export const defaultLang = [
  {
    code: "es",
    file: require("./lang/es.ts").default,
    flag: require("../../assets/images/lang/es.png"),
  },
  {
    code: "fr",
    file: require("./lang/fr.ts").default,
    flag: require("../../assets/images/lang/fr.png"),
  },
  {
    code: "en",
    file: require("./lang/en.ts").default,
    flag: require("../../assets/images/lang/en.png"),
  },
];

export const getLanguages = (languages: ILangFile | null = null): ILanguageData[] => {
  let appLanguages = defaultLang;
  if (Constants.manifest?.extra?.languages.langs instanceof Array) {
    appLanguages = Constants.manifest?.extra?.languages.langs.map((code:string) => {
      return appLanguages.find(lang => lang.code == code)
    })
    appLanguages = appLanguages.filter((lang) => {
      return Constants.manifest?.extra?.languages.langs.includes(lang.code);
    });
  }

  return appLanguages.map((lang: ILanguageData) => {
    const appLang = languages ? languages[lang.code] || null : null;
   
    const file = (appLang ? merge(lang.file, appLang) : lang.file);
    return { ...lang, file };
  });
};

export const getLanguagesFiles = (
  languages: ILangFile | null = null
): Resource => {
  const langs = getLanguages(languages);
  const files = {}
  langs.forEach((lang) => (files[lang.code] = lang.file));
  return files;
};
