import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkDispatch } from "redux-thunk";
import { userReducer } from "./user/reducers";
import { roomReducer } from "./room/reducers";
import { socketReducer } from "./socket/reducers";
import { inAppNotificationReducer } from "./inAppNotification/reducers";
import { dimensionsReducer } from "./dimensions/reducers";
import { modalsReducer } from "./modals/reducers";
import { navigationReducer } from "./navigation/reducers";
import { analitycsReducer } from "./analitycs/reducers";
import { languagesReducer } from "./languages/reducers";
import { ModalsActionTypes } from "./modals/types";
import { UserActionTypes } from "./user/types";
import { IANActionTypes } from "./inAppNotification/types";
import { appReducer } from "./app/reducers";
import { AppActionTypes as MyAppActionTypes } from "./app/types";
import { AnalitycsActionTypes } from "./analitycs/types";
import { NavigationActionTypes } from "./navigation/types";
import { LanguagesActionTypes } from "./languages/types";
import { RoomActionTypes } from "./room/types";
import { SocketActionTypes } from "./socket/types";
import { themeReducer } from "./theme/reducers";
import { ThemeActionTypes } from "./theme/types";

//export const storage = isWeb ? require("redux-persist/lib/storage").default : require("@react-native-community/async-storage").default

const userPersistConfig = {
  key: "user",
  storage: storage,
};

const roomPersistConfig = {
  key: "room",
  storage: storage,
};

const languagesPersistConfig = {
  key: "languages",
  storage: storage,
};


export const rootReducers = {
  app: appReducer,
  theme:themeReducer,
  user: persistReducer(userPersistConfig, userReducer),
  room: persistReducer(roomPersistConfig, roomReducer),
  socket: socketReducer,
  inAppNotification: inAppNotificationReducer,
  dimensions: dimensionsReducer,
  modals: modalsReducer,
  navigation: navigationReducer,
  analitycs: analitycsReducer,
  languages:persistReducer(languagesPersistConfig, languagesReducer)
}


export const createAppStore = (appReducers = {}) => {
  return createStore(combineReducers({ ...rootReducers, ...appReducers }), applyMiddleware(thunk));
}

export const store = createAppStore();


export type AppCoreActionTypes =
  | MyAppActionTypes
  | ThemeActionTypes
  | UserActionTypes
  | RoomActionTypes
  | AnalitycsActionTypes
  | NavigationActionTypes
  | IANActionTypes
  | ModalsActionTypes
  | LanguagesActionTypes
  | SocketActionTypes;


export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = ThunkDispatch<RootState, unknown, AppCoreActionTypes>;

export type Selector<S> = (state: RootState) => S;