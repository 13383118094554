import { StyleSheet } from "react-native";

export const createStyles = (centered: boolean = false, size: number = 0) => {
  return StyleSheet.create({
    icon: {
      transform: [
        { translateX: centered ? -(size / 2) : 0 },
        { translateY: centered ? -(size / 2) : 0 }
      ]
    }
  });
};
