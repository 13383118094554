import React, { FunctionComponent } from "react";
import { View } from "react-native";
import LottieView from "lottie-react-native";
import { createStyles } from "./styles";

interface IScreenLoader {}

const ScreenLoader: FunctionComponent<IScreenLoader> = ({}) => {
  const styles = createStyles();
  return (
    <View style={styles.container}>
      <LottieView
        style={styles.animation}
        source={require('./../../../assets/animations/animatedScreenLoader.json')}
        autoPlay={true}
        loop={true}
      />
    </View>
  );
};

export default ScreenLoader;
