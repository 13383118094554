import React, { FunctionComponent } from "react";
import { View, Text } from "react-native";
import { createStyles } from "./styles";

const packageJson = require("../../../../../../package.json");

interface IAppVersion {}

const AppVersion: FunctionComponent<IAppVersion> = ({}) => {
  const styles = createStyles();
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{packageJson.version}</Text>
    </View>
  );
};

export default AppVersion;
