import { StyleSheet } from "react-native";
import { colors } from "../../utils/colors";

export const createStyles = () => {
  return StyleSheet.create({
    container: {
      flex: 1,
      zIndex:1
    },
    processingContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      position:"absolute",
      top:0,
      left:0,
      width:"100%",
      height:"100%",
      zIndex:1
    },
    processingBackground:{
      position:"absolute",
      top:0,
      left:0,
      width:"100%",
      height:"100%",
      zIndex:0,
      backgroundColor:colors.darkBlue
    },
    indicatorContainer:{
      
    },
    appContainer: {
      flex: 1,
      zIndex:0
    },
  });
};
