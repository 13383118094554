import * as React from "react";
import Svg, { Path, G, Circle } from "react-native-svg";
import { FunctionComponent } from "react";
import { ISvgIcons } from "../types";
import { colors } from "../../../utils/colors";

export const IconCamera: FunctionComponent<ISvgIcons> = ({
  size = 24,
  color = colors.lightBlue,
  stroke = colors.white,
}) => {
  return (
    <Svg id="bt_photo" width={size} height={size} viewBox="0 0 160 160">
      <G id="Groupe_731" data-name="Groupe 731">
        <Circle
          id="Ellipse_57"
          data-name="Ellipse 57"
          cx="80"
          cy="80"
          r="80"
          fill={color}
        />
      </G>
      <G
        id="Groupe_736"
        data-name="Groupe 736"
        transform="translate(36 43.998)"
      >
        <G id="Groupe_733" data-name="Groupe 733">
          <G id="Groupe_732" data-name="Groupe 732">
            <Path
              id="Tracé_567"
              data-name="Tracé 567"
              d="M1446.667,94h-69.332A9.344,9.344,0,0,1,1368,84.667V42a9.346,9.346,0,0,1,9.334-9.334h12V31.333A9.344,9.344,0,0,1,1398.666,22h26.668a9.342,9.342,0,0,1,9.332,9.334v1.334h12A9.344,9.344,0,0,1,1456,42V84.667A9.344,9.344,0,0,1,1446.667,94Zm-69.332-53.334A1.335,1.335,0,0,0,1376,42V84.667A1.335,1.335,0,0,0,1377.334,86h69.332A1.335,1.335,0,0,0,1448,84.667V42a1.335,1.335,0,0,0-1.334-1.334h-16a4,4,0,0,1-4-4V31.333A1.334,1.334,0,0,0,1425.334,30h-26.668a1.335,1.335,0,0,0-1.332,1.334v5.334a4,4,0,0,1-4,4Z"
              transform="translate(-1368 -21.999)"
              fill="#fff"
            />
          </G>
        </G>
        <G
          id="Groupe_734"
          data-name="Groupe 734"
          transform="translate(24 18.668)"
        >
          <Path
            id="Tracé_568"
            data-name="Tracé 568"
            d="M1400,71.333a20,20,0,1,1,20-20A20.023,20.023,0,0,1,1400,71.333Zm0-32a12,12,0,1,0,12,12A12.012,12.012,0,0,0,1400,39.333Z"
            transform="translate(-1380 -31.333)"
            fill="#fff"
          />
        </G>
        <G
          id="Groupe_735"
          data-name="Groupe 735"
          transform="translate(30 24.668)"
        >
          <Path
            id="Tracé_569"
            data-name="Tracé 569"
            d="M1391,50.333a8,8,0,1,1,8-8A8.008,8.008,0,0,1,1391,50.333Zm0-8v0Z"
            transform="translate(-1383 -34.333)"
            fill="#fff"
          />
        </G>
      </G>
    </Svg>
  );
};
