
export default {
  translation: {
    common: {
      button: {
        done: "Complete",
      },
    },
    modals: {},
    components: {
      signature: {
        buttonReset: "Redo signature",
      },
      takePicture: {
        takePicture: "Take picture",
        retakePicture: "Resume picture",
      },
      videoPlayer: {
        pause: "Video is paused",
      },
      profilePicture: {
        retakePicture: "Take picture again",
        retakeColor: "Change color",
      },
    },
    network: {
      noInternet: "You are not connected to the internet",
      recovery: {
        title: "Connection recovery",
        description: "The connection has just been restored !",
      },
    },
    notification: {
      camera: {
        error: {
          denied: {
            title: "Error",
            description: "Permiso no concedido",
          },
        },
      },
    },
    api: {
      error: {
        notFound: {
          title: "Alerte",
          description: "API non trouvé !",
        },
        unknownError: {
          title: "Alerte",
          description: "Un erreur inconnu est apparue",
        },
      },
      warning: {
        notActivated: {
          title: "Alerte",
          description: "Votre abonnement n'est pas activé",
        },
        emailNotFound: {
          title: "Alerte",
          description: "Votre email n'existe pas",
        },
        codeNotValid: {
          title: "Alerte",
          description: "Le code n'est pas valide",
        },
        cantActivate: {
          title: "Alerte",
          description: "Vous ne pouvez pas activer cet email",
        },
      },
      success: {
        codeGenerated: {
          title: "Succès",
          description: "Un code à été envoyé par email",
        },
        activated: {
          title: "Succès",
          description: "Votre abonnement est activé",
        },
      },
    },
    module: {
      userOnBoarding: {
        profileInfos: {
          title: "Enter your details",
          inputs: {
            pseudoTitle: "Pseudo",
            firstNameTitle: "Firstname",
            lastNameTitle: "Name",
            emailTitle: "Email",
          },
        },
        profilePicture: {
          title: "Choose an avatar or take a picture of yourself",
          retakePicture: "Take picture again",
          retakeColor: "Change color",
        },
        roomSelecter: {
          title: "Enter the code indicated in the Animator app",
        },
        errors: {
          voidInput: {
            title: "Warning !",
            description: "All fields are required",
          },
          noValideEmail: {
            title: "Warning !",
            description: "Your email format is not valid",
          },
          wrongRoom: {
            title: "Error...",
            description: "The room code you are trying to enter is not valid",
          },
          wrongEmail: {
            title: "Error...",
            description: "Your email is not valid",
          },
          socketsError: {
            title: "An error occurred",
            description: "An error has occurred, please try again",
          },
        },
      },
    },
  },
};
