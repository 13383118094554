import { StyleSheet } from "react-native";
import { getHeight } from "../../utils";
import { colors } from "../../utils/colors";

export const createStyles = () => {
  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      maxHeight: getHeight(),
      backgroundColor: colors.animatedBlue,
    },
    animation: {
      height: "100%",
      width: "100%",
    },
  });
};
