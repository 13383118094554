import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
} from "react";
import { Text, ViewStyle, Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "@core/store/dimensions/selecters";
import { createStyles } from "./styles";
import { ONE_SECONDE_IN_MILLISEC } from "@core/utils";
import { withAnchorPoint } from "react-native-anchor-point";
import { useElapsedTime } from "use-elapsed-time";
import {
  TIMER_WIDTH,
  TIMER_ANIMATE_ELAPSED_TIME,
  TIMER_APPEAR_DISAPPEAR_DURATION,
} from "./utils";

export interface ITimer {
  duration: number;
  onUpdate?: (elapsedTime: number) => void;
  onComplete?: (totalElapsedTime: number) => void;
  onHide?: () => void;
  style?: ViewStyle;
}

export interface ITimerRef {
  start: () => void;
  stop: () => void;
  reset: () => void;
  getElapsedTime: () => number;
  started: () => boolean;
}

const Timer = forwardRef<ITimerRef, ITimer>(
  ({ duration, onUpdate, onComplete, onHide, style }, ref) => {
    const dispatch = useDispatch();
    const dimension = useSelector(useDimensions);
    const styles = createStyles();
    const scaleAnimation = useRef(new Animated.Value(1)).current;

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isDisplay, setIsDisplay] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [remainingTime, setRemaingTime] = useState<number>(duration);

    useEffect(() => {
      if (remainingTime == TIMER_ANIMATE_ELAPSED_TIME) {
        animateScale();
      }
    }, [remainingTime]);

    const { reset } = useElapsedTime({
      duration,
      isPlaying,
      updateInterval: 1,
      onUpdate: (elapsedTime: number) => {
        setRemaingTime(Math.ceil(duration - elapsedTime));
        setElapsedTime(elapsedTime);
        onUpdate && onUpdate(elapsedTime);
      },
      onComplete: (totalElapsedTime: number) => {
        setIsDisplay(false);
        onComplete && onComplete(totalElapsedTime);
      },
    });

    const animateScale = () => {
      const anim = Animated.loop(
        Animated.sequence([
          Animated.timing(scaleAnimation, {
            toValue: 2,
            duration: ONE_SECONDE_IN_MILLISEC / 2,
            useNativeDriver: true,
          }),
          Animated.timing(scaleAnimation, {
            toValue: 1,
            duration: ONE_SECONDE_IN_MILLISEC / 2,
            useNativeDriver: true,
          }),
        ])
      ).start();
    };

    useEffect(() => {
      if (isDisplay) {
        _start();
      }
      if (!isDisplay) {
        if (isVisible) {
          setIsPlaying(false);
          Animated.timing(scaleAnimation, {
            toValue: 0,
            duration: TIMER_APPEAR_DISAPPEAR_DURATION,
            useNativeDriver: true,
          }).start(() => {
            scaleAnimation.stopAnimation();
            setIsVisible(false);
            if (onHide) {
              onHide();
            }
          });
        }
      }
    }, [isVisible, isDisplay]);

    const _start = () => {
      setIsPlaying(true);
      reset();
      scaleAnimation.stopAnimation();
      if (!isVisible) {
        scaleAnimation.setValue(0);
      }
      Animated.timing(scaleAnimation, {
        toValue: 1,
        duration: TIMER_APPEAR_DISAPPEAR_DURATION,
        useNativeDriver: true,
      }).start(() => {});
      setIsVisible(true);
    };

    const start = () => {
      setIsDisplay(true);
    };

    const stop = () => {
      setIsDisplay(false);
    };

    const resetTimer = () => {
      _start();
    };

    const getTransform = () => {
      let transform = {
        transform: [{ perspective: 1000 }, { scale: scaleAnimation }],
      };
      return withAnchorPoint(
        transform,
        {
          x: 0.5,
          y: 0.5,
        },
        {
          width: TIMER_WIDTH,
          height: TIMER_WIDTH,
        }
      );
    };

    useImperativeHandle(ref, () => ({
      start: () => {
        start();
      },
      stop: () => {
        stop();
      },
      reset: () => {
        resetTimer();
      },
      getElapsedTime: () => elapsedTime,
      started: () => isPlaying && elapsedTime > 0,
    }));

    return (
      <>
        {isVisible && (
          <Animated.View
            ref={ref}
            style={[styles.timerContainer, getTransform()]}
          >
            <Text style={styles.timerText}>{remainingTime}</Text>
          </Animated.View>
        )}
      </>
    );
  }
);

export default Timer;
