export const colors = {
  white: "#fff",
  lightRed: "#ED316B",
  lightRedPastel: "#CF5367",
  lightRed2: "#FF1D25",
  lightBlue: "#38D7E7",
  lightBlue2: "#7F9CE1",
  lightBlue3: "#448fa3",
  lightGreen: "#81B39D",
  lightBlack: "#4D4D4D",
  lightGrey: "#C0CCCC",
  lightGrey2:"#B2BDBD",
  extraLightGrey: "#F2F2F2",
  bgGreen: "#9BB56B",
  bgBlue: "#21B6D1",
  bgRed: "#D83A28",
  bgLightGrey:"#D8E4E6",
  animatedBlue: "#20A3BA",
  pastelOrange: "#FFB347",
  pastelGreen: "#77dd77",
  lightGreen2: "#badf44",
  lightOrange: "#ffa52e",
  darkGrey: "#4D4D4D",
  darkBlue: "#0B2B2E",
  darkBlue2: "#0E9EC9",
  darkBlue3: "#26474D",
  darkBlue4: "#093138",
  darkGreen: "#95b736",
  darkGreen2: "#719c9e",
  darkGreen3: "#13606E",
  darkRed: "#CC5335",
  darkOrange: "#E68653",
  darkYellow: "#FF9D14",
  darkPink: "#EE316B",
  darkBrown: "#807959",
  darkBrown2: "#99947A",
  primaryBlue: "#0E9EC9",
  successGreen: "#C0EA6A",
  dangerRed: "#B2001E",
  disable: "#B4B4B4B4",
  raspberries: "#BF2556",
  brown: "#CCAF8F",
  raspberriesLight: "#B32064",
  greenLight: "#B0CF40",
  black: "#000",
  transparent: "transparent",
  cloudBlue: "#5A89A6",
};

export function getRandomColor() {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const convertHexToUint = (hexCode: string) => {
  return hexCode.replace("#", "0x");
};

/**
 * ECMA2015
 */
export const convertHex = (hexCode: string, opacity: number) => {
  var hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16);

  return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
};

export const convertRGBA = (hexCode: string, opacity: number) => {
  var hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b, a: opacity };
};

/**
 * ECMA2016 / ES6
 */
export const convertHexToRGBA = (hexCode: string, opacity: number = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export const hSLToHex = (h:number, s:number, l:number) => {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  let _r = Math.round((r + m) * 255).toString(16);
  let _g = Math.round((g + m) * 255).toString(16);
  let _b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (_r.length == 1) _r = "0" + _r;
  if (_g.length == 1) _g = "0" + _g;
  if (_b.length == 1) _b = "0" + _b;

  return "#" + _r + _g + _b;
};
