import { AppCoreActionTypes, createAppStore } from '@core/store';
import { ThunkDispatch } from 'redux-thunk';

const appReducers = {};

export const store = createAppStore();

export { appReducers };

export type AppActionTypes = null;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = ThunkDispatch<RootState, unknown, AppCoreActionTypes>;


