import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types';
import { RouteProp } from '@react-navigation/native';

import {
  RoutePath as CoreRoutePath,
  ScreenNames as CoreScreenNames,
  RootStackParamList as CoreRootStackParamList,
} from "@core/navigation/types";

export enum AppRoutePath {
  HOME_PATH = "home",
  HOME_JOINER_PATH = "roomId/:roomId",
  WHEEL_OF_FORTUNE_PATH = "wheelOfFortune",
  ON_BOARDING_PATH = "onBoarding",
  ERROR_PATH = "error"
}

export const RoutePath = { ...AppRoutePath, ...CoreRoutePath };

export enum AppScreenNames {
  Home = "Opendo",
  HomeJoiner = "HomeJoiner",
  WheelOfFortune = "WheelOfFortune",
  OnBoarding = "OnBoarding",
  Error = "Error"
}

export const ScreenNames = { ...AppScreenNames, ...CoreScreenNames };

export type AppRootStackParamList = {
  [ScreenNames.Home]: undefined;
  [ScreenNames.HomeJoiner]: {
    roomId: string;
  };
  [ScreenNames.WheelOfFortune]: undefined;
  [ScreenNames.OnBoarding]: undefined;
  [ScreenNames.Error]: undefined;
};

export type RootStackParamList = AppRootStackParamList & CoreRootStackParamList;


export type RootStackComponent<RouteName extends keyof RootStackParamList> = {
  navigation: StackNavigationProp<RootStackParamList, RouteName>;
  route: RouteProp<RootStackParamList, RouteName>;
};
