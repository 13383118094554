import {
  ILanguagesState,
  LanguagesActionTypes,
  UPDATE_LANGUAGES,
} from "./types";

const initialState: ILanguagesState = {
  languages: [],
};

export function languagesReducer(
  state = initialState,
  action: LanguagesActionTypes
): ILanguagesState {
  switch (action.type) {
    case UPDATE_LANGUAGES: {
      return {
        ...state,
        languages: action.payload,
      };
      break;
    }
    default:
      return state;
  }
}
