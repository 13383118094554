import { StyleSheet } from "react-native";
import { responsiveMargin, MQSize } from "../../utils/mq";
import { colors } from "../../utils/colors";

export const createStyles = (thumbColor: string, maxSize: number) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      width: "100%",
      flexDirection: "column",
      alignItems:"center",
      justifyContent:"center",
      minHeight: maxSize,
      marginBottom: responsiveMargin(MQSize.SMALL)
    },
    sliderContainer:{
      flex: 1,
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    buttonsContainer:{
      flex:1,
      flexDirection:"row",
      alignItems:"center",
      justifyContent:"center",
      marginTop: responsiveMargin(MQSize.SMALL)
    },
    spacer:{
      marginHorizontal:4
    },
    wrapper: {
      flex: 1,
      marginHorizontal: 10,
    },
    thumb: {
      width: 20,
      height: 20,
      borderRadius: 20,
      shadowColor: colors.lightBlack,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 2,
      shadowOpacity: 0.35,
      backgroundColor: thumbColor,
    },
    thumbVisualContainer: {
      alignItems: "center",
      justifyContent: "center",
      width: maxSize,
      height: maxSize,
    },
  });
};
