
import {
  IAnalitycsState,
  AnalitycsActionTypes,
  GTM_READY,
  GA_READY
} from "./types";

const initialState: IAnalitycsState = {
  gtmReady: false,
  gaReady: false
};

export function analitycsReducer(
  state = initialState,
  action: AnalitycsActionTypes
): IAnalitycsState {
  switch (action.type) {
    case GTM_READY: {
      return {
        ...state,
        gtmReady:true
      };
      break;
    }
    case GA_READY: {
      return {
        ...state,
        gaReady:true
      };
      break;
    }
    default:
      return state;
  }
}
