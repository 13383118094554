import { StyleSheet, ViewStyle } from "react-native";
import { colors } from "./colors";
import { MQSize, MQType, responsivePadding, responsiveValue } from "./mq";

export const generalStyle = StyleSheet.create({
  simpleFlex: {
    flex: 1,
  },
  alignCenter: {
    alignItems: "center",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  default: {
    flex: 1,
    height: "100vh",
    width: "100vw",
    backgroundColor: colors.animatedBlue,
  },
  maxWidth: { width: "100%" },
});

export const createGeneralStyle = () => {
  return StyleSheet.create({
    simpleFlex: {
      flex: 1,
    },
    alignCenter: {
      alignItems: "center",
    },
    flexRow: {
      flexDirection: "row",
    },
    flexer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    default: {
      flex: 1,
      height: "100vh",
      width: "100vw",
      backgroundColor: colors.animatedBlue,
    },
    maxWidth: { width: "100%" },
    mainContainer:{
      flex:1
    },
    langSelecter: {
      position: "absolute",
      right: 0,
      top: 0,
      zIndex:1000
    },
  });
};

export const boxShadow = (opacity: number = 0.1): ViewStyle => {
  return {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: opacity,
    shadowRadius: 8.3,
    elevation: 13,
  };
};

export const appPaddingHorizontal = () => {
  return responsiveValue([
    {
      mq: [MQType.XS],
      value: 16,
    },
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 30,
    },
    {
      mq: [MQType.LG],
      value: 40,
    },
  ]);
}