import { StyleSheet } from "react-native";
import { getWidth, isMediumScreen } from "../../utils";
import { colors } from "../../utils/colors";
import { Fonts } from "../../utils/fonts";
import { ENotificationTypes } from "../../store/inAppNotification/types";
import { FontSizeType, MQSize, responsiveFontSize, responsiveMargin } from "@core/utils/mq";

export const notificationBackground = {
  [ENotificationTypes.NONE]: colors.lightBlue,
  [ENotificationTypes.INFO]: colors.lightBlue,
  [ENotificationTypes.DANGER]: colors.bgRed,
  [ENotificationTypes.SUCCESS]: colors.pastelGreen,
  [ENotificationTypes.WARNING]: colors.lightOrange,
};

export const notificationStyles = (type: ENotificationTypes) => {
  const width = getWidth() > 500 ? 500 : getWidth()*.8;
  return StyleSheet.create({
    main: {
      zIndex: 2,
      position: "relative",
      alignItems: "center",
    },
    container: {
      position: "absolute",
      left: getWidth() / 2 - width / 2,
      width,
      borderRadius: 16,
      backgroundColor: notificationBackground[type],
    },
    iconContainer: {
      marginTop: 16,
      marginLeft: 16,
    },
    notificationContainer: {
      flex:1,
      marginLeft: 16,
      marginTop: 16,
      marginRight: 16,
    },
    title: {
      color: colors.white,
      fontSize: responsiveFontSize(FontSizeType.SMALL),
      fontWeight: "bold",
      fontFamily: Fonts.ROBOTO_BOLD,
    },
    subtitleContainer: {
      marginTop:6,
      marginLeft: 0,
      marginBottom: 16,
      marginRight: 16,
    },
    subtitle: {
      color: colors.white,
      fontSize: responsiveFontSize(FontSizeType.XSMALL),
      fontFamily: Fonts.ROBOTO_REGULAR,
    },
    children: {
      flex: 1,
      zIndex: 1,
    },
  });
};
