import { Easing } from "@core/utils/reanimated";
import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { ImageBackground, StyleProp, ViewStyle } from "react-native";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from "react-native-reanimated";
import { getImageBackgroundWrapperStyles } from "./styles";

interface IImageBackgroundWrapper {
  backgroundImage: string | null;
  defaultBackgroundColor: string;
  style?: StyleProp<ViewStyle>;
  opacity?: number;
  children?:ReactNode[] | ReactNode;
  onLoaded?: () => void;
}

const ImageBackgroundWrapper: FunctionComponent<IImageBackgroundWrapper> = ({
  children,
  backgroundImage,
  defaultBackgroundColor,
  style,
  opacity = 1,
  onLoaded,
}) => {
  const styles = getImageBackgroundWrapperStyles(defaultBackgroundColor);
  const [loaded, setLoaded] = useState<boolean>(false);



  const opacityAnimation = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      opacity: opacityAnimation.value,
    };
  });

  useEffect(() => {
    if(loaded){
      opacityAnimation.value = withTiming(1, {
        duration:500,
        easing:Easing.easeOut
      })
    }
  }, [loaded])

  useEffect(() => {
    if(!backgroundImage){
      setLoaded(true);
    }
  }, [])

  return (
    <Animated.View style={[styles.container, style, animatedStyles]}>
      <ImageBackground
        source={{
          uri: backgroundImage ? backgroundImage : "",
        }}
         style={ {
          flex: 1,
          backgroundColor:defaultBackgroundColor,
        }}
        imageStyle={{ opacity }}
        resizeMode={"cover"}
        onLoad={() => {
          setLoaded(true);
          onLoaded && onLoaded()
        }}
      >
        {children}
      </ImageBackground>
    </Animated.View>
  );
};

export default ImageBackgroundWrapper;
