export const GTM_READY = "GTM_READY";
export const GA_READY = "GA_READY";

export interface IAnalitycsState {
  gtmReady:boolean;
  gaReady:boolean;
}

interface GTMReadyAction {
  type: typeof GTM_READY;
  payload: boolean;
}

interface GAReadyAction {
  type: typeof GA_READY;
  payload: boolean;
}

export type AnalitycsActionTypes =
  | GTMReadyAction
  | GAReadyAction;
