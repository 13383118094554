import {
  ISocketState,
  SocketActionTypes,
  UPDATE_SOCKET,
  UPDATE_SOCKET_EVENTS,
  UPDATE_SOCKET_INTERNET_STATUS,
} from "./types";
import { SOCKET_URL } from "../../config";
const initialState: ISocketState = {
  socket: new WebSocket(SOCKET_URL),
  isOnline: false,
  events: null,
};

export function socketReducer(
  state = initialState,
  action: SocketActionTypes
): ISocketState {
  switch (action.type) {
    case UPDATE_SOCKET_EVENTS: {
      return {
        ...state,
        events: action.payload,
      };
    }
    case UPDATE_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };
    case UPDATE_SOCKET_INTERNET_STATUS:
      return {
        ...state,
        isOnline: action.payload,
      };
    default:
      return state;
  }
}
