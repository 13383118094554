import { appPaddingHorizontal } from "@core/utils/generalStyle";
import { Dimensions, StyleSheet, ViewStyle } from "react-native";
import { colors } from "../../utils/colors";
import { MQType, responsiveValue } from "../../utils/mq";

export const languagesSelecterStyles = (
  isOpen: boolean,
  autoPosition: boolean = true
) => {
  const containerSize = responsiveValue([
    {
      mq: [MQType.MD],
      value: 52,
    },
    {
      mq: [MQType.SM],
      value: isOpen ? 52 : 30,
    },
  ]);

  const containerPadding = responsiveValue([
    {
      mq: [MQType.MD],
      value: 8,
    },
    {
      mq: [MQType.SM],
      value: isOpen ? 8 : 3,
    },
  ]);

  const iconSize = responsiveValue([
    {
      mq: [MQType.MD],
      value: 32,
    },
    {
      mq: [MQType.SM],
      value: isOpen ? 32 : 22,
    },
  ]);
  const wrapper: ViewStyle = !autoPosition
    ? {}
    : {
        position: "absolute",
        right: appPaddingHorizontal(),
        top: appPaddingHorizontal(),
        zIndex: 999,
      };

  return StyleSheet.create({
    wrapper,
    container: {
      height: containerSize,
      width: containerSize,
      padding: containerPadding,
      borderRadius: 26,
      borderTopRightRadius: 26,
      borderTopLeftRadius: 26,
      borderBottomLeftRadius: isOpen ? 0 : 26,
      borderBottomRightRadius: isOpen ? 0 : 26,
      borderBottomWidth: isOpen ? 1 : 0,
      borderBottomColor: colors.lightGrey,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colors.white,
      flexDirection: "row",
    },
    flag: {
      height: iconSize,
      width: iconSize,
      borderRadius: 16,
    },
    selecter: {
      height: 24,
      width: 24,
      marginLeft: 8,
    },
    dropDown: {
      width: 52,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 26,
      borderBottomRightRadius: 26,
      justifyContent: "center",
      overflow: "hidden",
    },
    touchableSelecter: {
      height: 52,
      padding: 16,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
    },
    touchableSelecterSelected: {
      backgroundColor: colors.lightGrey,
    },
    selecterFlag: {
      height: 32,
      width: 32,
      borderRadius: 16,
    },
  });
};
