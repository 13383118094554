import React, {
  forwardRef,
  FunctionComponent,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
  ReactNode,
} from "react";
import { View, ActivityIndicator as RNPActivityIndicator } from "react-native";
import { colors } from "../../utils/colors";
import { createStyles } from "./styles";
import { MQSize, responsiveIconSize } from "../../utils/mq";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from "react-native-reanimated";
import { useApp } from "../../hooks/app";
import { Easing } from "../../utils/reanimated";
import { Portal } from "react-native-paper";

interface AnimatedActivityIndicatorRef {
  show: () => void;
  hide: () => void;
}

interface IAnimatedActivityIndicator {
  spinnerColor: string;
  onShow?: () => void;
  onHide?: () => void;
}

interface IActivityIndicator {
  spinnerColor?: string;
  children?:ReactNode[] | ReactNode;
}

const GlobalActivityIndicator: FunctionComponent<IActivityIndicator> = ({
  children,
  spinnerColor = colors.animatedBlue
}) => {
  const styles = createStyles();
  const app = useApp();

  const spinner = useRef<AnimatedActivityIndicatorRef>(null);

  const [hasProcessing, setHasProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (app.state.processing) {
      setHasProcessing(true);
    } else {
      spinner.current?.hide();
    }
  }, [app.state.processing]);

  return (
    <>
      <View style={styles.appContainer}>{children}</View>
      {hasProcessing && (
        <Portal>
          <AnimatedActivityIndicator
            ref={spinner}
            spinnerColor={spinnerColor}
            onShow={() => {}}
            onHide={() => {
              setHasProcessing(false);
            }}
          />
        </Portal>
      )}
    </>
  );
};

export default GlobalActivityIndicator;

const AnimatedActivityIndicator = forwardRef<
  AnimatedActivityIndicatorRef,
  IAnimatedActivityIndicator
>(({ spinnerColor, onShow, onHide }, ref) => {
  const styles = createStyles();
  const backgroundOpacity = useSharedValue(0);
  const indicatorOpacity = useSharedValue(0);
  const indicatorTranslateY = useSharedValue(100);
  const indicatorAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: indicatorOpacity.value,
      transform: [{ translateY: indicatorTranslateY.value }],
    };
  });

  const backgroundAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: backgroundOpacity.value,
    };
  });

  const show = () => {
    backgroundOpacity.value = withTiming(0.6, {
      duration: 200,
      easing: Easing.easeOut,
    });
    indicatorOpacity.value = withTiming(1, {
      duration: 200,
      easing: Easing.easeOut,
    });
    indicatorTranslateY.value = withTiming(
      0,
      {
        duration: 200,
        easing: Easing.easeOut,
      },
      (finished) => {
        if (finished) {
          onShow && onShow();
        }
      }
    );
  };

  const hide = () => {
    backgroundOpacity.value = withDelay(
      200,
      withTiming(
        0,
        {
          duration: 200,
          easing: Easing.easeOut,
        },
        (finished) => {
          onHide && onHide();
        }
      )
    );
    indicatorOpacity.value = withTiming(0, {
      duration: 200,
      easing: Easing.easeOut,
    });
    indicatorTranslateY.value = withTiming(100, {
      duration: 200,
      easing: Easing.easeOut,
    });
  };

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  useEffect(() => {
    show();
    return () => {};
  }, []);

  return (
    <View style={styles.container}>
      <Animated.View style={styles.processingContainer} pointerEvents={"none"}>
        <Animated.View
          style={[styles.processingBackground, backgroundAnimatedStyles]}
        ></Animated.View>
        <Animated.View
          style={[styles.indicatorContainer, indicatorAnimatedStyles]}
        >
          <RNPActivityIndicator
            animating={true}
            size={responsiveIconSize(MQSize.MEDIUM)}
            color={spinnerColor}
          />
        </Animated.View>
      </Animated.View>
    </View>
  );
});
