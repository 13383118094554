import React, { ReactNode, FunctionComponent, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store as coreStore } from './../../store';
import { Provider as PaperProvider, Portal } from 'react-native-paper';
import ScreenLoader from './../ScreenLoader';
import { ILangFile } from '../../languages/types';
import { AnyAction, Store } from 'redux';

const FontsLoader = lazy(() => import('./../FontsLoader'));
const Analitycs = lazy(() => import('./../Analitycs'));
const Screen = lazy(() => import('./../Screen'));
const DimensionsWrapper = lazy(() => import('./../DimensionsWrapper'));
const QueryProvider = lazy(() => import('./../QueryProvider'));
const InAppSockets = lazy(() => import('./../../sockets/inAppSockets'));
const InAppNotification = lazy(() => import('./../InAppNotification'));
const GlobalActivityIndicator = lazy(
  () => import('./../GlobalActivityIndicator')
);
const Network = lazy(() => import('./../Network'));
const Languages = lazy(() => import('./../Languages'));
const ModalsRoot = lazy(() => import('./../Modals/ModalRoot'));
const IconsRoot = lazy(() => import('./../Svg/IconsRoot'));

interface IAppWrapper {
  languages?: ILangFile;
  enableDimensionsWrapper?: boolean;
  children?: ReactNode[] | ReactNode;
  store?: Store<any & AnyAction>;
  modals?: any;
  icons?: any;
}

const AppWrapper: FunctionComponent<IAppWrapper> = ({
  languages,
  enableDimensionsWrapper = true,
  children,
  store,
  modals = {},
  icons = {},
}) => {
  const appStore = store || coreStore;
  return (
    <Suspense fallback={<ScreenLoader />}>
      <FontsLoader>
        <Provider store={appStore}>
          <PaperProvider>
            <Portal.Host>
              <PersistGate loading={null} persistor={persistStore(appStore)}>
                <Languages languages={languages}>
                  <Analitycs>
                    <Screen>
                      <IconsRoot icons={icons}>
                        <ModalsRoot appModalsComponents={modals}>
                          <GlobalActivityIndicator>
                            <DimensionsWrapper
                              enabled={enableDimensionsWrapper}
                            >
                              <Network>
                                <QueryProvider>
                                  <InAppSockets>
                                    <InAppNotification>
                                      {children}
                                    </InAppNotification>
                                  </InAppSockets>
                                </QueryProvider>
                              </Network>
                            </DimensionsWrapper>
                          </GlobalActivityIndicator>
                        </ModalsRoot>
                      </IconsRoot>
                    </Screen>
                  </Analitycs>
                </Languages>
              </PersistGate>
            </Portal.Host>
          </PaperProvider>
        </Provider>
      </FontsLoader>
    </Suspense>
  );
};

export default AppWrapper;
