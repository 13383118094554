import React, {
  FunctionComponent,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { View } from "react-native";
import { Slider as MySlider } from "@miblanchard/react-native-slider";
import CustomButton from "../CustomButton";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";
import { createStyles } from "./styles";
import { DISABLE_OPACITY, THUMB_MIN_SIZE_RATIO } from "./utils";
import { colors } from "../../utils/colors";
import { MQSize } from "../../utils/mq";
import { responsiveIconSize } from "../../utils/mq";

import SliderControls from "./SliderControls";
import { isArray } from "lodash"

interface ISlider {
  disabled: boolean;
  initialValue: number;
  maxValue: number;
  minValue: number;
  displayButton?: boolean;
  buttonIcon?: string;
  onChange?: (value: number) => void;
  onActive?: () => void;
}

export interface ISliderRef {
  setValue:(value:number) => void;
}

const Slider: FunctionComponent<ISlider> = forwardRef<ISliderRef, ISlider>(
  (
    {
      disabled = false,
      initialValue = 0,
      maxValue = 100,
      minValue = 0,
      displayButton = false,
      buttonIcon,
      onChange,
      onActive,
    },
    ref
  ) => {
    const [value, setValue] = useState<number>(initialValue);
    const thumbMaxSize = responsiveIconSize(MQSize.MEDIUM);
    const thumbMinSize = thumbMaxSize * THUMB_MIN_SIZE_RATIO;
    const styles = createStyles(colors.lightBlue, thumbMaxSize);

    const getThumbSizeValue = (value: number) => {
      return (
        ((thumbMaxSize - thumbMinSize) / 100) *
          ((100 / (maxValue - minValue)) * (value - minValue)) +
        thumbMinSize
      );
    };

    const thumbSize = useSharedValue(getThumbSizeValue(initialValue));

    const thumbAnimatedStyle = useAnimatedStyle(() => {
      return {
        width: thumbSize.value,
        height: thumbSize.value,
        borderRadius: thumbSize.value,
      };
    });

    const renderThumbComponent = () => {
      return <Animated.View style={[styles.thumb]}></Animated.View>;
    };

    useEffect(() => {
      thumbSize.value = getThumbSizeValue(value);
      onChange && onChange(value);
    }, [value]);

    useImperativeHandle(ref, () => ({
      setValue,
    }));

    return (
      <View style={styles.container}>
        <View style={styles.sliderContainer}>
          {displayButton && buttonIcon && (
            <CustomButton
              style={{ opacity: !disabled ? 1 : DISABLE_OPACITY }}
              active={!disabled}
              onPress={() => {
                onActive && onActive();
              }}
              icon={{
                size: MQSize.MEDIUM,
                name: buttonIcon,
                background: true,
                backgroundColor: colors.lightBlue,
                color: colors.white,
              }}
            />
          )}
          <MySlider
            containerStyle={[
              styles.wrapper,
              { opacity: !disabled ? 1 : DISABLE_OPACITY },
            ]}
            value={value}
            minimumTrackTintColor={colors.lightBlue}
            maximumTrackTintColor={colors.disable}
            onValueChange={(value:number | number[]) => {
              setValue(value[0]);

            }}
            renderThumbComponent={() => renderThumbComponent()}
            minimumValue={minValue}
            maximumValue={maxValue}
            disabled={disabled}
          />
          <View style={styles.thumbVisualContainer}>
            <Animated.View
              style={[styles.thumb, thumbAnimatedStyle]}
            ></Animated.View>
          </View>
        </View>
      </View>
    );
  }
);

export default Slider;

export { SliderControls };
