import {
  INavigationState,
  NavigationActionTypes,
  UPDATE_CURRENT_SCREEN,
  UPDATE_NAVIGATION_REF,
  UPDATE_READY,
  UPDATE_SCREEN_NAMES,
} from "./types";

const initialState: INavigationState = {
  screen: null,
  screens:[],
  ready:false,
  ref:null
};

export function navigationReducer(
  state = initialState,
  action: NavigationActionTypes
): INavigationState {
  switch (action.type) {

    case UPDATE_SCREEN_NAMES: {
      return {
        ...state,
        screens: action.payload,
      };
    }

    case UPDATE_CURRENT_SCREEN: {
      return {
        ...state,
        screen: action.payload,
      };
    }

    case UPDATE_READY: {
      return {
        ...state,
        ready: action.payload,
      };
    }

    case UPDATE_NAVIGATION_REF: {
      return {
        ...state,
        ref: action.payload,
      };
    }

    default:
      return state;
  }
}
