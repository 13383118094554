import { Dimensions, ViewStyle } from "react-native";
import { each, indexOf } from "lodash";

export interface IMQContent {
  mq: Array<MQType>;
  value: any;
}

export interface IMQSizes {
  [key: string]: Array<IMQContent>;
}

export enum MQType {
  DEFAULT = "default",
  XXS = "xxs",
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl",
  XXL = "xxl",
}

export enum MQSize {
  XXSMALL = "xxsmall",
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  XMEDIUM = "xmedium",
  LARGE = "large",
  XLARGE = "xlarge",
}

export enum FontSizeType {
  XXXSMALL = "xxxsmall",
  XXSMALL = "xxsmall",
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  XXLARGE = "xxlarge",
}

export const FontSizes: IMQSizes = {
  [FontSizeType.XXXSMALL]: [
    {
      mq: [MQType.SM],
      value: 8,
    },
    {
      mq: [MQType.MD],
      value: 10,
    },
    {
      mq: [MQType.LG],
      value: 12,
    },
  ],
  [FontSizeType.XXSMALL]: [
    {
      mq: [MQType.SM],
      value: 10,
    },
    {
      mq: [MQType.MD],
      value: 12,
    },
    {
      mq: [MQType.LG],
      value: 14,
    },
  ],
  [FontSizeType.XSMALL]: [
    {
      mq: [MQType.SM],
      value: 12,
    },
    {
      mq: [MQType.MD],
      value: 14,
    },
    {
      mq: [MQType.LG],
      value: 16,
    },
  ],
  [FontSizeType.SMALL]: [
    {
      mq: [MQType.XS],
      value: 13,
    },
    {
      mq: [MQType.SM],
      value: 16,
    },
    {
      mq: [MQType.MD],
      value: 20,
    },
    {
      mq: [MQType.LG],
      value: 24,
    },
  ],
  [FontSizeType.MEDIUM]: [
    {
      mq: [MQType.XS],
      value: 18,
    },
    {
      mq: [MQType.SM],
      value: 26,
    },
    {
      mq: [MQType.MD],
      value: 32,
    },
  ],
  [FontSizeType.LARGE]: [
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 50,
    },
    {
      mq: [MQType.LG],
      value: 70,
    },
  ],
  [FontSizeType.XLARGE]: [
    {
      mq: [MQType.SM],
      value: 40,
    },
    {
      mq: [MQType.MD],
      value: 60,
    },
    {
      mq: [MQType.LG],
      value: 80,
    },
  ],
  [FontSizeType.XXLARGE]: [
    {
      mq: [MQType.SM],
      value: 100,
    },
    {
      mq: [MQType.MD],
      value: 120,
    },
    {
      mq: [MQType.LG],
      value:140,
    },
  ],
};

export const ButtonSizes: IMQSizes = {
  [MQSize.XSMALL]: [
    {
      mq: [MQType.SM],
      value: 12,
    },
    {
      mq: [MQType.MD],
      value: 12,
    },
    {
      mq: [MQType.LG],
      value: 16,
    },
  ],
  [MQSize.SMALL]: [
    {
      mq: [MQType.SM],
      value: 14,
    },
    {
      mq: [MQType.MD],
      value: 14,
    },
    {
      mq: [MQType.LG],
      value: 20,
    },
  ],
  [MQSize.MEDIUM]: [
    {
      mq: [MQType.SM],
      value: 20,
    },
    {
      mq: [MQType.MD],
      value: 30,
    },
    {
      mq: [MQType.LG],
      value: 40,
    },
  ],
  [MQSize.LARGE]: [
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 40,
    },
    {
      mq: [MQType.LG],
      value: 60,
    },
  ],
  [MQSize.XLARGE]: [
    {
      mq: [MQType.SM],
      value: 40,
    },
    {
      mq: [MQType.MD],
      value: 60,
    },
    {
      mq: [MQType.LG],
      value: 80,
    },
  ],
};

export const IconSizes: IMQSizes = {
  [MQSize.XXSMALL]: [
    {
      mq: [MQType.SM],
      value: 10,
    },
    {
      mq: [MQType.MD],
      value: 12,
    },
    {
      mq: [MQType.LG],
      value: 14,
    },
  ],
  [MQSize.XSMALL]: [
    {
      mq: [MQType.SM],
      value: 20,
    },
    {
      mq: [MQType.MD],
      value: 25,
    },
    {
      mq: [MQType.LG],
      value: 30,
    },
  ],
  [MQSize.SMALL]: [
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 30,
    },
    {
      mq: [MQType.LG],
      value: 40,
    },
  ],
  [MQSize.MEDIUM]: [
    {
      mq: [MQType.XS],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 40,
    },
    {
      mq: [MQType.LG],
      value: 50,
    },
  ],
  [MQSize.XMEDIUM]: [
    {
      mq: [MQType.XS],
      value: 35,
    },
    {
      mq: [MQType.MD],
      value: 65,
    },
    {
      mq: [MQType.LG],
      value: 80,
    },
  ],
  [MQSize.LARGE]: [
    {
      mq: [MQType.SM],
      value: 70,
    },
    {
      mq: [MQType.MD],
      value: 100,
    },
    {
      mq: [MQType.LG],
      value: 120,
    },
  ],
  [MQSize.XLARGE]: [
    {
      mq: [MQType.SM],
      value: 150,
    },
    {
      mq: [MQType.MD],
      value: 250,
    },
    {
      mq: [MQType.LG],
      value: 300,
    },
  ],
};

export const MarginSizes: IMQSizes = {
  [MQSize.XXSMALL]: [
    {
      mq: [MQType.SM],
      value: 8,
    },
    {
      mq: [MQType.LG],
      value: 10,
    },
  ],
  [MQSize.XSMALL]: [
    {
      mq: [MQType.XS],
      value: 8,
    },
    {
      mq: [MQType.SM],
      value: 10,
    },
    {
      mq: [MQType.MD],
      value: 15,
    },
    {
      mq: [MQType.LG],
      value: 20,
    },
    {
      mq: [MQType.XL],
      value: 20,
    },
  ],
  [MQSize.SMALL]: [
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 30,
    },
    {
      mq: [MQType.LG],
      value: 40,
    },
  ],
  [MQSize.MEDIUM]: [
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 30,
    },
    {
      mq: [MQType.LG],
      value: 40,
    },
  ],
  [MQSize.XMEDIUM]: [
    {
      mq: [MQType.SM],
      value: 40,
    },
    {
      mq: [MQType.MD],
      value: 60,
    },
    {
      mq: [MQType.LG],
      value: 80,
    },
  ],
  [MQSize.LARGE]: [
    {
      mq: [MQType.SM],
      value: 70,
    },
    {
      mq: [MQType.MD],
      value: 100,
    },
    {
      mq: [MQType.LG],
      value: 120,
    },
  ],
  [MQSize.XLARGE]: [
    {
      mq: [MQType.SM],
      value: 150,
    },
    {
      mq: [MQType.MD],
      value: 250,
    },
    {
      mq: [MQType.LG],
      value: 300,
    },
  ],
};

export const PaddingSizes: IMQSizes = {
  [MQSize.XXSMALL]: [
    {
      mq: [MQType.SM],
      value: 10,
    },
    {
      mq: [MQType.LG],
      value: 12,
    },
  ],
  [MQSize.XSMALL]: [
    {
      mq: [MQType.SM],
      value: 16,
    },
    {
      mq: [MQType.MD],
      value: 20,
    },
    {
      mq: [MQType.LG],
      value: 30,
    },
  ],
  [MQSize.SMALL]: [
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 30,
    },
    {
      mq: [MQType.LG],
      value: 40,
    },
  ],
  [MQSize.MEDIUM]: [
    {
      mq: [MQType.SM],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 30,
    },
    {
      mq: [MQType.LG],
      value: 40,
    },
  ],
  [MQSize.XMEDIUM]: [
    {
      mq: [MQType.SM],
      value: 40,
    },
    {
      mq: [MQType.MD],
      value: 60,
    },
    {
      mq: [MQType.LG],
      value: 80,
    },
  ],
  [MQSize.LARGE]: [
    {
      mq: [MQType.SM],
      value: 70,
    },
    {
      mq: [MQType.MD],
      value: 100,
    },
    {
      mq: [MQType.LG],
      value: 120,
    },
  ],
  [MQSize.XLARGE]: [
    {
      mq: [MQType.SM],
      value: 150,
    },
    {
      mq: [MQType.MD],
      value: 250,
    },
    {
      mq: [MQType.LG],
      value: 300,
    },
  ],
};

export const sizes = {
  xxs: {
    size: 320,
    q: "max-width",
  },
  xs: {
    size: 400,
    q: "min-width",
  },
  sm: {
    size: 576,
    q: "min-width",
  },
  md: {
    size: 768,
    q: "min-width",
  },
  lg: {
    size: 992,
    q: "min-width",
  },
  xl: {
    size: 1200,
    q: "min-width",
  },
  xxl: {
    size: 1400,
    q: "min-width",
  },
};

// X-Small devices (portrait phones, less than 576px)
export const isXSmallScreen = () => {
  return (
    Dimensions.get("window").width >= 0 &&
    Dimensions.get("window").width < sizes.sm.size
  );
};

// Small devices (landscape phones, 576px and up)
export const isSmallScreen = () => {
  return (
    Dimensions.get("window").width >= sizes.sm.size &&
    Dimensions.get("window").width < sizes.md.size
  );
};

// Medium devices (tablets, 768px and up)
export const isMediumScreen = () => {
  return (
    Dimensions.get("window").width >= sizes.md.size &&
    Dimensions.get("window").width < sizes.lg.size
  );
};

// Large devices (desktops, 992px and up)
export const isLargeScreen = () => {
  return (
    Dimensions.get("window").width >= sizes.lg.size &&
    Dimensions.get("window").width < sizes.xl.size
  );
};

// X-Large devices (large desktops, 1200px and up)
export const isXLargeScreen = () => {
  return (
    Dimensions.get("window").width >= sizes.xl.size &&
    Dimensions.get("window").width < sizes.xxl.size
  );
};

// XX-Large devices (larger desktops, 1400px and up)
export const isXXLargeScreen = () => {
  return Dimensions.get("window").width >= sizes.xxl.size;
};

export const ScreenSize = {
  current() {
    return this.size(Dimensions.get("window").width);
  },
  xxs(w: number) {
    return w < sizes.xxs.size;
  },
  xs(w: number) {
    return w < sizes.xs.size;
  },
  sm(w: number) {
    return w >= sizes.sm.size;
  },
  md(w: number) {
    return w >= sizes.md.size;
  },
  lg(w: number) {
    return w >= sizes.lg.size;
  },
  xl(w: number) {
    return w >= sizes.xl.size;
  },
  xxl(w: number) {
    return w >= sizes.xxl.size;
  },
  size(w: number): MQType {
    if (this.xxl(w)) {
      return MQType.XXL;
    } else if (this.xl(w)) {
      return MQType.XL;
    } else if (this.lg(w)) {
      return MQType.LG;
    } else if (this.md(w)) {
      return MQType.MD;
    } else if (this.sm(w)) {
      return MQType.SM;
    } else if (this.xs(w)) {
      return MQType.XS;
    }
    return MQType.XXS;
  },
};

const findClosestMQ = (styles: Array<IMQContent>, mq: MQType): MQType => {
  /* unik style */
  const tmpStyles: Array<IMQContent> = styles;
  styles = [];
  each(tmpStyles, (style: IMQContent) => {
    each(style.mq, (mq: MQType) => {
      styles.push({
        mq: [mq],
        value: style.value,
      });
    });
  });

  const values = Object.values(MQType);
  const mqIdx = indexOf(values, mq);
  const indexes: Array<number> = [];
  each(styles, (style: IMQContent) => {
    indexes.push(indexOf(values, style.mq[0]));
  });
  const output = indexes.reduce((prev, curr) =>
    Math.abs(curr - mqIdx) < Math.abs(prev - mqIdx) ? curr : prev
  );
  return values[output];
};

export const stylesReducer = (
  styles: Array<IMQContent>,
  size: MQType
): IMQContent => {
  let output = styles.find((style: IMQContent) => {
    return style.mq.includes(size);
  });

  if (output != undefined) {
    return output;
  } else {
    const values = Object.values(MQType);
    const index: number = indexOf(values, size);
    size = findClosestMQ(styles, size);
    return stylesReducer(styles, size);
  }
};

export const responsiveStyles = (styles: Array<IMQContent>): ViewStyle => {
  const size: MQType = ScreenSize.current();
  const style = stylesReducer(styles, size);
  return style?.value;
};

export const responsiveStylesSize = (
  stylesSize: MQSize,
  styles: IMQSizes
): ViewStyle => {
  const size: MQType = ScreenSize.current();
  const style = stylesReducer(styles[stylesSize], size);
  return style?.value;
};

export const responsiveFontSize = (fontSize: FontSizeType): number => {
  const size: MQType = ScreenSize.current();
  const style = stylesReducer(FontSizes[fontSize], size);
  return style?.value;
};

export const responsiveIconSize = (iconSize: MQSize): number => {
  const size: MQType = ScreenSize.current();
  const style = stylesReducer(IconSizes[iconSize], size);
  return style?.value;
};

export const responsivePadding = (paddingSize: MQSize): number => {
  const size: MQType = ScreenSize.current();
  const style = stylesReducer(PaddingSizes[paddingSize], size);
  return style?.value;
};

export const responsiveMargin = (marginSize: MQSize): number => {
  const size: MQType = ScreenSize.current();
  const style = stylesReducer(MarginSizes[marginSize], size);
  return style?.value;
};

export const responsiveValue = (mqc: Array<IMQContent>): number => {
  const size: MQType = ScreenSize.current();
  const style = stylesReducer(mqc, size);
  return style?.value;
};

export const isMQ = (mq: MQType): boolean => {
  switch (mq) {
    case MQType.XS:
      return isXSmallScreen();
      break;
    case MQType.SM:
      return isSmallScreen();
      break;
    case MQType.MD:
      return isMediumScreen();
      break;
    case MQType.LG:
      return isLargeScreen();
      break;
    case MQType.XL:
      return isXLargeScreen();
      break;
    case MQType.XXL:
      return isXXLargeScreen();
      break;
    default:
      return false;
      break;
  }
};