
import React, { FunctionComponent, useRef, useEffect } from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import { colors } from "../../utils/colors";
import { MQSize, responsiveIconSize } from "../../utils/mq";
import { useDimensions } from "../../store/dimensions/selecters";
import {  ISvgIcon } from "./types";
import { createStyles } from "./styles";
import { useApp } from "../../store/app/selecters";

export const SVGIcon: FunctionComponent<ISvgIcon> = ({
  name,
  size = MQSize.MEDIUM,
  color = colors.white,
  stroke,
  background = true,
  backgroundColor = colors.lightBlue,
  style,
  centered = false,
}) => {
  const dimensions = useSelector(useDimensions);
  const { icons } = useSelector(useApp);

  const responsiveSize = useRef<number>(size);

  if (typeof size === "string") {
    responsiveSize.current = responsiveIconSize(size as MQSize);
  }


  const Icon = icons[name];
  

  useEffect(() => {
    if (typeof size === "string") {
      responsiveSize.current = responsiveIconSize(size as MQSize);
    } else {
      responsiveSize.current = size;
    }
  }, [size]);

  const styles = createStyles(centered, responsiveSize.current);

  return (
    <>
      {background && (
        <View
          style={[
            {
              width: responsiveSize.current,
              height: responsiveSize.current,
              borderRadius: responsiveSize.current / 2,
              overflow: "hidden",
              backgroundColor: backgroundColor,
              zIndex: 0,
              alignContent: "center",
              justifyContent: "center",
            },
            style,
            styles.icon,
          ]}
        >
          <Icon
            style={{ zIndex: 1 }}
            size={responsiveSize.current}
            color={color ? color : undefined}
            stroke={stroke ? stroke : undefined}
          />
        </View>
      )}

      {!background && (
        <Icon
          style={[style, styles.icon]}
          size={responsiveSize.current}
          color={color ? color : undefined}
          stroke={stroke ? stroke : undefined}
        />
      )}
    </>
  );
};
