import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useContext,
  useRef,
  forwardRef,
  FunctionComponent,
} from "react";
import { View } from "react-native";
import {
  DroppableGestureEventType,
  IDraggable,
  IDroppable,
  IDroppableRef,
  ILayout,
} from "./types";
import DNDContext from "@core/components/DNDList";
import { getLayout } from "../../utils/display";

const Droppable: FunctionComponent<IDroppable> = forwardRef<
  IDroppableRef,
  IDroppable
>(
  (
    { children, index, id, filled, limitation, droppableInnerRef, onUpdate },
    ref
  ) => {
    const [layout, setLayout] = useState<ILayout>();
    const [inView, setInView] = useState<boolean>(false);
    const [inited, setInited] = useState<boolean>(false);
    const inViewRef = useRef<View>(null);

    const context: DNDContext = useContext(DNDContext);

    const updateDroppable = (
      type: DroppableGestureEventType,
      draggable: IDraggable | null
    ) => {
      onUpdate && onUpdate(type, draggable);
    };

    useEffect(() => {
      const layout = getLayout(inViewRef.current);
      context.registerDroppable(id, {
        index,
        ref,
        droppableInnerRef,
        layout,
        inView: true,
        filled: null,
      });
    }, [inViewRef.current]);

    useImperativeHandle(ref, () => ({
      getLayout: () => {
        return layout;
      },
      updateLayout: () => {
        //inViewRef.current?.updateLayout();
      },
      updateInView: () => {
        const layout = getLayout(inViewRef.current);
        if (layout) {
          setLayout(layout);
        }
      },
      updateDroppable,
    }));

    return (
      <View
        ref={inViewRef}
        onLayout={(e) => {
          const { layout } = e.nativeEvent;
          setLayout({
            x: layout.x,
            y: layout.y,
            width: layout.width,
            height: layout.height,
            top: layout.top,
            left: layout.left,
          });
        }}
      >
        {children}
      </View>
    );
  }
);

/*export default memo(Droppable, (prev, next) => {
  return prev.filled == next.filled;
});*/
export default Droppable;
