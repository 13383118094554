import { StyleSheet } from "react-native";
export const createStyles = () => {
  return StyleSheet.create({
    container: {
      flex:1,
      alignItems:"center",
      justifyContent:"center"
    }
  });
};
