import * as React from "react";
import { FunctionComponent, useEffect, useState, useCallback } from "react";
import { Image, StyleProp, ImageStyle, StyleSheet, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { colors } from "@core/utils/colors";
import { MQType, responsiveValue } from "@core/utils/mq";

export const getSize = () => {
  return responsiveValue([
    {
      mq: [MQType.LG],
      value: 30,
    },
    {
      mq: [MQType.MD],
      value: 20,
    },
    {
      mq: [MQType.SM],
      value: 15,
    },
  ]);
};

interface IImageLoader {
  uri: string;
  style?: StyleProp<ImageStyle>;
  color?: string;
  onLoaded?: (uri: string) => void;
}

const ImageLoader: FunctionComponent<IImageLoader> = ({
  uri,
  style,
  color = colors.lightBlue,
  onLoaded,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const preloadImage = useCallback(() => {
    Image.prefetch(uri)
      .then(() => {
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
      });
  }, [uri]);

  useEffect(() => {
    if (loaded && onLoaded) {
      onLoaded(uri);
    }
  }, [loaded]);

  useEffect(() => {
    preloadImage();
    return () => {
      setLoaded(false);
    };
  }, []);

  return (
    <View
      style={[
        { flex: 1, alignItems: "center", justifyContent: "center" },
      ]}
      pointerEvents={"none"}
    >
      {loaded && (
        <Image
          style={StyleSheet.flatten(style)}
          resizeMode={"cover"}
          source={{
            uri,
            cache: "force-cache",
          }}
        />
      )}

      {!loaded && (
        <ActivityIndicator
          size={getSize()}
          animating={true}
          color={color}
          style={{
            transform: [
              { translateX: -getSize() / 2 },
              { translateY: -getSize() / 2 },
            ],
          }}
        />
      )}
    </View>
  );
};

export default ImageLoader;
