import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { FunctionComponent } from "react";
import { ISvgIcons } from "@core/components/Svg/types";
import { colors } from "@core/utils/colors";

export const Star: FunctionComponent<ISvgIcons> = ({
  size = 24,
  color = colors.white
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 67 67">
      <Path
        d="m33.917 0-.853 12.306a22.355 22.355 0 0 1-20.758 20.759L0 33.918l12.306.852a22.355 22.355 0 0 1 20.758 20.759l.853 12.306.851-12.306A22.357 22.357 0 0 1 55.527 34.77l12.308-.852-12.308-.853a22.357 22.357 0 0 1-20.759-20.759Z"
        fill={color}
      />
    </Svg>
  );
};
