export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_USER_LANG = "UPDATE_USER_LANG";
export const UPDATE_USER_LASTNAME = "UPDATE_USER_LASTNAME";
export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const UPDATE_USER_PICTURE = "UPDATE_USER_PICTURE";
export const UPDATE_DEFAULT_PICTURE_INDEX = "UPDATE_DEFAULT_PICTURE_INDEX";
export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const UPDATE_USER_COLOR = "UPDATE_USER_COLOR";
export const UPDATE_USER_EMAIL_STATUS = "UPDATE_USER_EMAIL_STATUS";

/* IF ACTIVATION CODE NEEDED */
export const UPDATE_USER_CODE = "UPDATE_USER_CODE";
export const UPDATE_USER_CODE_VERIFICATION_SENDED =
  "UPDATE_USER_CODE_VERIFICATION_SENDED";
export const UPDATE_USER_ACTIVATION = "UPDATE_USER_ACTIVATION";
export const UPDATE_USER_ACTIVATED = "UPDATE_USER_ACTIVATED";
export const UPDATE_USER_DEVICE_ID = "UPDATE_USER_DEVICE_ID";
export const UPDATE_USER_ACCESS_TOKEN = "UPDATE_USER_ACCESS_TOKEN";

export interface IUserState {
  userId: string | null;
  session: string | null;
  userName: string | null;
  userLastName: string | null;
  userEmail: string | null;
  userPicture: string | null;
  userColor: string | null;
  defaultPictureIndex: number | null;
  isDefaultPicture: boolean;
  hasEmailError: boolean;
  lang: string;

  //user activation code needed
  userCode:string | null;
  userDeviceId: string | null;
  userCodeVerificationSended: boolean;
  userActivated: boolean;
  userAccessToken: string | null;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: IUserState;
}

interface UpdateUserNameAction {
  type: typeof UPDATE_USER_NAME;
  payload: string;
}

interface UpdateUserLangAction {
  type: typeof UPDATE_USER_LANG;
  payload: string;
}

interface UpdateUserEmailStatusAction {
  type: typeof UPDATE_USER_EMAIL_STATUS;
  payload: boolean;
}

interface UpdateUserLastnameAction {
  type: typeof UPDATE_USER_LASTNAME;
  payload: string;
}

interface UpdateUserEmailAction {
  type: typeof UPDATE_USER_EMAIL;
  payload: string;
}

interface UpdateUserPictureAction {
  type: typeof UPDATE_USER_PICTURE;
  payload: { userPicture: string; isDefaultPicture: boolean };
}

interface UpdateUserIdAction {
  type: typeof UPDATE_USER_ID;
  payload: string;
}

interface UpdatePictureIndexAction {
  type: typeof UPDATE_DEFAULT_PICTURE_INDEX;
  payload: number;
}

interface UpdateUserColorAction {
  type: typeof UPDATE_USER_COLOR;
  payload: string;
}

interface UpdateUserCodeAction {
  type: typeof UPDATE_USER_CODE;
  payload: string | null;
}

interface UpdateUserDeviceIdAction {
  type: typeof UPDATE_USER_DEVICE_ID;
  payload: string;
}

interface UpdateUserCodeVerificationSendedAction {
  type: typeof UPDATE_USER_CODE_VERIFICATION_SENDED;
  payload: boolean;
}

interface UpdateUserActivationAction {
  type: typeof UPDATE_USER_ACTIVATION;
  payload: boolean;
}

interface UpdateUserActivatedAction {
  type: typeof UPDATE_USER_ACTIVATED;
  payload: boolean;
}

interface UpdateUserAccessToken {
  type: typeof UPDATE_USER_ACCESS_TOKEN;
  payload: string;
}

export type UserActionTypes =
  | UpdateUserAction
  | UpdateUserNameAction
  | UpdateUserPictureAction
  | UpdateUserIdAction
  | UpdatePictureIndexAction
  | UpdateUserLastnameAction
  | UpdateUserEmailAction
  | UpdateUserEmailStatusAction
  | UpdateUserColorAction
  | UpdateUserLangAction
  | UpdateUserCodeAction
  | UpdateUserDeviceIdAction
  | UpdateUserCodeVerificationSendedAction
  | UpdateUserActivationAction
  | UpdateUserActivatedAction
  | UpdateUserAccessToken;
