import { StyleSheet } from "react-native";
import { responsiveHeight } from "react-native-responsive-dimensions";
import {
  isMediumScreen,
} from "../../utils";
import { colors } from "../../utils/colors";
import { Fonts } from "../../utils/fonts";
import { FontSizeType, responsiveFontSize } from "../../utils/mq";

const SQUARE_DIMENSIONS = isMediumScreen ? 200 : 240;


export const createStyles = () => {
  return StyleSheet.create({
    container: {
      flex:1,
      alignItems: "center",
      justifyContent: "center"
    },
    cameraSelecterContainer: {
      height: SQUARE_DIMENSIONS,
      width: SQUARE_DIMENSIONS,
      borderRadius: 16,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.lightBlue,
    },
    cameraImage: {
      height: "100%",
      width: "100%",
      borderRadius: 16,
    },
    retakePictureContainer: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      marginTop:16
    },
    retakePictureText: {
      color: colors.white,
      fontFamily: Fonts.ROBOTO_BOLD,
      fontSize: responsiveFontSize(FontSizeType.SMALL),
      marginLeft: 8,
    }
  })
}
