import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { SVGIcon } from "../../components/Svg";
import Animated, {
  useSharedValue,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  Easing,
} from "react-native-reanimated";
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from "react-native-gesture-handler";
import { createStyles } from "./styles";
import { colors } from "../../utils/colors";
import { IBox, IDragEventType } from "./types";
import { MQSize } from "../../utils/mq";

const clamp = (value: number, lowerBound: number, upperBound: number) => {
  "worklet";
  return Math.min(Math.max(lowerBound, value), upperBound);
};

const DragResize: FunctionComponent<IBox> = ({
  x = 0,
  y = 0,
  width = 100,
  height = 100,
  limitationHeight,
  limitationWidth,
  children,
  minWidth = width,
  minHeight = height,
  resizable = true,
  draggable = true,
  active = true,
  dilation = false,
  style,
  onUpdatePoint,
}) => {
  const translateX = useSharedValue(x);
  const translateY = useSharedValue(y);
  const translateWidth = useSharedValue(width);
  const translateHeight = useSharedValue(height);
  const isDragging = useSharedValue(false);
  const isResizing = useSharedValue(false);
  const styles = createStyles();
  const ratio = useSharedValue(1);
  const dilationValue = useSharedValue(dilation);

  type AnimatedGHContext = {
    offsetX: number;
    offsetY: number;
    offsetWidth: number;
    offsetHeight: number;
  };

  const config = {
    duration: 500,
    easing: Easing.bezier(0.5, 0.01, 0, 1),
  };

  const dragHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: (_, ctx) => {
      if ((active && !draggable) || isResizing.value) return;
      ctx.offsetX = translateX.value;
      ctx.offsetY = translateY.value;
      if (onUpdatePoint) {
        onUpdatePoint(IDragEventType.DRAG_START, {
          x: translateX.value,
          y: translateY.value,
        });
      }
    },
    onActive: (event, ctx) => {
      if ((active && !draggable) || isResizing.value) return;
      isDragging.value = true;
      translateX.value = clamp(
        ctx.offsetX + event.translationX,
        0,
        limitationWidth - translateWidth.value
      );
      translateY.value = clamp(
        ctx.offsetY + event.translationY,
        0,
        limitationHeight - translateHeight.value
      );
      if (onUpdatePoint) {
        onUpdatePoint(IDragEventType.DRAG_UPDATE, {
          x: translateX.value,
          y: translateY.value,
        });
      }
    },
    onEnd: () => {
      isDragging.value = false;
      if (onUpdatePoint) {
        onUpdatePoint(IDragEventType.DRAG_END, {
          x: translateX.value,
          y: translateY.value,
        });
      }
    },
  });

  const resizeHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: (event, ctx) => {
      if ((active && !resizable) || isDragging.value) return;
      ctx.offsetWidth = translateWidth.value;
      ctx.offsetHeight = translateHeight.value;
      if (onUpdatePoint) {
        onUpdatePoint(IDragEventType.RESIZE_START, {
          width: translateWidth.value,
          height: translateHeight.value,
        });
      }
    },
    onActive: (event, ctx) => {
      if ((active && !resizable) || isDragging.value) return;
      isResizing.value = true;
      translateWidth.value = event.translationX + ctx.offsetWidth;

      
      if(dilationValue.value){
        translateHeight.value = translateWidth.value * ratio.value;
      }else{
        translateHeight.value = event.translationY + ctx.offsetHeight;
      }
      
      
      translateHeight.value = clamp(
        translateHeight.value,
        minHeight,
        limitationHeight - translateY.value
      );

      let maxWidth = !dilationValue.value ? limitationWidth - translateX.value : translateHeight.value / ratio.value
      let _minWidth = translateHeight.value <= minHeight ? maxWidth : minWidth;

      translateWidth.value = clamp(
        translateWidth.value,
        _minWidth,
        maxWidth
      );
      if (onUpdatePoint) {
        onUpdatePoint(IDragEventType.RESIZE_UPDATE, {
          width: translateWidth.value,
          height: translateHeight.value,
        });
      }
    },
    onEnd: () => {
      isResizing.value = false;
      if (onUpdatePoint) {
        onUpdatePoint(IDragEventType.RESIZE_END, {
          width: translateWidth.value,
          height: translateHeight.value,
        });
      }
    },
  });

  const boxStylePosition = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: translateX.value },
        { translateY: translateY.value },
      ],
    };
  });

  const buttonMoveStylePosition = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translateWidth.value }, { translateY: 0 }],
    };
  });

  const buttonResizeStylePosition = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: translateWidth.value },
        { translateY: translateHeight.value },
      ],
    };
  });

  const boxStyleW = useAnimatedStyle(() => {
    return {
      width: translateWidth.value,
    };
  });

  const boxStyleH = useAnimatedStyle(() => {
    return {
      height: translateHeight.value,
    };
  });


  useEffect(() => {
    ratio.value = height/width;
  }, [])

  return (
    <Animated.View
      style={[styles.box, style, boxStylePosition]}
      pointerEvents={active ? "auto" : "none"}
    >
      <Animated.View style={[styles.boxInner, boxStyleW]}>
        <Animated.View style={[styles.boxInnerH, boxStyleH]}>
          {children}
        </Animated.View>
      </Animated.View>
      {draggable && (
        <PanGestureHandler onGestureEvent={dragHandler}>
          <Animated.View style={[styles.button, buttonMoveStylePosition]}>
            <SVGIcon
              name={"Move"}
              size={MQSize.SMALL}
              background={true}
              color={colors.white}
              backgroundColor={colors.lightBlue}
              centered={true}
            />
          </Animated.View>
        </PanGestureHandler>
      )}

      {resizable && (
        <PanGestureHandler onGestureEvent={resizeHandler}>
          <Animated.View style={[styles.button, buttonResizeStylePosition]}>
            <SVGIcon
              name={"Drag"}
              size={MQSize.SMALL}
              background={true}
              color={colors.white}
              backgroundColor={colors.lightBlue}
              centered={true}
            />
          </Animated.View>
        </PanGestureHandler>
      )}
    </Animated.View>
  );
};

export default DragResize;
