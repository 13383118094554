export interface IVideoPlayer {
  source: string;
  width?: number;
  height?: number;
  onFinish?: () => void;
  defaultTime?: number;
  defaultBackground?: string;
  muted?: boolean;
  isControls?: boolean;
  play?: boolean;
  ratio?: EVideoPlayerRatio;
}

export enum EVideoPlayerRatio {
  _16_9 = "16:9",
  _4_3 = "4:3",
}
