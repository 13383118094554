import { base64DefaultProfile } from "./base64/defaultProfile";

export const defaultProfilesPictures = [
  base64DefaultProfile.DEFAULT_PICTURE_2,
  base64DefaultProfile.DEFAULT_PICTURE_3,
  base64DefaultProfile.DEFAULT_PICTURE_4,
  base64DefaultProfile.DEFAULT_PICTURE_5,
  base64DefaultProfile.DEFAULT_PICTURE_6,
  base64DefaultProfile.DEFAULT_PICTURE_7,
  base64DefaultProfile.DEFAULT_PICTURE_8,
  base64DefaultProfile.DEFAULT_PICTURE_9,
  base64DefaultProfile.DEFAULT_PICTURE_10,
  base64DefaultProfile.DEFAULT_PICTURE_11,
  base64DefaultProfile.DEFAULT_PICTURE_12,
  base64DefaultProfile.DEFAULT_PICTURE_13,
  base64DefaultProfile.DEFAULT_PICTURE_14,
  base64DefaultProfile.DEFAULT_PICTURE_15,
  base64DefaultProfile.DEFAULT_PICTURE_16,
  base64DefaultProfile.DEFAULT_PICTURE_17,
  base64DefaultProfile.DEFAULT_PICTURE_18,
  base64DefaultProfile.DEFAULT_PICTURE_19,
  base64DefaultProfile.DEFAULT_PICTURE_20,
  base64DefaultProfile.DEFAULT_PICTURE_21,
];
