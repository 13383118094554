export const UPDATE_ROOM = "UPDATE_ROOM";
export const UPDATE_ROOM_DISPLAY = "UPDATE_ROOM_DISPLAY";
export const UPDATE_CAN_SEND = "UPDATE_CAN_SEND";

export const DEFAULT_ROOM_TIME_EXPRESSION = "DD-MM-YYYY-HH:mm:ss";

export interface IRoomState {
  display: string | null;
  id: string | null;
  formerId: string | null;
  date: string | null;
  canSend: boolean;
}

interface UpdateRoomAction {
  type: typeof UPDATE_ROOM;
  payload: IRoomState | null;
}

interface UpdateRoomCanSendAction {
  type: typeof UPDATE_CAN_SEND;
  payload: boolean;
}

interface UpdateRoomDisplayAction {
  type: typeof UPDATE_ROOM_DISPLAY;
  payload: string | null;
}

export type RoomActionTypes =
  | UpdateRoomAction
  | UpdateRoomDisplayAction
  | UpdateRoomCanSendAction;
