
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const RESET_MODALS = "RESET_MODALS";
export const REMOVE_MODAL = "REMOVE_MODAL";

export enum ModalType {
  BOTTOM = "bottom",
  POPUP = "popup",
  FULLSCREEN = "fullscreen",
  STANDALONE = "standalone"
}

export interface IModalState {
  modalName:string;
  modalType?: ModalType;
  modalProps?: any;
  level?: number;
  display?:boolean;
  
}

export interface IModalsState {
  activeModals: IModalState[];
  currentLevel: number;
}


export interface IModalProps {
  [key:string]:any;
}



interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: IModalState;
}

interface HideModalAction {
  type: typeof HIDE_MODAL;
  payload: IModalState;
}
interface RemoveModalAction {
  type: typeof REMOVE_MODAL;
  payload: IModalState;
}

interface ResetModalsAction {
  type: typeof RESET_MODALS;
  payload?: string;
}

export type ModalsActionTypes =
  | ShowModalAction
  | HideModalAction
  | RemoveModalAction
  | ResetModalsAction;
