import {
  IUserState,
  UPDATE_DEFAULT_PICTURE_INDEX,
  UPDATE_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_EMAIL_STATUS,
  UPDATE_USER_ID,
  UPDATE_USER_LANG,
  UPDATE_USER_LASTNAME,
  UPDATE_USER_NAME,
  UPDATE_USER_PICTURE,
  UPDATE_USER_COLOR,
  UPDATE_USER_CODE,
  UPDATE_USER_CODE_VERIFICATION_SENDED,
  UPDATE_USER_ACTIVATION,
  UPDATE_USER_ACTIVATED,
  UPDATE_USER_DEVICE_ID,
  UPDATE_USER_ACCESS_TOKEN,
  UserActionTypes,
} from "./types";
import { MAIL_DESIGNATION } from "../../utils";
import { DEFAULT_LANGUAGE } from "../../languages";
import { defaultProfilesPictures } from "../../utils/user";

const generateUserMail = () => {
  let userEmail: string = "";

  while (userEmail.length < 6) {
    userEmail += Math.floor(Math.random() * 10);
  }

  return "speedy-" + userEmail.toString() + MAIL_DESIGNATION;
};

export const generateUserName = () => {
  const fragmentedPartOne = [
    "Flamant",
    "Castor",
    "Fusée",
    "Chausson",
    "Super",
    "Marteau",
    "Clou",
    "Vis",
    "Trompette",
    "Saxo",
    "Geranium",
    "Anaconda",
    "Papillon",
    "Marmotte",
    "Lion",
  ];
  const fragmentedPartTwo = [
    "Fushia",
    "Mauve",
    "Turquoise",
    "Jaune",
    "Rose",
    "Pistache",
    "Cyan",
    "Pourpre",
    "Bordeaux",
    "Violet",
    "Beige",
    "Caramel",
    "Mandarine",
    "Marine",
    "Corail",
  ];
  const indexOne = Math.floor(Math.random() * (fragmentedPartOne.length - 1));
  const indexTwo = Math.floor(Math.random() * (fragmentedPartTwo.length - 1));

  return fragmentedPartOne[indexOne] + fragmentedPartTwo[indexTwo];
};


export const randomPicture = Math.floor(
  Math.random() * defaultProfilesPictures.length
);
export const myGenerateEmail = generateUserMail();
export const SPEEDY_MAIL_REGEX = /speedy-[0-9]+@opendo.fr/;

export const initialState: IUserState = {
  userId: null,
  session: null,
  userName: null,
  userLastName: null,
  userEmail: null,
  userColor: null,
  userPicture: null,
  isDefaultPicture: true,
  defaultPictureIndex: null,
  hasEmailError: false,
  lang: DEFAULT_LANGUAGE,
  userCode: null,
  userDeviceId: null,
  userCodeVerificationSended: false,
  userActivated: false,
  userAccessToken: null,
};

export function userReducer(
  state = initialState,
  action: UserActionTypes
): IUserState {
  switch (action.type) {
    case UPDATE_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_USER_NAME: {
      return {
        ...state,
        userName: action.payload,
      };
    }
    case UPDATE_USER_LANG: {
      return {
        ...state,
        lang: action.payload,
      };
    }
    case UPDATE_USER_LASTNAME: {
      return {
        ...state,
        userLastName: action.payload,
      };
    }
    case UPDATE_USER_EMAIL: {
      return {
        ...state,
        userEmail: action.payload,
      };
    }
    case UPDATE_USER_EMAIL_STATUS: {
      return {
        ...state,
        hasEmailError: action.payload,
      };
    }
    case UPDATE_USER_PICTURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_DEFAULT_PICTURE_INDEX: {
      return {
        ...state,
        defaultPictureIndex: action.payload,
      };
    }
    case UPDATE_USER_ID: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    case UPDATE_USER_COLOR: {
      return {
        ...state,
        userColor: action.payload,
      };
    }
    case UPDATE_USER_CODE: {
      return {
        ...state,
        userCode: action.payload,
      };
    }
    case UPDATE_USER_DEVICE_ID: {
      return {
        ...state,
        userDeviceId: action.payload,
      };
    }

    case UPDATE_USER_CODE_VERIFICATION_SENDED: {
      return {
        ...state,
        userCodeVerificationSended: action.payload,
      };
    }
    case UPDATE_USER_ACTIVATED: {
      return {
        ...state,
        userActivated: action.payload,
      };
    }

    case UPDATE_USER_ACCESS_TOKEN: {
      return {
        ...state,
        userAccessToken: action.payload,
      };
    }
    default:
      return state;
  }
}
