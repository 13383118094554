import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { View, Text } from "react-native";
import ReactPlayer from "react-player";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { VideoStyles } from "./styles";
import { EVideoPlayerRatio, IVideoPlayer } from "./utils";
import { getHeight, getWidth } from "../../utils";
import { colors } from "../../utils/colors";
import { ISize } from "@core/utils/display";

const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const VideoPlayer: FunctionComponent<IVideoPlayer> = ({
  source,
  onFinish,
  defaultTime = 0,
  defaultBackground = colors.black,
  muted = false,
  isControls = true,
  play = true,
  width = getWidth(),
  ratio = EVideoPlayerRatio._16_9 
}) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const player = useRef(null);
  const styles = VideoStyles(defaultBackground);
  const [size, setSize] = useState<ISize>({ width:0, height:0 })

  const { t } = useTranslation();

  useEffect(() => {
    if(source.indexOf('http://') > -1 || source.indexOf('https://') > -1){
      setFileUrl(source);
    }else{
      const blob = b64toBlob(source, "video/mp4");
      const blobUrl = URL.createObjectURL(blob);
  
      setFileUrl(blobUrl);
    }
    
  }, [source]);



  useEffect(() => {
    if (player.current) {
      player?.current?.seekTo(Number(defaultTime));
    }
  }, [defaultTime, player.current]);


  useEffect(() => {
    updateSize(ratio);
  }, [ratio])

  const onEnd = () => {
    if (onFinish) {
      onFinish();
    }
  };

  const ref = (playerRef: any) => {
    player.current = playerRef;
  };

  const updateSize = (ratio:EVideoPlayerRatio) => {
    const s:string[] = ratio.split(':')
    const height = width / Number(s[0]) * Number(s[1]);
    setSize({ width, height })
  }



  return (
    <View style={styles.wrapper}>
      {fileUrl && !play && (
        <View style={styles.pauseWrapper}>
          <View style={styles.pauseContainer}>
            <Text style={styles.pauseText}>
              {t("components.videoPlayer.pause")}
            </Text>
          </View>
        </View>
      )}

      {fileUrl && (
        <ReactPlayer
          ref={ref}
          url={`${fileUrl}`}
          controls={isControls}
          height={size.height}
          width={size.width}
          //style={styles.container}
          playing={play}
          muted={muted}
          playsinline={true}
          onEnded={onEnd}
          loop={false}
        />
      )}
    </View>
  );
};
