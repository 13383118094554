import React, { FunctionComponent, useEffect, useState } from "react";
import { View, Text, Animated, TouchableOpacity, Easing } from "react-native";
import { useSelector } from "react-redux";

import { useInAppNotification } from "../../store/inAppNotification/selecters";
import {
  DEFAULT_ANIMATION_DURATION,
  DEFAULT_NOTIFICATION_DURATION,
  DEFAULT_TOP_VALUE,
  END_TOP_VALUE,
} from "./utils";
import { generalStyle } from "../../utils/generalStyle";
import { IconInformations } from "../Svg/IconInformations";
import { notificationBackground, notificationStyles } from "./styles";
import { Portal } from "react-native-paper";
import { useDimensions } from "../../store/dimensions/selecters";
import { SVGIcon } from "../Svg";
import { MQSize } from "@core/utils/mq";
import { colors } from "@core/utils/colors";

const InAppNotification: FunctionComponent<IInAppNotification> = ({
  children,
}) => {
  const dimensions = useSelector(useDimensions);
  const notifications = useSelector(useInAppNotification);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationAnimation, setNotificationAnimation] = useState(
    new Animated.Value(DEFAULT_TOP_VALUE)
  );

  const styles = notificationStyles(notifications.type);
  const animatedStyle = {
    top: notificationAnimation,
  };

  const backAnimation = () => {
    Animated.timing(notificationAnimation, {
      toValue: DEFAULT_TOP_VALUE,
      duration: DEFAULT_ANIMATION_DURATION,
      useNativeDriver: true,
      easing: Easing.out(Easing.elastic(1)),
    }).start(() => {
      setShowNotification(false);
    });
  };

  const launchAnimation = () => {
    Animated.timing(notificationAnimation, {
      toValue: END_TOP_VALUE,
      duration: DEFAULT_ANIMATION_DURATION,
      useNativeDriver: true,
      easing: Easing.elastic(1),
    }).start();

    setTimeout(
      () => {
        backAnimation();
      },
      notifications.duration && notifications.duration > 0
        ? notifications.duration
        : DEFAULT_NOTIFICATION_DURATION
    );
  };

  useEffect(() => {
    if (notifications?.title && notifications?.type) {
      setShowNotification(true);
      launchAnimation();
    }
  }, [notifications]);

  return (
    <View style={generalStyle.simpleFlex}>
      <View style={styles.main}>
        {notifications?.title && notifications?.type && showNotification && (
          <Portal>
            <Animated.View style={[styles.container, animatedStyle]}>
              <TouchableOpacity
                onPress={() => backAnimation()}
                activeOpacity={1}
              >
                <View style={generalStyle.flexRow}>
                  <View style={styles.iconContainer}>
                    <SVGIcon
                      name={"Informations"}
                      size={MQSize.SMALL}
                      color={notificationBackground[notifications.type]}
                      background={true}
                      backgroundColor={colors.white}
                    />
                  </View>
                  <View style={styles.notificationContainer}>
                    <Text style={styles.title}>{notifications.title}</Text>
                    {notifications?.description && (
                  <View style={styles.subtitleContainer}>
                    <Text style={styles.subtitle}>
                      {notifications.description}
                    </Text>
                  </View>
                )}
                  </View>
                </View>
               
              </TouchableOpacity>
            </Animated.View>
          </Portal>
        )}
      </View>
      <View style={styles.children}>{children}</View>
    </View>
  );
};

export default InAppNotification;
