import {
  IDimensionsState,
  DimensionsActionTypes,
  UPDATE_DIMENSIONS,
} from "./types";

import {
  getWidth,
  getHeight,
  getScreenWidth,
  getScreenHeight,
} from "../../utils";

const initialState: IDimensionsState = {
  height: getHeight(),
  width: getWidth(),
  orientation: getHeight() > getWidth() ? "portrait" : "landscape",
  screenWidth: getScreenWidth(),
  screenHeight: getScreenHeight(),
  screenOrientation:
    getScreenHeight() > getScreenWidth() ? "portrait" : "landscape",
};

export function dimensionsReducer(
  state = initialState,
  action: DimensionsActionTypes
): IDimensionsState {
  switch (action.type) {
    case UPDATE_DIMENSIONS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
