export interface IRect {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface IPoint {
  x: number;
  y: number;
}

export interface ICircle extends IPoint {
  radius: number;
}

export interface InterpolationConfig {
  input: Array<number>;
  output: Array<number>;
}
export const interpolator = (config: InterpolationConfig) => {
  if (config.input.length < 2) {
    throw new Error("input array must have at least 2 entries");
  }

  if (config.output.length < 2) {
    throw new Error("output array must have at least 2 entries");
  }

  if (config.input.length !== config.output.length) {
    throw new Error("input and output array must have same number of items");
  }

  return (x: number) => {
    let i = config.input.indexOf(x);

    // if an exact match exists
    if (i > -1) {
      // return the exact match
      return config.output[i];
    }

    // using clamped values
    if (x < config.input[0]) {
      return config.output[0];
    }

    if (x > config.input[config.input.length - 1]) {
      return config.output[config.output.length - 1];
    }

    // find nearest x
    i = 0;
    while (i < config.input.length && config.input[i] < x) {
      i++;
    }

    const x1 = config.input[i - 1];
    const x2 = config.input[i];
    const y1 = config.output[i - 1];
    const y2 = config.output[i];
    return y1 + (y2 - y1) * ((x - x1) / (x2 - x1));
  };
};

export const inside = (
  point: [number, number],
  vs: Array<[number, number]>
) => {
  var x = point[0],
    y = point[1];

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const getRandomArbitrary = (min: number = 0, max: number = 100, round:boolean = false) => {
  const abr = Math.random() * (max - min) + min;
  return round ? Math.round(abr) : abr;
};

export const norm = (value: number, min: number, max: number) => {
  return (value - min) / (max - min);
};

export const lerp = (lnorm: number, min: number, max: number) => {
  return (max - min) * lnorm + min;
};

export const map = (
  value: number,
  sourceMin: number,
  sourceMax: number,
  destMin: number,
  destMax: number
) => {
  return lerp(norm(value, sourceMin, sourceMax), destMin, destMax);
};

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
};

export const distance = (p0: IPoint, p1: IPoint) => {
  const dx = p1.x - p0.x;
  const dy = p1.y - p0.y;
  return Math.sqrt(dx * dx + dy * dy);
};

export const distanceXY = (x0: number, y0: number, x1: number, y1: number) => {
  const dx = x1 - x0;
  const dy = y1 - y0;
  return Math.sqrt(dx * dx + dy * dy);
};

export const circleCollision = (c0: ICircle, c1: ICircle) => {
  return distance(c0, c1) <= c0.radius + c1.radius;
};

export const circlePointCollision = (x: number, y: number, circle: ICircle) => {
  return distanceXY(x, y, circle.x, circle.y) < circle.radius;
};

export const inRange = (value: number, min: number, max: number) => {
  return value >= Math.min(min, max) && value <= Math.max(min, max);
};

export const pointInRect = (p: IPoint, rect: IRect) => {
  return (
    inRange(p.x, rect.x, rect.x + rect.w) &&
    inRange(p.y, rect.y, rect.y + rect.h)
  );
};

export const rangeIntersect = (
  min0: number,
  max0: number,
  min1: number,
  max1: number
) => {
  return (
    Math.max(min0, max0) >= Math.min(min1, max1) &&
    Math.min(min0, max0) <= Math.max(min1, max1)
  );
};

export const rectIntersect = (r0: IRect, r1: IRect) => {
  return (
    rangeIntersect(r0.x, r0.x + r0.w, r1.x, r1.x + r1.w) &&
    rangeIntersect(r0.y, r0.y + r0.h, r1.y, r1.y + r1.h)
  );
};

export const degreesToRads = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

export const radsToDegrees = (radians: number) => {
  return (radians * 180) / Math.PI;
};

export const randomRange = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};

export const randomInt = (min: number, max: number) => {
  return Math.floor(min + Math.random() * (max - min + 1));
};
