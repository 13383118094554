import { StyleSheet } from "react-native";
import { colors, convertHexToRGBA } from "../../utils/colors";
import { Fonts } from "../../utils/fonts";
import {
  FontSizeType,
  MQSize,
  MQType,
  responsiveFontSize,
  responsiveStyles,
} from "../../utils/mq";
import { BORDER_WIDTH, InputType } from "./utils";

export const inputInnerStyle = (
  textAlign: string = "center",
  size: FontSizeType | number = FontSizeType.MEDIUM,
  hasBorder: boolean = true
) => {
  return {
    paddingHorizontal: 16,
    paddingVertical: size == FontSizeType.SMALL ? 14 : 6,
    backgroundColor: colors.white,
    textAlign,
    fontFamily: Fonts.ROBOTO_BOLD,
    fontSize:
      typeof size != "number" ? responsiveFontSize(size as FontSizeType) : size,
    color: colors.lightBlack,
    placeholderTextColor: convertHexToRGBA(colors.lightBlack, 0.7),
    borderRadius: 12,
    borderWidth: hasBorder ? BORDER_WIDTH : 0,
    borderColor: colors.lightBlue,
  };
};

export const inputStyles = (
  type: InputType = InputType.NORMAL,
  size: FontSizeType | number = FontSizeType.MEDIUM,
  titleColor: string = colors.white,
  textAlign: string = "center",
  hasBorder: boolean = true,
  editable: boolean = true
) => {
  let container = {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: 18,
  };

  let input = inputInnerStyle(textAlign, size, hasBorder)

  switch (type) {
    case InputType.FLUID:
      input = {
        ...input,
        ...{
          width: "100%",
          borderWidth: hasBorder ? BORDER_WIDTH : 0,
          borderColor: colors.lightBlue,
        },
      };
      break;
    case InputType.NORMAL:
      input = {
        ...input,
        ...{
          width: "100%",
          borderWidth: hasBorder ? BORDER_WIDTH : 0,
          borderColor: colors.lightBlue,
        },
      };
      break;

    case InputType.BIG:
      const bigStyles = responsiveStyles([
        {
          mq: [MQType.SM],
          value: {
            width: 140,
            height: 70,
          },
        },
        {
          mq: [MQType.LG],
          value: {
            width: 240,
            height: 100,
          },
        },
      ]);
      input = {
        ...input,
        ...{
          color: colors.lightBlack,
          fontSize: responsiveFontSize(FontSizeType.LARGE),
        },
        ...bigStyles,
      };
      break;
  }

  return StyleSheet.create({
    containerWrapper: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    containerInner: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-end",
      width: "100%",
    },
    container,
    container_error: {
      borderColor: colors.dangerRed,
      borderWidth: 3,
    },
    input,
    text: {
      fontFamily: Fonts.ROBOTO_THIN,
      color: titleColor,
      fontSize: responsiveFontSize(FontSizeType.SMALL),
      marginBottom: 4,
    },
  });
};

export default inputStyles;
