import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  FunctionComponent,
  memo,
} from "react";
import { Pressable, View } from "react-native";
import Animated, {
  useSharedValue,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withTiming,
  withDelay,
  Easing,
  runOnJS,
  cancelAnimation,
} from "react-native-reanimated";
import {
  LongPressGestureHandler,
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  State,
  TapGestureHandler,
  TouchableWithoutFeedback,
} from "react-native-gesture-handler";
import {
  DraggableGestureEventType,
  IDraggable,
  IDraggableRef,
  IDroppable,
  IEventPosition,
} from "./types";
import { createDraggableStyles } from "./styles";
import DNDContext, { DNDContextType } from "./Context";
import { LONG_PRESS_MIN_DURATION } from "./utils";
import { getBoundingClientRect } from "../../utils/display";

type AnimatedGHContext = {
  offsetX: number;
  offsetY: number;
  offsetWidth?: number;
  offsetHeight?: number;
};

const Draggable: FunctionComponent<IDraggable> = forwardRef<
  IDraggableRef,
  IDraggable
>(
  (
    {
      id,
      index,
      children,
      draggable = true,
      active = true,
      longPress = false,
      viewRef,
      style,
      fromPosition = { x: 0, y: 0 },
      onUpdate,
      dropToStartPosition = true,
    },
    ref
  ) => {
    const translateX = useSharedValue(fromPosition.x);
    const translateY = useSharedValue(fromPosition.y);
    const dragging = useRef<boolean>(false);
    const context: DNDContextType = useContext(DNDContext);

    const containerStyles = createDraggableStyles();
    const position = useRef<IEventPosition>({
      absoluteX: 0,
      absoluteY: 0,
      x: 0,
      y: 0,
      translationX: 0,
      translationY: 0,
      offsetX: 0,
      offsetY: 0,
    });
    const [longPressActive, setLongPressActive] = useState<boolean>(false);
    const panHandlerRef = useRef<PanGestureHandler>(null);
    const tapHandlerRef = useRef<TapGestureHandler>(null);
    const longPressHandlerRef = useRef<LongPressGestureHandler>(null);
    const animatedGHContext = useRef<AnimatedGHContext>({
      offsetX: 0,
      offsetY: 0,
    });

    const [forceDroppableNull, setForceDroppableNull] =
      useState<boolean>(false);

    const config = {
      duration: 300,
      easing: Easing.bezier(0.25, 0.1, 0.25, 1),
    };

    const startDrag = (_forceDroppableNull: boolean = false) => {
      if (_forceDroppableNull) {
        setForceDroppableNull(true);
      }
      if (!dragging.current) {
        context.handleBeforeDragStart(id, position.current);
        context.handleDragStart(id, position.current);
      }
      dragging.current = true;
    };

    const endDrag = () => {
      if (dragging.current) {
        context.handleBeforeDragEnd(id, position.current);
        //updateLayout(null);
        context.handleDragEnd(id, position.current);
      }
      dragging.current = false;
    };

    const updatePosition = async (
      x: number,
      y: number,
      duration: number = 300,
      delay: number = 0
    ) => {
      return new Promise<void>((resolve, reject) => {
        const complete = () => {
          const newPosition = getBoundingClientRect(viewRef.current);
          updateLayout({
            absoluteX: newPosition.left,
            absoluteY: newPosition.top,
            x: -translateX.value,
            y: -translateY.value,
            translationX: 0,
            translationY: 0,
          });
          resolve();
        };
        cancelAnimation(translateX);
        cancelAnimation(translateY);
        if (duration > 0) {
          const newConfig = {
            ...config,
            ...{
              duration,
            },
          };
          translateX.value = withDelay(delay, withTiming(x, newConfig));
          translateY.value = withDelay(
            delay,
            withTiming(y, newConfig, (finished) => {
              if (finished) complete();
            })
          );
        } else {
          translateX.value = x;
          translateY.value = y;
          resolve();
        }
      });
    };

    const dragHandler = useAnimatedGestureHandler<
      PanGestureHandlerGestureEvent,
      AnimatedGHContext
    >({
      onStart: (event, ctx) => {
        if (active && !draggable) return;

        /*updateLayout({
          offsetX: event.x,
          offsetY: event.y,
        });*/
      },
      onActive: (event, ctx) => {
        if (active && !draggable) return;
        if (dragging.current) {
          updateLayout(event);

          const { current } = position;

          translateX.value =
            animatedGHContext.current.offsetX + current.translationX;
          translateY.value =
            animatedGHContext.current.offsetY + current.translationY;

          context.handleDragMove(id, position.current);
        }
      },
      onEnd: (event) => {
        endDrag();
      },
    });

    const boxStylePosition = useAnimatedStyle(() => {
      return {
        transform: [
          { translateX: translateX.value },
          { translateY: translateY.value },
        ],
      };
    });

    const updateLayout = (layout: Partial<IEventPosition> | null) => {
      if (!layout) {
        const bounds = getBoundingClientRect(viewRef.current);
        layout = {
          absoluteX: bounds.left,
          absoluteY: bounds.top,
          x: -translateX.value,
          y: -translateY.value,
          translationX: 0,
          translationY: 0,
          offsetX: translateX.value,
          offsetY: translateY.value,
        };
      }
      position.current = { ...position.current, ...layout };
    };

    useEffect(() => {
      context.registerDraggable(id, {
        index,
        onUpdate,
        ref,
      });
      return () => {
        // context.unregisterDraggable(id);
      };
    }, [id, index]);

    const updateDraggable = (
      type: DraggableGestureEventType,
      p: IEventPosition,
      droppable: IDroppable | null
    ) => {
      if (forceDroppableNull) {
        droppable = null;
      }

      const { current } = position;
      if (type == DraggableGestureEventType.DRAG_START) {
        animatedGHContext.current.offsetX = p.absoluteX - p.x;
        animatedGHContext.current.offsetY = p.absoluteY - p.y;
        translateX.value = animatedGHContext.current.offsetX;
        translateY.value = animatedGHContext.current.offsetY;
      }
      if (type == DraggableGestureEventType.DRAG_END) {
        const newPosition = getBoundingClientRect(viewRef.current);
        translateX.value = p.absoluteX - newPosition.x - current.offsetX;
        translateY.value = p.absoluteY - newPosition.y - current.offsetY;
        if (forceDroppableNull) {
          setForceDroppableNull(false);
        }
      }
      onUpdate && onUpdate(type, p, droppable);
    };

    useImperativeHandle(ref, () => ({
      updatePosition,
      updateLayout,
      updateDraggable,
      startDrag,
      endDrag,
      getLayout: () => {
        return position.current;
      },
    }));

    return (
      <PanGestureHandler
        ref={panHandlerRef}
        onGestureEvent={dragHandler}
        enabled={active}
      >
        <Pressable
          disabled={!active}
          delayLongPress={longPress ? LONG_PRESS_MIN_DURATION : 0}
          onLongPress={({ nativeEvent }) => {
            const newPosition = getBoundingClientRect(viewRef.current);
            const { locationX, locationY } = nativeEvent;
            updateLayout({
              absoluteX: newPosition.left,
              absoluteY: newPosition.top,
              x: -translateX.value,
              y: -translateY.value,
              translationX: 0,
              translationY: 0,
              offsetX: locationX - translateX.value,
              offsetY: locationY - translateY.value,
            });
            startDrag();
            setLongPressActive(true);
          }}
          onPressOut={() => {
            endDrag();
            setLongPressActive(false);
          }}
        >
          <Animated.View
            style={[containerStyles.container, style, boxStylePosition]}
            pointerEvents={"none"}
          >
            {children}
          </Animated.View>
        </Pressable>
      </PanGestureHandler>
    );
  }
);

export default memo(Draggable, (prev, next) => {
  return (
    prev.active == next.active &&
    prev.dropped == next.dropped &&
    prev.viewRef == next.viewRef &&
    prev.draggable == prev.draggable &&
    prev.longPress == next.longPress
  );
});
