import React, {
  Children,
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  ReactNode,
  useImperativeHandle,
  forwardRef,
} from "react";
import { View, Text, StyleSheet } from "react-native";
import Swiper, { SwiperControlsProps } from "react-native-web-swiper";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDimensions } from "../../store/dimensions/selecters";
import CustomButton from "../CustomButton";
import { createPaginationDotStyles, swiperStyles } from "./styles";
import { colors } from "../../utils/colors";
import { SwiperCommonControlsType } from "./utils";
import { MQSize } from "../../utils/mq";
import { useCardAnimation } from "@react-navigation/stack";

interface ISwiperSlideComponent {
  data: any;
}

interface ISwiperSlide {
  index: number;
  component: ReactNode;
  processing?: boolean;
}

interface ISwiperCommon {
  canNext: boolean;
  canPrev: boolean;
  prevProcessing?: boolean;
  nextProcessing?: boolean;
  onNext?: (index: number) => Promise<boolean>;
  onEnd?: () => void;
  onSlideChange?: (index: number) => void;
  onBeforePrevCheck?: () => Promise<boolean>;
  onBeforeNextCheck?: () => Promise<boolean>;
  startIndex?: number;
  hasDoneButton?: boolean;
  hasDot?: boolean;
  hasPagination?: boolean;
  controlsType?: SwiperCommonControlsType;
  onEndControlButtonType?: SwiperCommonControlsType;
  prevNextButtonsEnabled?: boolean;
  prevNextButtonsProcessing?: boolean;
  labelPreviousButton?: string;
  labelNextButton?: string;
  labelLastNextButton?: string;
  canStayAtEnd?: boolean;
  children: ReactNode[];
}

interface IButtonProps {
  title?: string;
  onPress: () => void | null;
  disabled?: boolean;
}

const SwiperCommon: FunctionComponent<ISwiperCommon> = forwardRef(
  (
    {
      canNext = true,
      canPrev = true,
      prevProcessing = false,
      nextProcessing = false,
      onEnd,
      onNext,
      onSlideChange,
      onBeforePrevCheck,
      onBeforeNextCheck,
      startIndex = 0,
      hasDot,
      hasDoneButton = true,
      hasPagination = true,
      children,
      controlsType = SwiperCommonControlsType.DEFAULT,
      onEndControlButtonType = SwiperCommonControlsType.VERBOSE,
      prevNextButtonsEnabled = true,
      labelPreviousButton,
      labelNextButton,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const swiperRef = useRef<Swiper>(null);
    const dimension = useSelector(useDimensions);
    const styles = swiperStyles();
    const [slides, setSlides] = useState<Array<ISwiperSlide>>([]);
    const [currentSlide, setCurrentSlide] = useState<number>(startIndex);
    const [ended, setEnded] = useState<boolean>(false);

    const renderPagination = () => {
      if (
        swiperRef.current &&
        swiperRef.current?.getActiveIndex() >= slides.length
      ) {
        return null;
      }
      const total: number = slides.length;

      if (hasDot) {
        return (
          <View style={styles.paginationDotsStyle}>
            {slides.map((slide, index) => {
              const dotStyles = createPaginationDotStyles(
                index == currentSlide
              );
              return <View key={index} style={dotStyles.dot}></View>;
            })}
          </View>
        );
      }

      return (
        <View style={styles.paginationStyle} pointerEvents={"none"}>
          <Text style={styles.paginationText}>
            {currentSlide + 1}/{total}
          </Text>
        </View>
      );
    };

    const prevButton = () => {
      if (!prevNextButtonsEnabled || currentSlide == 0) {
        return null;
      }

      const title =
        controlsType == SwiperCommonControlsType.VERBOSE
          ? labelPreviousButton
          : undefined;

      return (
        <CustomButton
          title={title}
          icon={{
            name: "Prev",
            background: true,
            size: MQSize.SMALL,
          }}
          processing={prevProcessing}
          background={false}
          onPress={async () => {
            if (onBeforePrevCheck) {
              const check = await onBeforePrevCheck();
              if (check) {
                swiperRef.current?.goToPrev();
              }
            } else {
              swiperRef.current?.goToPrev();
            }
          }}
          disabled={!canPrev}
        />
      );
    };

    const nextButton = () => {
      if (!prevNextButtonsEnabled) {
        return null;
      }

      if (!hasDoneButton && currentSlide == slides.length - 1) {
        return null;
      }

      const title =
        controlsType == SwiperCommonControlsType.VERBOSE
          ? labelNextButton
          : undefined;

      return (
        <CustomButton
          title={title}
          icon={{
            name: "Next",
            background: true,
            size: MQSize.MEDIUM,
          }}
          processing={nextProcessing}
          background={false}
          onPress={async () => {

            const _onNext = async () => {
              if (onNext) {
                const canNext = await onNext(currentSlide);
                if (canNext) {
                  swiperRef.current?.goToNext();
                }
              } else {
                swiperRef.current?.goToNext();
              }
            }


            if (hasDoneButton && currentSlide == slides.length - 1) {
              onEnd && onEnd();
              setEnded(true);
            } else {
              if (onBeforeNextCheck) {
                const check = await onBeforeNextCheck();
               
                if (check) {
                  _onNext();
                }
              } else {
                _onNext();
              }
              
            }
          }}
          disabled={!canNext}
        />
      );
    };

    const swiperControlsProps = {
      dotActiveStyle: {
        backgroundColor: colors.white,
      },
      controlsEnabled: true,
      dotsTouchable: false,
      prevPos: "bottom-left",
      nextPos: "bottom-right",
      NextComponent: () => {
        return null;
      },
      PrevComponent: () => {
        return null;
      },
      lastNextElement: () => {
        return null;
      },
    };

    if (hasPagination) {
      swiperControlsProps.DotComponent = () => {
        return null;
      };
    }

    useEffect(() => {
      setSlides(
        Children.toArray(children)
          .filter((child: ReactNode) => child != null)
          .map((child: ReactNode, index: number): ISwiperSlide => {
            return {
              index,
              component: child,
            };
          }) as ISwiperSlide[]
      );
    }, [children]);

    useEffect(() => {
      onSlideChange && onSlideChange(currentSlide);
    }, [currentSlide]);

    /*useEffect(() => {
      if (ended) {
        onEnd && onEnd();
      }
    }, [ended]);*/

    useImperativeHandle(ref, () => ({
      goToNext: () => {
        swiperRef.current?.goToNext();
      },
      getActiveIndex: () => {
        swiperRef.current?.getActiveIndex();
      },
    }));

    return (
      <View style={styles.container}>
        {slides.length > 0 && (
          <Swiper
            ref={swiperRef}
            from={startIndex}
            gesturesEnabled={() => false}
            controlsProps={swiperControlsProps}
            onIndexChanged={(index) => {
              setCurrentSlide(index);
            }}
            positionFixed={false}
            slideWrapperStyle={StyleSheet.flatten(styles.slideWrapperStyle)}
            innerContainerStyle={StyleSheet.flatten(styles.innerContainerStyle)}
          >
            {children}
          </Swiper>
        )}
        <View style={styles.controls}>
          <View style={{ flex: 1, alignItems: "flex-start" }}>
            {prevButton()}
          </View>
          <View style={{ flex: 1 }}>
            {hasPagination && slides.length > 0 && renderPagination()}
          </View>
          <View style={{ flex: 1, alignItems: "flex-end" }}>
            {nextButton()}
          </View>
        </View>
      </View>
    );
  }
);

export default SwiperCommon;
