

import { StyleSheet } from "react-native";
export const createDraggableStyles = () => {
  return StyleSheet.create({
    container: {
      width:"100%",
      height:"100%"
    },
  });
}