import React, { FunctionComponent, useState, useEffect } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { updateUserLang } from "./../../store/user/actions";
import { ILangData } from "./../../languages";
import { useUser } from "./../../store/user/selecters";
import { languagesSelecterStyles } from "./styles";
import { useDimensions } from "./../../store/dimensions/selecters";
import { useLanguages } from "./../../store/languages/selecters";

interface ILanguagesSelecter {
  autoPosition?:boolean;
}

const LanguagesSelecter: FunctionComponent<ILanguagesSelecter> = ({
  children,
  autoPosition = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dimensions = useSelector(useDimensions);

  const dispatch = useDispatch();
  const user = useSelector(useUser);
  const styles = languagesSelecterStyles(isOpen, autoPosition);
  const { languages } = useSelector(useLanguages);

  const selectedLanguage = {
    lang: user.lang,
    flag: languages.find((ll: ILangData) => ll.code == user.lang)?.flag,
  };

  const onChangeLang = (lang: string) => {
    dispatch(updateUserLang(lang));
    setIsOpen(false);
  };

  if(languages.length == 1){
    return null;
  }

  return (
    <View style={styles.wrapper}>
      <TouchableOpacity
        onPress={() => setIsOpen(!isOpen)}
        style={styles.container}
      >
        {selectedLanguage.flag && (
          <Image source={{ uri: selectedLanguage.flag }} style={styles.flag} />
        )}
      </TouchableOpacity>
      {isOpen && (
        <View style={styles.dropDown}>
          {languages.map((l, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => onChangeLang(l.code)}
              style={[
                styles.touchableSelecter,
                l.code === user.lang && styles.touchableSelecterSelected,
              ]}
            >
              <Image source={{ uri: l.flag }} style={styles.selecterFlag} />
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  );
};

export default LanguagesSelecter;
